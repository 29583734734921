.hero-title,
.hero-text,
.vs-btn {
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-name: var(--animation-name);
}
.hero {
  $hero: &;
  &-bg,
  &-bg3 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: -1;
    opacity: 1;
    transform: scale(1);
    transition: transform 7000ms ease, opacity 1500ms ease-in;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        #01133c 34.22%,
        rgba(1, 19, 60, 0) 74.58%
      );
      opacity: 0.9;
    }
  }
  &-inner,
  &-inner3 {
    min-height: 920px;
    display: flex;
    align-items: center;
    @include respond-below(xxlg) {
      min-height: 695px;
    }
  }
  &-title,
  &-text {
    color: var(--white-color);
  }
  &-title {
    font-size: 64px;
    line-height: 74px;
    margin-bottom: 22px;
    @include respond-below(sm) {
      font-size: 40px;
      line-height: 50px;
    }
    & > span {
      color: var(--sec-color);
    }
  }
  &-text {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 32px;
    padding-right: 20px;
    @include respond-below(xl) {
      padding-right: 0;
    }
  }
  &--layout1 {
    #{$hero}-inner {
      min-height: 930px;
      @include respond-below(xlg) {
        min-height: 750px;
        padding-bottom: 100px;
      }
      @include respond-below(lg) {
        min-height: 700px;
        padding-bottom: 100px;
      }
    }
    #{$hero}-bg {
      &::before {
        background: linear-gradient(
          90deg,
          #01133c 37.67%,
          rgba(1, 19, 60, 0) 58.62%
        );
        opacity: 0.9;
      }
    }
  }
  &--layout1,
  &--layout2,
  &--layout3 {
    .hero-text {
      animation-delay: 0.5s;
    }
    .vs-btn {
      animation-delay: 0.8s;
    }
    .slick-dots {
      position: absolute;
      top: auto;
      left: 50%;
      margin: 0;
      transform: translateX(-50%);
      right: 0;
      display: flex;
      gap: 10px;
      bottom: 20%;
      justify-content: center;
      @include respond-below(lg) {
        display: none;
      }

      button {
        font-size: 30px;
        width: 30px;
        height: 10px;
        border-radius: 0%;
        background-color: transparent;
        color: $white-color;
        border: 2px solid $white-color;
        font-family: $title-font;
        font-size: 20px;
        font-weight: 700;
        text-indent: -9999999999px;
        li {
          margin-bottom: 0;
        }

        &::before {
          display: none;
        }
      }

      li {
        display: block;
        margin: 0 0 20px 0;

        &.slick-active {
          button {
            transform: scale(1);
            background-color: var(--theme-color);
            color: var(--white-color);
            border: 2px solid var(--white-color);
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .slick-current {
      --animation-name: slideinup;
    }
  }
  &-bg3 {
    &::before {
      background: linear-gradient(90deg, #01133c 9%, rgba(1, 19, 60, 0) 75.58%);
      opacity: 0.9;
    }
  }
}

.call-block2 {
  position: absolute;
  bottom: 145px;
  right: 0;
  background-color: var(--theme-color);
  z-index: 2;
  color: var(--white-color);
  padding: 0 77px 0 140px;
  min-height: 150px;
  display: flex;
  align-items: center;
  @include smooth-transition;
  @include respond-below(md) {
    display: none;
  }
  .vc-icon {
    position: absolute;
    left: -75px;
    min-width: 150px;
    min-height: 150px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--theme-color);
    font-size: 64px;
    border-radius: 50%;
    @include smooth-transition;
    &::before,
    &::after {
      position: absolute;
      content: "";
      min-width: 200px;
      min-height: 200px;
      background-color: var(--white-color);
      border-radius: 50%;
      z-index: -1;
      @include smooth-transition;
    }
  }
  .vc-title,
  .vc-number {
    font-size: 24px;
    display: block;
    font-weight: 700;
    font-family: var(--title-font);
    line-height: 1.3;
    @include smooth-transition;
  }
  .vc-number {
    font-size: 40px;
  }
  &:hover {
    background-color: var(--sec-color);
    color: var(--white-color);
    @include smooth-transition;
    .vc-icon {
      background-color: var(--sec-color);
      color: var(--white-color);
      @include smooth-transition;
    }
  }
}
