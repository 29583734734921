.price_slider_wrapper {
  margin: {
    top: 40px;
  }
  .ui-slider {
    height: 10px;
    position: relative;
    width: 100%;
    background-color: var(--white-color);
    border: none;
    margin-top: 10px;
    margin-bottom: 35px;
    cursor: pointer;
    border-radius: 3px;
  }
  .ui-slider-handle {
    width: 20px;
    height: 20px;
    outline: none;
    border-radius: 50%;
    text-align: center;
    line-height: 10px;
    padding: 0;
    border: 5px solid var(--theme-color);
    cursor: pointer;
    position: absolute;
    z-index: 2;
    margin-top: 0;
    background-color: var(--white-color);
    transform: translateX(9px);
    &:last-child {
      transform: translateX(-10px);
    }
  }
  .ui-slider-range {
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    display: block;
    background-color: var(--theme-color);
  }

  .price_label {
    font-size: 16px;
    font-weight: 500;
    padding-top: 3px;
    color: var(--title-color);
  }

  .vs-btn {
    padding: 13px 30px;
    background-color: var(--theme-color);
    color: var(--white-color);
    &::before,
    &::after {
      background-color: var(--sec-color);
    }
    &:hover {
      color: $white-color;
    }
  }

  .price_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.chekbox-area {
  margin-bottom: 5px;
}
