.widget {
  padding: var(--widget-padding-y, 40px) var(--widget-padding-x, 30px);
  background-color: $smoke-color;
  position: relative;
  margin-bottom: 30px;
  border: 6px;

  &_banner {
    padding: 0;
  }

  select,
  input {
    height: 55px;
    border: none;
    background-color: #fff;
    padding-left: 20px;
    font-weight: 400;
  }
}

.widget_title {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.41666667;
  margin: -0.38em 0 30px 0;
  font-family: var(--title-font);
  text-transform: capitalize;
  padding: 0 0px 5px 0;
  &::before,
  &::after {
    position: absolute;
    content: "";
    min-width: 50px;
    min-height: 2px;
    bottom: 0;
    left: 0;
  }
  &::before {
    background-color: var(--sec-color);
    z-index: 1;
  }
  &::after {
    min-width: 100%;
    background-color: var(--border-color);
  }
}

.widget {
  .search-form {
    position: relative;
    display: flex;

    input {
      flex: 1;
    }

    button {
      border: none;
      background-color: var(--theme-color);
      color: $white-color;
      height: 55px;
      width: 55px;
      line-height: 55px;
      font-size: 17px;

      &:hover {
        background-color: $title-color;
        color: $white-color;
      }
    }
  }
}
.wp-block-tag-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0;
  align-items: center;
}
.wp-block-tag-cloud,
.tagcloud {
  a {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    padding: 20px 21px 19px 21px;
    margin-right: 0;
    margin-bottom: 0;
    color: var(--black-color);
    text-transform: uppercase;
    background: var(--silverLightLight-color);
    border: 1px solid var(--border-color);
    border-radius: 50px;
    font-family: var(--title-font);

    &:hover {
      background-color: $theme-color;
      border-color: var(--theme-color);
      color: $white-color !important;
    }
  }
}

.tagcloud {
  margin-right: -5px;
  margin-bottom: -10px;

  a {
    background-color: $white-color;
    color: $title-color;
  }
}

.recent-post,
.recent-product {
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }

  .media-img {
    margin-right: 15px;
    width: 100px;
    overflow: hidden;

    img {
      width: 100%;
      border-radius: 0px;
      transition: all ease 0.4s;
    }
  }

  .post-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
    margin: 0 0 3px 0;
    & > a {
      display: block;
    }
  }

  .recent-post-meta {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: var(--theme-color);
    font-family: var(--title-font);
    display: inline-flex;
    align-items: center;
    i {
      margin-right: 5px;
      color: $theme-color;
    }
  }

  &:hover {
    .media-img {
      img {
        transform: scale(1.1);
      }
    }
  }
}
.recent-product {
  .media-img {
    min-width: 100px;
    min-height: 80px;
    background: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    img {
      width: inherit;
    }
  }
  .recent-post-meta {
    font-size: 16px;
  }
}

.wp-block-calendar,
.calendar_wrap {
  position: relative;
  background-color: #fff;
  padding-bottom: 0;
  border: none;

  span[class*="wp-calendar-nav"] {
    position: absolute;
    top: 8px;
    left: 20px;
    font-size: 14px;
    color: $title-color;
    font-weight: 500;
    z-index: 1;

    a {
      color: inherit;
    }
  }

  span.wp-calendar-nav-next {
    left: auto;
    right: 20px;
  }

  caption {
    caption-side: top;
    text-align: center;
    color: $title-color;
    background-color: #f1dbbe;
  }

  th {
    font-size: 14px;
    padding: 5px 5px;
    border: none;
    text-align: center;
    border-right: 1px solid #fff;
    color: #01133c;
    font-weight: 500;

    a {
      color: inherit;
    }
  }

  td {
    font-size: 14px;
    padding: 5px;
    color: #01133c;
    border: 1px solid #ededed;
    text-align: center;
    background-color: transparent;
    transition: all ease 0.4s;
  }

  #today {
    color: $theme-color;
    background-color: $white-color;
    border-color: #ededed;
  }

  thead {
    background-color: #f6f6f6;
  }

  .wp-calendar-table {
    margin-bottom: 0;
  }

  .wp-calendar-nav {
    .pad {
      display: none;
    }
  }
}

.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  .gallery-thumb {
    overflow: hidden;
    border-radius: 5px;

    img {
      width: 100%;
      transform: scale(1);
      transition: all ease 0.4s;
    }

    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }
}

.widget_shopping_cart {
  text-align: left;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .mini_cart_item {
    position: relative;
    border-bottom: 1px solid var(--theme-color);
    padding: 0 0 32px 0px;
    margin: 0 0 27px 0;
    min-height: 90px;
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    a {
      color: var(--title-color);
    }
    & > a > img {
      max-width: 80px;
      margin-right: 15px;
    }
  }

  .remove {
    position: absolute;
    right: 0;
    top: 0;
    color: $title-color;
    line-height: 1;
    font-size: 18px;

    &:hover {
      color: $theme-color;
    }
  }

  .img {
    position: absolute;
    left: 0;
    top: 3px;
    width: 90px;
    height: 90px;
    display: inline-block;
    border: 1px solid $border-color;
  }

  .product-title {
    font-size: 14px;
    color: $title-color;
    font-weight: 400;
    margin-bottom: 0;
    display: inline-block;
  }

  .amount {
    display: block;
    font-weight: 600;
    color: $title-color;
    font-size: 16px;
  }

  .quantity {
    display: inline-flex;
    margin-top: 0px;
  }

  .qut-btn {
    border: 1px solid $border-color;
    background-color: transparent;
    display: inline-block;
    background-color: transparent;
    width: 25px;
    height: 25px;
    padding: 0;
    font-size: 12px;
    z-index: 1;
    position: relative;

    &:hover {
      background-color: $title-color;
      border-color: transparent;
      color: $white-color;
    }
  }

  .qty-input {
    border: 1px solid $border-color;
    text-align: center;
    width: max-content;
    min-width: 40px;
    font-size: 12px;
    padding: 0;
    height: 25px;
    margin: 0 -1px;
  }

  .subtotal {
    float: right;
    text-align: right;
    font-size: 12px;
    margin-top: 19px;

    > span:not(.amount) {
      color: $body-color;
      font-weight: 300;
    }

    .amount {
      font-size: 12px;
      display: inline-block;
    }
  }

  .total {
    color: var(--title-color);
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 25px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .amount {
      font-size: inherit;
      display: inline-block;
      color: $theme-color;
    }
  }

  .buttons {
    margin: 0;
    gap: 15px;
  }
}

.sidebar-area {
  margin-bottom: -10px;
  position: sticky;
  top: 30px;
  margin-bottom: 30px;
  @include respond-below(md) {
    margin-bottom: 0;
  }

  ul.wp-block-latest-posts {
    margin-bottom: 0;

    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .newsletter-form {
    button {
      width: 100%;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 400;
      height: 60px;
      margin-top: 10px;
    }
  }

  .widget .wp-block-search {
    margin-bottom: 0;
  }

  .wp-block-group__inner-container h2 {
    font-size: 20px;
    line-height: 1em;
    margin-bottom: 20px;
    margin-top: -0.07em;
  }

  ol.wp-block-latest-comments {
    padding: 0;
    margin: 0;

    li {
      line-height: 1.5;
      margin: 0 0 20px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

.recent-product {
  display: flex;

  .product-title {
    font-size: 16px;
  }
}

.widget-workhours {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    background-color: $white-color;
    padding: 14.5px 20px;
    margin: 0 0 15px 0;
    font-family: $title-font;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      margin-right: 10px;
    }
  }
}

.quote-box {
  position: relative;
  text-align: center;
  padding: 80px 30px;
  margin: 0 0 30px 0;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#071a3e, 0.7);
  }

  &__title {
    color: $white-color;
    position: relative;
    z-index: 1;
    max-width: 200px;
    margin: -0.3em auto 25px auto;
  }
}

@include lg {
  .widget {
    --widget-padding-y: 25px;
    --widget-padding-x: 25px;
  }

  .widget_title {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .recent-post {
    .post-title {
      font-size: 14px;
      line-height: 22px;
    }

    .recent-post-meta a {
      font-size: 12px;
    }
  }

  .widget-workhours {
    li {
      padding: 14.5px 20px;
      font-size: 14px;
    }
  }
}

@include md {
  .wp-block-tag-cloud a,
  .tagcloud a {
    padding: 10.5px 18px;
  }
}

@include sm {
  .contact-widget {
    .icon {
      --icon-size: 30px;
      --icon-font-size: 12px;
      top: 5px;
    }

    .contact {
      padding: 0 0 0 45px;
      margin: 0 0 20px 0;
      border-bottom: none;
    }
  }

  .sidebar-gallery {
    gap: 10px;
  }
}

@include xs {
  .widget {
    padding: 30px 20px;
  }
}

.sidebar-gallery .gallery-thumb {
  overflow: hidden;
  position: relative;
  border-radius: 0px;
}

.sidebar-gallery .gallery-thumb img {
  transition: all ease 0.4s;
  width: 100%;
  transform: scale(1);
}

.sidebar-gallery .gallery-thumb:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
}

.sidebar-gallery .gallery-thumb .gal-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  width: var(--icon-size, 35px);
  height: var(--icon-size, 35px);
  margin: calc(var(--icon-size, 35px) / -2) 0 0
    calc(var(--icon-size, 35px) / -2);
  text-align: center;
  font-size: 18px;
  color: var(--white-color);
  background-color: transparent;
  border-radius: 50%;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sidebar-gallery .gallery-thumb .gal-btn:hover {
  transform: scale(1);
  background-color: var(--white-color);
  color: var(--theme-color);
}

.sidebar-gallery .gallery-thumb:hover:before {
  opacity: 0.8;
  visibility: visible;
}

.sidebar-gallery .gallery-thumb:hover .gal-btn {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.1s;
}

.sidebar-gallery .gallery-thumb:hover img {
  transform: scale(1.12);
}

.widget {
  &--bg1 {
    background-color: var(--blackSand-color);
  }
}
.vs-widget-about {
  &__text {
    color: var(--silverDeep-color);
  }
}
