.counter {
  &--layout2 {
    background-color: var(--smoke-color);
  }
  &-inner {
    padding-left: 120px;
    padding-right: 120px;
    margin: -160px -120px 0;
    position: relative;
    z-index: 2;
    background-attachment: fixed;
    padding-bottom: calc(var(--section-space) - 30px);
    padding-top: var(--section-space);
    @include respond-below(xl) {
      margin: -160px 0 0;
      padding: 60px 60px 30px;
    }
    @include respond-below(lg) {
      padding: 30px 30px 0px;
    }
  }
  &-block {
    $self: &;
    display: flex;
    align-items: center;
    gap: 20px;
    &__icon {
      display: inline-flex;
      min-width: 80px;
      min-height: 80px;
      align-items: center;
      justify-content: center;
      background-color: var(--white-color);
    }
    &__heading {
      font-size: 16px;
      margin-bottom: 0;
      color: var(--white-color);
    }
    &__number {
      font-size: 40px;
      font-weight: 700;
      color: var(--white-color);
      font-family: var(--title-font);
      margin-bottom: 3px;
      display: block;
      line-height: 50px;
    }
    &.style2 {
      .counter-block {
        &__icon {
          background-color: var(--title-color);
          color: var(--white-color);
        }
        &__number {
          color: var(--title-color);
        }
        &__heading {
          color: var(--theme-color);
        }
      }
    }
  }
}

.offer-counter {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  background: var(--white-color);
  margin-bottom: 30px;
  min-height: 140px;
  align-items: center;
  @include respond-below(sm) {
    flex-direction: column;
  }
  & > li {
    line-height: 1;
    padding: 16px 30px 16px 30px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 37px;
      width: 1px;
      background: var(--grey-color);
      right: -1px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 0.49px;
      @include respond-below(sm) {
        display: none;
      }
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      margin-right: 0;
      &::before {
        display: none;
      }
    }
    @include respond-below(sm) {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .count-number {
    font-size: 40px;
    font-weight: 800;
    color: var(--theme-color);
    font-family: var(--title-font);
    margin-bottom: 5px;
  }
  .count-name {
    font-size: 18px;
    color: var(--black-color);
    font-family: var(--title-font);
    font-weight: 800;
    text-transform: lowercase;
    display: inline-block;
  }
}
