.cursor1 {
  position: fixed;
  width: 40px;
  height: 40px;
  border: 1px solid var(--theme-color);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.15s;
  z-index: 999;
  mix-blend-mode: difference;

  @media (max-width: 1200px) {
    display: none;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}

.cursor2 {
  position: fixed;
  width: 8px;
  height: 8px;
  background-color: var(--theme-color);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.2s;
  z-index: 999;
  mix-blend-mode: difference;

  @media (max-width: 1200px) {
    display: none;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  &.circle {
    width: 60px;
    height: 60px;
  }
}
