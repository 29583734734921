.form-style1,
.form-style2 {
  padding: 60px 60px 40px 60px;
  @include respond-below(lg) {
    padding: 50px 30px 30px 30px;
  }
  @include respond-below(md) {
    padding: 0px 30px 30px 30px;
  }
  @include respond-below(sm) {
    padding: 20px;
  }
  .nice-select {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    &::after {
      border-bottom: none;
      border-right: none;
      height: auto;
      margin-top: 0;
      right: 20px;
      transform: rotate(0deg) translateY(-50%);
      width: auto;
      content: "\f0d7";
      font-family: var(--icon-font);
      font-weight: 700;
      color: var(--theme-color);
      font-size: 20px;
    }
    .list {
      z-index: 9;
      width: 100%;
    }
  }
  .form-group {
    & > i {
      right: calc(var(--bs-gutter-x) / 2 + 20px);
      color: var(--theme-color);
    }
  }
  .form-control {
    padding: 15px 20px;
  }
  .nice-select,
  .form-control {
    border: 1px solid var(--border-color);
    border-radius: 0;
    font-size: 14px;
    color: var(--body-color);
  }
}

.form-style1 {
  .sec-title {
    font-size: 30px;
    @include respond-below(md) {
      color: var(--white-color);
    }
  }
}

.form-control[type="date"]::-webkit-inner-spin-button,
.form-control[type="date"]::-webkit-calendar-picker-indicator,
.form-control[type="time"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  width: 100px;
  height: 50px;
  cursor: pointer;
  margin-right: -20px;
  position: relative;
  z-index: 9;
}

.form-style2 {
  background-color: var(--white-color);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.07);
  padding: 60px;
  @include respond-below(md) {
    padding: 30px;
  }
  @include respond-below(sm) {
    padding: 20px;
  }
  .form-group {
    margin-bottom: 30px;
    @include respond-below(sm) {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
  .form-control {
    background-color: var(--smoke-color);
    border-color: var(--smoke-color);
    &.nice-select {
      display: flex;
      align-items: center;
    }
  }
}

.form-style3 {
  background-color: var(--smoke-color);
  padding: 50px 60px;
  @include respond-below(md) {
    padding: 20px;
  }
  .sec-title {
    font-size: 40px;
    margin-bottom: 30px;
    text-transform: capitalize;
    @include respond-below(sm) {
      font-size: 30px;
    }
  }
  .form-group {
    margin-bottom: 30px;
    @include respond-below(sm) {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
  .form-control {
    padding: 15px 20px;
    color: var(--body-color);
    border: 1px solid var(--white-color);
    &::placeholder {
      color: var(--body-color);
    }
  }
  .vs-btn {
    padding: 17px 30px;
  }
}

.form-style4 {
  position: relative;
  margin-bottom: 30px;
  .form-control {
    height: 60px;
  }
  .vs-btn {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    padding: 17px 20px;
  }
}
