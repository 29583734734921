.project {
  .tab-content {
    min-height: 636px;
  }
  &__tab {
    margin-bottom: 60px;
    text-align: center;
    .nav-tabs,
    .filter-menu1 {
      border: 1px solid var(--white-color);
      display: inline-flex;
      padding: 15px;
      gap: 10px;
      .nav-link,
      button {
        border-radius: 0;
        padding: 0 30px;
        background-color: var(--smoke-color);
        color: var(--title-color);
        font-size: 16px;
        font-weight: 700;
        min-height: 50px;
        font-family: var(--title-font);
        border: 0;
        &.active {
          background-color: var(--theme-color);
          border-color: var(--theme-color);
          color: var(--white-color);
        }
      }
    }
    &.style2 {
      .nav-tabs {
        padding: 0;
        border: 0;
        gap: 30px;
      }
      .nav-link {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        min-height: 80px;
        justify-content: center;
        font-size: 20px;
        border: 1px solid var(--theme-color);
        position: relative;
        &::before {
          position: absolute;
          content: "";
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 20px 10px 0 10px;
          border-color: var(--theme-color) transparent transparent transparent;
          transform: rotate(0deg) translateX(-50%);
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s ease-in-out;
          bottom: -19px;
          left: 50%;
          @include smooth-transition;
        }
        & > img {
          filter: none;
          @include smooth-transition;
        }
        &.active {
          background-color: var(--theme-color);
          border-color: var(--theme-color);
          @include smooth-transition;
          &::before {
            opacity: 1;
            visibility: visible;
          }
          & > img {
            filter: brightness(0) invert(1);
            @include smooth-transition;
          }
        }
      }
    }
    &.style3 {
      .nav-tabs,
      .filter-menu1 {
        border-color: var(--theme-color);
        flex-wrap: wrap;
      }
    }
  }
  &--layout2 {
    padding-bottom: 235px;
    background-attachment: fixed;
  }
  &-block {
    margin-bottom: 30px;
    $self: &;
    position: relative;
    &__img {
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 0%;
        background: linear-gradient(
          180deg,
          rgba(1, 19, 60, 0) 0%,
          rgba(1, 19, 60, 0.71) 25%,
          #01133c 100%
        );
        bottom: 0;
        @include smooth-transition;
      }
      &::after {
        position: absolute;
        content: "";
        width: 0%;
        height: 6px;
        background-color: var(--sec-color);
        bottom: 0;
        left: 0;
        @include smooth-transition;
        opacity: 0;
        visibility: hidden;
      }
    }
    &__content {
      position: absolute;
      bottom: 30px;
      left: 0;
      padding: 36px 30px;
      transition: all 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden;
    }
    &__title {
      & > a {
        color: var(--white-color);
        display: inline-block;
        &:hover {
          color: var(--sec-color);
        }
      }
    }
    &__title {
      font-size: 30px;
    }
    &:hover {
      #{$self}__content {
        bottom: 0;
        opacity: 1;
        visibility: visible;
      }
      #{$self}__img {
        &::before {
          height: 100%;
        }
        &::after {
          opacity: 1;
          visibility: visible;
          width: 100%;
        }
      }
    }
  }
}

.tab-content {
  &.style2 {
    background-color: var(--white-color);
    padding: 50px 50px 20px;
    @include respond-below(sm) {
      padding: 20px;
    }
  }
}

.twentytwenty-handle {
  height: 60px;
  width: 60px;
  margin-left: -32px;
  margin-top: -30px;
  border: 3px solid var(--theme-color);
  background: var(--theme-color);
  cursor: pointer;
}

.twentytwenty-horizontal .twentytwenty-handle:before,
.twentytwenty-horizontal .twentytwenty-handle:after,
.twentytwenty-vertical .twentytwenty-handle:before,
.twentytwenty-vertical .twentytwenty-handle:after {
  background: var(--theme-color);
  box-shadow: none;
}

.details {
  &-content {
    background-color: var(--smoke-color);
    padding: 50px 40px;
    margin-top: -84px;
    margin-bottom: 55px;
    @include respond-below(sm) {
      padding: 20px 10px;
      margin-top: 0;
    }
    p {
      color: var(--black-color);
    }
  }
  &-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    column-count: 3;
    column-gap: 20px;
    margin: -2.5px;
    @include respond-below(lg) {
      column-count: 2;
    }
    @include respond-below(sm) {
      column-count: 1;
    }
    & > li {
      display: inline-flex;
      align-items: center;
      gap: 30px;
      padding: 2.5px;
      .star-rating {
        &::before {
          color: var(--theme-color);
        }
      }
    }
    &__name {
      font-size: 20px;
      font-weight: 700;
      font-family: var(--title-font);
      color: var(--black-color);
      min-width: 85px;
    }
    &__span {
      font-size: 16px;
      font-family: var(--body-font);
      color: var(--title-color);
      font-weight: 400;
    }
  }
}
.filter-active {
  padding: 0 5px;
}
.filter-item {
  width: 283px;
  height: 420px;
  margin-bottom: 30px;
  @include respond-below(sm) {
    width: 100%;
  }
}
.grid-item--width2 {
  width: 594px;
  @include respond-below(sm) {
    width: 100%;
  }
}
.grid-item--height2 {
  height: 420px;
  @include respond-below(sm) {
    height: 248px;
  }
}
