@charset "UTF-8";
/*
Template Name: TechBiz
Template URL: https://html.vecuro.com/techbiz
Description: TechBiz - IT Solution & Service HTML Template
Author: vecuro
Author URI: https://themeforest.net/user/vecuro_themes
Version: 1.0.0
*/
/*=================================
    CSS Index Here
==================================*/
/*

01. Theme Base
    1.1. Mixin
    1.2. Function
    1.3. Variable
    1.4. Typography
    1.5. Extend
    1.7. Wordpress Default
02. Reset
    2.1. Container
    2.2. Grid
    2.3. Input
    2.4. Slick Slider
    2.5. Mobile Menu
03. Utilities
    3.1. Preloader
    3.2. Buttons
    3.3. Titles
    3.4. Common
    3.6. Font
    3.7. Background
    3.8. Text Color
    3.9. Overlay
    3.10. Animation
04. Template Style
    4.1. Widget
    4.2. Header
    4.3. Footer
    4.4. Breadcumb
    4.5. Pagination
    4.6. Blog
    4.7. Comments
    4.8. Hero Area
    4.9. Error    
    4.10. Feature
    4.11. About
    4.12. Service
    4.13. Counter
    4.14. Team
    4.15. Process
    4.16. Accordion
    4.17. Simple Sections
    4.18. Testimonial
    4.19. Popup Side Menu
    4.20. Price Plan
    4.21. Forms Style
    4.22. Projects
    4.23. Contact
05. Spacing
06. Color Plate

*/
/*=================================
    CSS Index End
==================================*/
/*=================================
   01. Theme Base
==================================*/
/*------------------- 1.1. Mixin -------------------*/
/*------------------- 1.2. Function -------------------*/
/*------------------- 1.3. Variable-------------------*/
:root {
  --theme-color: #0046e2;
  --theme-color-rgb: 0, 70, 226;
  --sec-color-rgb: 239, 15, 58;
  --sec-color: #ef0f3a;
  --title-color: #01133c;
  --body-color: #666;
  --smoke-color: #f5f5f6;
  --secondary-color: #eef2fb;
  --black-color: #000000;
  --white-color: #ffffff;
  --yellow-color: #fec624;
  --success-color: #28a745;
  --error-color: #dc3545;
  --border-color: #e6edfd;
  --title-font: "Dosis", sans-serif;
  --body-font: "Roboto", sans-serif;
  --icon-font: "Font Awesome 5 Pro";
  --main-container: 1220px;
  --container-gutters: 30px;
  --section-space: 120px;
  --section-space-mobile: 80px;
  --section-title-space: 80px;
  --ripple-ani-duration: 5s;
}

.home-five,
.home-four {
  --theme-color: #0037ff;
  --smoke-color: #f6f7fc;
}

/*------------------- 1.5. Typography -------------------*/
html,
body {
  scroll-behavior: auto !important;
}

body {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--body-color);
  line-height: 26px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  /* Small devices */
}
@media (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 24px;
  }
}

iframe {
  border: none;
  width: 100%;
}

.slick-slide:focus,
button:focus,
a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  outline: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid var(--border-color);
}

th {
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
}

td,
th {
  border: 1px solid var(--border-color);
  padding: 9px 12px;
}

a {
  color: var(--theme-color);
  text-decoration: none;
  outline: 0;
  transition: all ease 0.4s;
}
a:hover {
  color: var(--title-color);
}
a:active, a:focus, a:hover, a:visited {
  text-decoration: none;
  outline: 0;
}

button {
  transition: all ease 0.4s;
}

img {
  border: none;
  max-width: 100%;
}

ins {
  text-decoration: none;
}

pre {
  background: #f5f5f5;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

span.ajax-loader:empty,
p:empty {
  display: none;
}

p {
  margin: 0 0 18px 0;
  color: var(--body-color);
  line-height: 1.63;
  /* Small devices */
}
@media (max-width: 767px) {
  p {
    line-height: 1.73;
  }
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: var(--title-font);
  color: var(--title-color);
  text-transform: none;
  font-weight: 700;
  line-height: 1.5;
  margin: -0.25em 0 15px 0;
}

.h1,
h1 {
  font-size: 48px;
}

.h2,
h2 {
  font-size: 40px;
}

.h3,
h3 {
  font-size: 36px;
}

.h4,
h4 {
  font-size: 30px;
}

.h5,
h5 {
  font-size: 24px;
}

.h6,
h6 {
  font-size: 20px;
}

/* Large devices */
@media (max-width: 1199px) {
  .h1,
  h1 {
    font-size: 40px;
  }
  .h2,
  h2 {
    font-size: 36px;
  }
  .h3,
  h3 {
    font-size: 30px;
  }
  .h4,
  h4 {
    font-size: 24px;
  }
  .h5,
  h5 {
    font-size: 20px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 36px;
  }
  .h2,
  h2 {
    font-size: 30px;
  }
  .h3,
  h3 {
    font-size: 26px;
  }
  .h4,
  h4 {
    font-size: 22px;
  }
  .h5,
  h5 {
    font-size: 18px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}
/*------------------- 1.6. Extend -------------------*/
/*------------------- 1.7. Wordpress Default -------------------*/
p.has-drop-cap {
  margin-bottom: 20px;
}

.page--item p:last-child .alignright {
  clear: right;
}

.blog-title,
.pagi-title,
.breadcumb-title {
  word-break: break-word;
}

.blocks-gallery-caption,
.wp-block-embed figcaption,
.wp-block-image figcaption {
  color: var(--body-color);
}

.bypostauthor,
.gallery-caption {
  display: block;
}

.page-links,
.clearfix {
  clear: both;
}

.page--item {
  margin-bottom: 30px;
}
.page--item p {
  line-height: 1.8;
}

.content-none-search {
  margin-top: 30px;
}

.wp-block-button.aligncenter {
  text-align: center;
}

.alignleft {
  display: inline;
  float: left;
  margin-bottom: 10px;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-bottom: 10px;
  margin-left: 1.5em;
  margin-right: 1em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.gallery {
  margin-bottom: 1.5em;
  width: 100%;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
  padding: 0 5px;
}

.wp-block-columns {
  margin-bottom: 1em;
}

figure.gallery-item {
  margin-bottom: 10px;
  display: inline-block;
}

figure.wp-block-gallery {
  margin-bottom: 14px;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
  font-size: 12px;
  color: var(--body-color);
  line-height: 1.5;
  padding: 0.5em 0;
}

.wp-block-cover p:not(.has-text-color),
.wp-block-cover-image-text,
.wp-block-cover-text {
  color: var(--white-color);
}

.wp-block-cover {
  margin-bottom: 15px;
}

.wp-caption-text {
  text-align: center;
}

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}
.wp-caption .wp-caption-text {
  margin: 0.5em 0;
  font-size: 14px;
}

.wp-block-media-text,
.wp-block-media-text.alignwide,
figure.wp-block-gallery {
  margin-bottom: 30px;
}

.wp-block-media-text.alignwide {
  background-color: var(--smoke-color);
}

.editor-styles-wrapper .has-large-font-size,
.has-large-font-size {
  line-height: 1.4;
}

.wp-block-latest-comments a {
  color: inherit;
}

.wp-block-button {
  margin-bottom: 10px;
}
.wp-block-button:last-child {
  margin-bottom: 0;
}
.wp-block-button .wp-block-button__link {
  color: #fff;
}
.wp-block-button .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
}
.wp-block-button.is-style-outline .wp-block-button__link {
  background-color: transparent;
  border-color: var(--title-color);
  color: var(--title-color);
}
.wp-block-button.is-style-outline .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.wp-block-button.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

ol.wp-block-latest-comments li {
  margin: 15px 0;
}

ul.wp-block-latest-posts {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}
ul.wp-block-latest-posts a {
  color: inherit;
}
ul.wp-block-latest-posts a:hover {
  color: var(--theme-color);
}
ul.wp-block-latest-posts li {
  margin: 15px 0;
}

.wp-block-search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.wp-block-search .wp-block-search__input {
  width: 100%;
  max-width: calc(100% - 120px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  padding-left: 20px;
}
.wp-block-search .wp-block-search__button {
  margin: 0;
  width: 120px;
  border: none;
  height: 50px;
  color: #fff;
  background-color: var(--theme-color);
}
.wp-block-search .wp-block-search__button:hover {
  background-color: var(--title-color);
  opacity: 0.8;
}

ul.wp-block-rss a {
  color: inherit;
}

.wp-block-group.has-background {
  padding: 15px 15px 1px;
  margin-bottom: 30px;
}

.wp-block-table td,
.wp-block-table th {
  border-color: rgba(0, 0, 0, 0.1);
}

.wp-block-table.is-style-stripes {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.logged-in .will-sticky .sticky-active.active,
.logged-in .preloader .vs-btn {
  top: 32px;
}
@media (max-width: 782px) {
  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .vs-btn {
    top: 46px;
  }
}
@media (max-width: 600px) {
  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .vs-btn {
    top: 0;
  }
}

.post-password-form .theme-input-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 20px;
}
.post-password-form .theme-input-group .theme-input-style {
  width: 100%;
  max-width: calc(100% - 120px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  padding-left: 20px;
}
.post-password-form .theme-input-group .submit-btn {
  margin: 0;
  width: 120px;
  border: none;
  height: 50px;
  color: #fff;
  background-color: var(--theme-color);
}
.post-password-form .theme-input-group .submit-btn:hover {
  background-color: var(--title-color);
  opacity: 0.8;
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
  padding-top: 1em;
}
.page-links > .page-links-title {
  margin-right: 10px;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text),
.page-links > a {
  display: inline-block;
  padding: 5px 13px;
  background-color: var(--white-color);
  color: var(--title-color);
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-right: 10px;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text):hover,
.page-links > a:hover {
  opacity: 0.8;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: transparent;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text).current,
.page-links > a.current {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}
.page-links span.screen-reader-text {
  display: none;
}

.blog-single .wp-block-archives-dropdown {
  margin-bottom: 30px;
}
.blog-single.format-quote, .blog-single.format-link, .blog-single.tag-sticky-2, .blog-single.sticky {
  border-color: transparent;
  position: relative;
}
.blog-single.format-quote .blog-content, .blog-single.format-link .blog-content, .blog-single.tag-sticky-2 .blog-content, .blog-single.sticky .blog-content {
  background-color: var(--smoke-color);
  padding: 40px;
  border: none;
}
.blog-single.format-quote .blog-content:before, .blog-single.format-link .blog-content:before, .blog-single.tag-sticky-2 .blog-content:before, .blog-single.sticky .blog-content:before {
  display: none;
}
.blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
  content: "\f0c1";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-size: 5rem;
  opacity: 0.3;
  right: 15px;
  line-height: 1;
  top: 15px;
  color: var(--theme-color);
  z-index: 1;
}
.blog-single.tag-sticky-2::before, .blog-single.sticky::before {
  content: "Featured";
  right: 0;
  top: 0;
  font-size: 18px;
  color: var(--white-color);
  background-color: var(--theme-color);
  font-family: var(--title-font);
  opacity: 1;
  text-transform: capitalize;
  padding: 10px 23px;
  font-weight: 400;
}
.blog-single.format-quote:before {
  content: "\f10e";
}
.blog-single .blog-content .wp-block-categories-dropdown.wp-block-categories,
.blog-single .blog-content .wp-block-archives-dropdown {
  display: block;
  margin-bottom: 30px;
}

.blog-details .blog-single:before {
  display: none;
}
.blog-details .blog-single .blog-content {
  background-color: transparent;
  overflow: hidden;
}
.blog-details .blog-single.format-chat .blog-meta {
  margin-bottom: 20px;
}
.blog-details .blog-single.format-chat .blog-content > p:nth-child(2n) {
  background: var(--smoke-color);
  padding: 5px 20px;
}
.blog-details .blog-single.tag-sticky-2, .blog-details .blog-single.sticky, .blog-details .blog-single.format-quote, .blog-details .blog-single.format-link {
  box-shadow: none;
  border: none;
  background-color: transparent;
}
.blog-details .blog-single.tag-sticky-2:before, .blog-details .blog-single.sticky:before, .blog-details .blog-single.format-quote:before, .blog-details .blog-single.format-link:before {
  display: none;
}

.vs-search {
  background-color: #f3f3f3;
  margin-bottom: 30px;
  border: 1px solid #f3f3f3;
}
.vs-search .search-grid-content {
  padding: 30px;
  /* Small devices */
}
@media (max-width: 767px) {
  .vs-search .search-grid-content {
    padding: 20px;
  }
}
.vs-search .search-grid-title {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: -0.2em;
}
.vs-search .search-grid-title a {
  color: inherit;
}
.vs-search .search-grid-title a:hover {
  color: var(--theme-color);
}
.vs-search .search-grid-meta > * {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}
.vs-search .search-grid-meta > *:last-child {
  margin-right: 0;
}
.vs-search .search-grid-meta a,
.vs-search .search-grid-meta span {
  color: var(--body-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
    font-size: 14px;
    padding: 8px 16px;
  }
  .blog-single.format-quote .blog-content, .blog-single.format-link .blog-content, .blog-single.tag-sticky-2 .blog-content, .blog-single.sticky .blog-content {
    padding: 30px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
    font-size: 14px;
    padding: 8px 16px;
  }
  .blog-single.format-quote .blog-content, .blog-single.format-link .blog-content, .blog-single.tag-sticky-2 .blog-content, .blog-single.sticky .blog-content {
    padding: 30px 15px;
  }
}
@media (max-width: 768px) {
  .wp-block-search .wp-block-search__input {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .wp-block-latest-comments {
    padding-left: 10px;
  }
  .page--content.clearfix + .vs-comment-form {
    margin-top: 24px;
  }
}
/*------------------- 1.8. Media Query -------------------*/
/*-----------------
    3.2 Responsive Media Queries
-----------------*/
/**
@include respond-above(sm) {
  .element {
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  .element {
    font-weight: bold;
  }
}
**/
/**
@include respond-below(lg) {
  .element {
    font-weight: bold;
  }
}
@media (max-width: 767px) {
  .element {
    font-weight: bold;
  }
}
**/
/**
@include respond-between(sm, md) {
  .element {
    font-weight: bold;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .element {
    font-weight: bold;
  }
}
**/
/*=================================
    02. Reset
==================================*/
/*------------------- 2.1. Container -------------------*/
@media only screen and (min-width: 1300px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(var(--main-container) + var(--container-gutters));
    padding-left: calc(var(--container-gutters) / 2);
    padding-right: calc(var(--container-gutters) / 2);
  }
}
@media only screen and (max-width: 1600px) {
  .container-fluid.px-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .container-fluid.px-0 .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1399px) {
  .container-style2 {
    max-width: 1920px;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
  }
}
.container-style3 {
  --main-container: 1445px;
}

@media (min-width: 1500px) and (max-width: 1921px) {
  .container-style1 {
    max-width: 1576px;
    overflow: hidden;
    margin-right: 0;
    padding-right: 0;
  }
}
/*------------------- 2.2. Grid -------------------*/
.slick-track > [class*=col] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

@media (min-width: 767px) {
  .row:not([class*=gx-]) {
    --bs-gutter-x: 30px;
  }
}
.gy-gx {
  --bs-gutter-y: var(--bs-gutter-x);
}

.gy-30 {
  --bs-gutter-y: 30px;
}

.gx-15 {
  --bs-gutter-x: 15px;
}

@media (min-width: 1199px) {
  .gx-45 {
    --bs-gutter-x: 45px;
  }
  .gx-60 {
    --bs-gutter-x: 60px;
  }
  .gx-50 {
    --bs-gutter-x: 50px;
  }
  .gx-70 {
    --bs-gutter-x: 70px;
  }
  .gx-80 {
    --bs-gutter-x: 80px;
  }
}
@media (min-width: 1399px) {
  .gx-20 {
    --bs-gutter-x: 20px;
  }
  .gx-30 {
    --bs-gutter-x: 30px;
  }
  .gx-40 {
    --bs-gutter-x: 40px;
  }
}
/*------------------- 2.3. Input -------------------*/
select,
.form-control,
.form-select,
textarea,
input {
  height: 50px;
  padding: 0 30px 0 28px;
  padding-right: 45px;
  border: 1px solid var(--border-color);
  color: var(--body-color);
  background-color: var(--white-color);
  border-radius: 9999px;
  border-radius: 0;
  font-size: 14px;
  width: 100%;
}
@media (max-width: 767px) {
  select,
  .form-control,
  .form-select,
  textarea,
  input {
    padding: 0 30px 0 20px;
  }
}
select:focus,
.form-control:focus,
.form-select:focus,
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: none;
  background-color: var(--white-color);
}
select::placeholder,
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder,
input::placeholder {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
}
select:focus,
.form-control:focus,
.form-select:focus,
textarea:focus,
input:focus {
  border-color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}
select:focus::placeholder,
.form-control:focus::placeholder,
.form-select:focus::placeholder,
textarea:focus::placeholder,
input:focus::placeholder {
  opacity: 0;
  visibility: hidden;
}
select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}
select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}
select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}
select::placeholder,
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder,
input::placeholder {
  color: var(--body-color);
}
select.form-control-lg,
.form-control.form-control-lg,
.form-select.form-control-lg,
textarea.form-control-lg,
input.form-control-lg {
  height: 60px;
}
select.form-control-sm,
.form-control.form-control-sm,
.form-select.form-control-sm,
textarea.form-control-sm,
input.form-control-sm {
  height: 40px;
  font-size: 12px;
}
select[readonly],
.form-control[readonly],
.form-select[readonly],
textarea[readonly],
input[readonly] {
  color: var(--title-color);
}

.form-select,
select {
  display: block;
  width: 100%;
  line-height: 1.5;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 26px center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

textarea.form-control,
textarea {
  min-height: 150px;
  padding-top: 16px;
  padding-bottom: 17px;
  border-radius: 0;
}

input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type=checkbox]:checked ~ label:before {
  content: "\f00c";
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
input[type=checkbox] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
}
input[type=checkbox] ~ label:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 700;
  position: absolute;
  left: 0px;
  top: 3.5px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
}

input[type=radio] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type=radio] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}
input[type=radio] ~ label::before {
  content: "\f111";
  position: absolute;
  font-family: var(--icon-font);
  left: 0;
  top: -2px;
  width: 20px;
  height: 20px;
  padding-left: 0.5px;
  font-size: 8px;
  line-height: 19px;
  text-align: center;
  border: 1px solid var(--theme-color);
  border-radius: 100%;
  font-weight: 700;
  background: var(--white-color);
  color: transparent;
  transition: all 0.2s ease;
}
input[type=radio]:checked ~ label::before {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
}

label {
  margin-bottom: 0.5em;
  margin-top: -0.3em;
  display: block;
  color: var(--title-color);
  font-family: var(--body-font);
  font-size: 16px;
}

.form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
}
.form-group > i {
  position: absolute;
  right: calc(var(--bs-gutter-x) / 2 + 30px);
  top: 18px;
  font-size: 14px;
  color: #a0abb4;
}
.form-group.has-label > i {
  top: 45px;
}

textarea.is-invalid,
select.is-invalid,
input.is-invalid,
.was-validated input:invalid {
  border: 1px solid var(--error-color) !important;
  background-position: right calc(0.375em + 0.8875rem) center;
  background-image: none;
}
textarea.is-invalid:focus,
select.is-invalid:focus,
input.is-invalid:focus,
.was-validated input:invalid:focus {
  outline: 0;
  box-shadow: none;
}

textarea.is-invalid {
  background-position: top calc(0.375em + 0.5875rem) right calc(0.375em + 0.8875rem);
}

.row.no-gutters > .form-group {
  margin-bottom: 0;
}

.form-messages {
  display: none;
}
.form-messages.mb-0 * {
  margin-bottom: 0;
}
.form-messages.success {
  color: var(--success-color);
  display: block;
}
.form-messages.error {
  color: var(--error-color);
  display: block;
}
.form-messages pre {
  padding: 0;
  background-color: transparent;
  color: inherit;
}

/*------------------- 2.4. Slick Slider -------------------*/
.slick-track > [class*=col] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.slick-track {
  min-width: 100%;
}

.slick-slide img {
  display: inline-block;
}

.slick-dots {
  list-style-type: none;
  padding: 2px 0;
  margin: 38px 0 38px 0;
  line-height: 0;
  text-align: center;
  height: max-content;
}
.slick-dots li {
  display: inline-block;
  margin-right: 25px;
}
.slick-dots li:last-child {
  margin-right: 0;
}
.slick-dots button {
  font-size: 0;
  padding: 0;
  background-color: transparent;
  width: 8px;
  height: 8px;
  line-height: 0;
  border-radius: 9999px;
  border: none;
  background-color: var(--dots-bg, #D8D8D8);
  transition: all ease 0.4s;
  position: relative;
}
.slick-dots button:hover {
  border-color: var(--theme-color);
}
.slick-dots button:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -15px;
  border: 2px solid var(--theme-color);
  border-radius: 50%;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
}
.slick-dots .slick-active button {
  background-color: var(--theme-color);
}
.slick-dots .slick-active button::before {
  opacity: 1;
  visibility: visible;
}

.slick-arrow {
  display: inline-block;
  padding: 0;
  background-color: var(--white-color);
  position: absolute;
  top: 50%;
  border: 1px solid var(--smoke-color);
  left: var(--pos-x, -100px);
  width: var(--icon-size, 50px);
  height: var(--icon-size, 50px);
  font-size: var(--icon-font-size, 18px);
  margin-top: calc(var(--icon-size, 50px) / -2);
  z-index: 2;
  border-radius: 50%;
}
.slick-arrow.default {
  position: relative;
  --pos-x: 0;
  margin-top: 0;
}
.slick-arrow.slick-next {
  right: var(--pos-x, -100px);
  left: auto;
}
.slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}

.arrow-margin .slick-arrow {
  top: calc(50% - 30px);
}

.arrow-wrap .slick-arrow {
  opacity: 0;
  visibility: hidden;
}
.arrow-wrap:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.dot-style2 .slick-dots {
  margin-top: 10px;
  margin-bottom: 30px;
}
.dot-style2 .slick-dots button {
  width: 10px;
  height: 10px;
  border-radius: 9999px;
  background-color: var(--theme-color);
}
.dot-style2 .slick-dots button:before {
  display: none;
}
.dot-style2 .slick-dots button:hover {
  background-color: var(--title-color);
}
.dot-style2 .slick-dots li {
  margin: 0 5px;
}
.dot-style2 .slick-dots li.slick-active button {
  width: 40px;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .slick-arrow {
    --arrow-horizontal: -20px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .slick-arrow {
    --arrow-horizontal: 40px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .slick-arrow {
    --icon-size: 40px;
    margin-right: 40px;
  }
  .slick-arrow.slick-next {
    margin-right: 0;
    margin-left: 40px;
  }
  .slick-dots {
    margin: 8px 0 38px 0;
  }
}
/*------------------- 2.5. Mobile Menu -------------------*/
.vs-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100%;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;
}
.vs-menu-wrapper .mobile-logo {
  padding-bottom: 30px;
  padding-top: 40px;
  display: block;
  text-align: center;
  background-color: var(--theme-color);
}
.vs-menu-wrapper .mobile-logo svg {
  max-width: 185px;
}
.vs-menu-wrapper .vs-menu-toggle {
  border: none;
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  line-height: 1;
  width: 33px;
  height: 33px;
  line-height: 33px;
  font-size: 18px;
  z-index: 1;
  border-radius: 0%;
}
.vs-menu-wrapper .vs-menu-toggle:hover {
  background-color: var(--sec-color);
  color: var(--white-color);
}
.vs-menu-wrapper .vs-menu-area {
  width: 100%;
  max-width: 310px;
  background-color: #fff;
  border-right: 3px solid var(--theme-color);
  height: 100%;
  position: relative;
  left: -110%;
  opacity: 0;
  visibility: hidden;
  transition: all ease 1s;
  z-index: 1;
}
.vs-menu-wrapper.vs-body-visible {
  opacity: 1;
  visibility: visible;
}
.vs-menu-wrapper.vs-body-visible .vs-menu-area {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.vs-mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;
}
.vs-mobile-menu .new-label {
  margin-left: 5px;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.vs-mobile-menu ul {
  margin: 0;
  padding: 0 0;
}
.vs-mobile-menu ul li {
  border-bottom: 1px solid #fdedf1;
  list-style-type: none;
}
.vs-mobile-menu ul li li:first-child {
  border-top: 1px solid #fdedf1;
}
.vs-mobile-menu ul li a {
  display: block;
  position: relative;
  padding: 12px 0;
  line-height: 1;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--title-color);
}
.vs-mobile-menu ul li a:before {
  content: "\f105";
  font-family: var(--icon-font);
  position: relative;
  left: 0;
  top: 0;
  margin-right: 10px;
  display: inline-block;
}
.vs-mobile-menu ul li.vs-active > a {
  color: var(--theme-color);
}
.vs-mobile-menu ul li.vs-active > a:before {
  transform: rotate(90deg);
}
.vs-mobile-menu ul li.vs-active > a .vs-mean-expand {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.vs-mobile-menu ul li ul li {
  padding-left: 10px;
}
.vs-mobile-menu ul li ul li:last-child {
  border-bottom: none;
}
.vs-mobile-menu ul .vs-item-has-children > a .vs-mean-expand {
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 400;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-top: -12.5px;
  display: inline-block;
  text-align: center;
  background-color: var(--smoke-color);
  color: var(--title-color);
  box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
  border-radius: 50%;
}
.vs-mobile-menu ul .vs-item-has-children > a .vs-mean-expand:before {
  content: "\f067";
  font-family: var(--icon-font);
}
.vs-mobile-menu ul .vs-item-has-children.vs-active > a .vs-mean-expand:before {
  content: "\f068";
}
.vs-mobile-menu > ul {
  padding: 0 40px;
}
.vs-mobile-menu > ul > li:last-child {
  border-bottom: none;
}

.vs-menu-toggle {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 24px;
  border: none;
  display: inline-block;
  background-color: var(--white-color);
  color: var(--title-color);
  border-radius: 0%;
}
.vs-menu-toggle:hover {
  background-color: var(--sec-color);
  color: var(--white-color);
}
.vs-menu-toggle.style2 {
  background-color: var(--theme-color);
  border-radius: 0%;
  color: var(--white-color);
  width: 55px;
  height: 55px;
}
.vs-menu-toggle.style3 {
  background-color: var(--theme-color);
  color: var(--white-color);
}

@media (max-width: 400px) {
  .vs-menu-wrapper .vs-menu-area {
    width: 100%;
    max-width: 270px;
  }
  .vs-mobile-menu > ul {
    padding: 0 20px;
  }
}
/*=================================
    03. Utilities
==================================*/
/*------------------- 3.1. Preloader -------------------*/
.preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: var(--white-color);
}
.preloader .vs-btn {
  padding: 15px 20px;
  border-radius: 0;
  font-size: 14px;
}

.preloader-inner {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.preloader-inner img {
  display: block;
  margin: 0 auto 10px auto;
}

.loader {
  animation: loaderspin 1.5s infinite;
  height: 50px;
  width: 50px;
  display: block;
  margin: 0 auto;
}
.loader:before, .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1.5s infinite;
  background-color: var(--sec-color);
  box-shadow: 30px 0 0 var(--theme-color);
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1.5s infinite;
  background-color: var(--theme-color);
  box-shadow: 30px 0 0 var(--title-color);
}

@keyframes loaderspin {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}
@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--theme-color);
  }
  50% {
    box-shadow: 0 0 0 var(--theme-color);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--theme-color);
    margin-bottom: 10px;
  }
}
@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 var(--title-color);
  }
  50% {
    box-shadow: 0 0 0 var(--title-color);
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--title-color);
    margin-top: 0;
  }
}
@keyframes spin {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(36deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(72deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(108deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(144deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(180deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(216deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(252deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(288deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(324deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(360deg);
  }
}
@keyframes speed {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(-1deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes strikes {
  from {
    left: 25px;
  }
  to {
    left: -80px;
    opacity: 0;
  }
}
@keyframes dots {
  from {
    width: 0px;
  }
  to {
    width: 15px;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn, .loading-window {
  animation: fadeIn 0.4s both;
}

.loading-window {
  height: 200px;
  left: 50%;
  margin-left: -150px;
  margin-top: -100px;
  position: fixed;
  top: 50%;
  width: 300px;
  z-index: 99;
}
.loading-window .text {
  font-size: 16px;
  position: absolute;
  width: auto;
  top: 75%;
  left: 38%;
  margin: 0 auto;
}
.loading-window .dots {
  display: inline-block;
  width: 5px;
  overflow: hidden;
  vertical-align: bottom;
  animation: dots 1.5s linear infinite;
  transition: 1;
}

.car {
  position: absolute;
  width: 117px;
  height: 42px;
  left: 92px;
  top: 85px;
}
.car .strike {
  position: absolute;
  width: 11px;
  height: 1px;
  background: var(--theme-color);
  animation: strikes 0.2s linear infinite;
}
.car .strike2 {
  top: 11px;
  animation-delay: 0.05s;
}
.car .strike3 {
  top: 22px;
  animation-delay: 0.1s;
}
.car .strike4 {
  top: 33px;
  animation-delay: 0.15s;
}
.car .strike5 {
  top: 44px;
  animation-delay: 0.2s;
}
.car-detail {
  position: absolute;
  display: block;
  background: var(--sec-color);
  animation: speed 0.5s linear infinite;
}
.car-detail.spoiler {
  width: 0;
  height: 0;
  top: 7px;
  background: none;
  border: 20px solid transparent;
  border-bottom: 8px solid var(--sec-color);
  border-left: 20px solid var(--sec-color);
}
.car-detail.back {
  height: 20px;
  width: 92px;
  top: 15px;
  left: 0px;
}
.car-detail.center {
  height: 35px;
  width: 75px;
  left: 12px;
  border-top-left-radius: 30px;
  border-top-right-radius: 45px 40px;
  border: 4px solid var(--sec-color);
  background: none;
  box-sizing: border-box;
}
.car-detail.center1 {
  height: 35px;
  width: 35px;
  left: 12px;
  border-top-left-radius: 30px;
}
.car-detail.front {
  height: 20px;
  width: 50px;
  top: 15px;
  left: 67px;
  border-top-right-radius: 50px 40px;
  border-bottom-right-radius: 10px;
}
.car-detail.wheel {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 20px;
  left: 12px;
  border: 3px solid var(--theme-color);
  background: linear-gradient(45deg, transparent 45%, var(--sec-color) 46%, var(--sec-color) 54%, transparent 55%), linear-gradient(-45deg, transparent 45%, var(--sec-color) 46%, var(--sec-color) 54%, transparent 55%), linear-gradient(90deg, transparent 45%, var(--sec-color) 46%, var(--sec-color) 54%, transparent 55%), linear-gradient(0deg, transparent 45%, var(--sec-color) 46%, var(--sec-color) 54%, transparent 55%), radial-gradient(var(--sec-color) 29%, transparent 30%, transparent 50%, var(--sec-color) 51%), #333;
  animation-name: spin;
}
.car-detail.wheel2 {
  left: 82px;
}

/*------------------- 3.2. Buttons -------------------*/
.vs-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-transform: capitalize;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 17px 30px;
  border-radius: 0;
  z-index: 1;
  overflow: hidden;
  clip-path: polygon(140% 0, 100% 65%, 95% 100%, 0 100%, 0 0);
}
.vs-btn::after, .vs-btn::before {
  width: 103%;
  height: 103%;
  content: "";
  margin: auto;
  position: absolute;
  top: -2%;
  left: 0%;
  background: var(--sec-color);
  transition: all ease 0.5s;
  z-index: -1;
  border-radius: inherit;
}
.vs-btn:before {
  transform: rotateX(90deg);
}
.vs-btn:after {
  transform: rotateY(90deg);
}
.vs-btn > i {
  margin-left: 8px;
}
.vs-btn.style2 {
  background-color: var(--smoke-color);
  color: var(--title-color);
}
.vs-btn.style2::before, .vs-btn.style2::after {
  background-color: var(--title-color);
}
.vs-btn.style4, .vs-btn.style3 {
  background-color: var(--smoke-color);
  color: var(--theme-color);
}
.vs-btn.style3 {
  padding: 14px 23.5px;
}
.vs-btn.style5 {
  background-color: #0844bc;
  color: var(--white-color);
}
.vs-btn.style6 {
  background-color: var(--title-color);
  color: var(--white-color);
}
.vs-btn.style6:after, .vs-btn.style6::before {
  background-color: var(--theme-color);
}
.vs-btn.style7 {
  border-radius: 9999px;
  text-transform: capitalize;
}
.vs-btn.style8 {
  border: 2px solid var(--theme-color);
  color: var(--theme-color);
  background-color: transparent;
  border-radius: 9999px;
  padding: 17px 28px;
  text-transform: capitalize;
}
.vs-btn.style8:after, .vs-btn.style8:before {
  display: none;
}
.vs-btn.style8:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.vs-btn.style9 {
  background-color: var(--white-color);
  color: var(--theme-color);
  border-radius: 9999px;
  text-transform: capitalize;
}
.vs-btn.style10 {
  border: 2px solid var(--white-color);
  color: var(--white-color);
  background-color: transparent;
  border-radius: 9999px;
  padding: 17px 28px;
  text-transform: capitalize;
}
.vs-btn.style10:hover {
  border-color: transparent;
}
.vs-btn:hover {
  color: var(--white-color);
}
.vs-btn:hover:before {
  transform: rotateX(0);
}
.vs-btn:hover:after {
  transform: rotateY(0);
}
.vs-btn.style11 {
  clip-path: none;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 17px 10px;
}
.vs-btn.style11 > i {
  margin-left: 0;
}
.vs-btn.style12, .vs-btn.style14, .vs-btn.style16 {
  clip-path: none;
  background: var(--white-color);
  color: var(--theme-color);
  position: relative;
}
.vs-btn.style12::before, .vs-btn.style12::after, .vs-btn.style14::before, .vs-btn.style14::after, .vs-btn.style16::before, .vs-btn.style16::after {
  background-color: var(--theme-color);
}
.vs-btn.style12 .vs-btn__bar, .vs-btn.style14 .vs-btn__bar, .vs-btn.style16 .vs-btn__bar {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 0 15px 15px;
  border-color: transparent transparent var(--sec-color) transparent;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}
.vs-btn.style12:hover, .vs-btn.style14:hover, .vs-btn.style16:hover {
  color: var(--white-color);
}
.vs-btn.style12:hover .vs-btn__bar, .vs-btn.style14:hover .vs-btn__bar, .vs-btn.style16:hover .vs-btn__bar {
  border-color: transparent transparent var(--white-color) transparent;
}
.vs-btn.style13 {
  background-color: var(--sec-color);
  padding: 17px 30px;
}
.vs-btn.style13::before, .vs-btn.style13::after {
  background-color: var(--theme-color);
}
.vs-btn.style14 {
  background-color: var(--sec-color);
  color: var(--white-color);
}
.vs-btn.style14::before, .vs-btn.style14::after {
  background-color: var(--theme-color);
}
.vs-btn.style14 .vs-btn__bar {
  border-color: transparent transparent var(--white-color) transparent;
}
.vs-btn.style15 {
  padding: 17px 30px;
  background-color: var(--sec-color);
}
.vs-btn.style15::before, .vs-btn.style15::after {
  background-color: var(--theme-color);
}
.vs-btn.style16 {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.vs-btn.style16:after, .vs-btn.style16:before {
  background-color: var(--sec-color);
}

.icon-btn {
  padding: 0;
  font-size: var(--icon-font-size, 20px);
  width: var(--icon-size, 56px);
  height: var(--icon-size, 56px);
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--theme-color);
  color: var(--white-color);
  background-color: var(--theme-color);
  border-radius: 0%;
  transition: all ease 0.4s;
  position: relative;
  z-index: 1;
}
.icon-btn::before {
  content: "";
  position: absolute;
  left: var(--border-size, 2px);
  top: var(--border-size, 2px);
  right: var(--border-size, 2px);
  bottom: var(--border-size, 2px);
  background-color: var(--sec-color);
  border-radius: inherit;
  z-index: -1;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
}
.icon-btn:hover {
  background-color: var(--theme-color);
  border-color: transparent;
  color: var(--white-color);
}
.icon-btn:hover::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1.001);
  transform: scale(1.001);
}
.icon-btn.style2 {
  border: 2px solid var(--white-color);
  color: var(--white-color);
  background-color: transparent;
}
.icon-btn.style2:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.icon-btn.style4, .icon-btn.style3 {
  --btn-size: 55px;
  background-color: #f2f6ff;
  color: var(--theme-color);
  border-radius: 5px;
}
.icon-btn.style4:hover i, .icon-btn.style3:hover i {
  color: var(--white-color);
}
.icon-btn.style3 {
  z-index: 1;
}
.icon-btn.style3::before {
  z-index: -1;
}
.icon-btn.style3 i {
  transition: all 0.3s ease-in-out;
}
.icon-btn.style3 :hover {
  color: var(--white-color);
}
.icon-btn.style3 :hover > i {
  color: var(--white-color);
}
.icon-btn.style4 {
  background-color: var(--white-color);
  z-index: 1;
}
.icon-btn.style4::before {
  z-index: -1;
}
.icon-btn.style4 i {
  transition: all 0.3s ease-in-out;
}
.icon-btn.style4 :hover {
  color: var(--white-color);
}
.icon-btn.style4 :hover > i {
  color: var(--white-color);
}
.icon-btn.style5 {
  background-color: var(--white-color);
  color: var(--title-color);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.08);
}
.icon-btn.style5:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  box-shadow: none;
}
.icon-btn.style6 {
  background-color: var(--theme-color);
  color: var(--white-color);
  --btn-size: 55px;
  --btn-font-size: 17px;
}

.play-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
  border: none;
  background-color: transparent;
  padding: 0;
}
.play-btn > i {
  display: inline-block;
  width: var(--icon-size, 124px);
  height: var(--icon-size, 124px);
  line-height: var(--icon-size, 124px);
  font-size: var(--icon-font-size, 1.5em);
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  z-index: 1;
  transition: all ease 0.4s;
}
.play-btn:after, .play-btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-color);
  z-index: -1;
  border-radius: 50%;
  transition: all ease 0.4s;
}
.play-btn:after {
  animation-delay: 2s;
}
.play-btn.style3 i, .play-btn.style2 i {
  --icon-size: 60px;
  border-radius: 5px;
  --icon-font-size: 18px;
}
.play-btn.style2 > i {
  --icon-size: 120px;
  --icon-font-size: 48px;
  border-radius: 0;
  background-color: var(--theme-color);
}
.play-btn.style2:after, .play-btn.style2:before {
  border-radius: 0px;
  background-color: var(--theme-color);
}
.play-btn.style2:hover > i {
  background-color: var(--sec-color);
}
.play-btn.style2:hover::before, .play-btn.style2:hover::after {
  background-color: var(--sec-color);
}
.play-btn.style3 i {
  background-color: rgba(14, 89, 242, 0.7);
}
.play-btn.style3:after, .play-btn.style3::before {
  display: none;
}
.play-btn.style4 {
  --icon-size: 120px;
  --icon-font-size: 30px;
}
.play-btn.style4:after, .play-btn.style4:before,
.play-btn.style4 i {
  background-color: var(--white-color);
  color: var(--theme-color);
}
.play-btn.style5 i {
  border-radius: 0;
  --icon-size: 120px;
  --icon-font-size: 30px;
}
.play-btn.style5:after, .play-btn.style5:before {
  border: 2px solid var(--theme-color);
  background-color: transparent;
  border-radius: 0;
}
.play-btn.style6 {
  --icon-size: 60px;
  --icon-font-size: 18px;
}
.play-btn:hover:after, .play-btn:hover::before,
.play-btn:hover i {
  background-color: var(--title-color);
  color: var(--white-color);
}

.link-btn {
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  line-height: 0.8;
  position: relative;
  padding-bottom: 2px;
  margin-bottom: -2px;
  text-transform: uppercase;
}
.link-btn i {
  margin-left: 7px;
  font-size: 0.9rem;
}
.link-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: var(--theme-color);
  transition: all ease 0.4s;
}
.link-btn:hover {
  color: var(--theme-color);
}
.link-btn:hover::before {
  width: 100%;
}

.scroll-btn {
  position: fixed;
  bottom: 300px;
  right: 30px;
  z-index: 94;
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  border-radius: 50%;
  /* Small devices */
}
.scroll-btn i {
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  font-size: 16px;
  width: var(--btn-size, 50px);
  height: var(--btn-size, 50px);
  line-height: var(--btn-size, 50px);
  z-index: 2;
  border-radius: inherit;
  position: relative;
  transition: all ease 0.8s;
}
.scroll-btn:before {
  content: "";
  position: absolute;
  left: var(--extra-shape, -6px);
  top: var(--extra-shape, -6px);
  right: var(--extra-shape, -6px);
  bottom: var(--extra-shape, -6px);
  background-color: transparent;
  border-radius: inherit;
  z-index: 1;
  border: 2px dashed var(--theme-color);
  animation: spin 13s infinite linear;
}
.scroll-btn:focus i, .scroll-btn:hover i {
  background-color: var(--sec-color);
  color: var(--white-color);
}
.scroll-btn.show {
  bottom: 120px;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .scroll-btn {
    --btn-size: 40px;
    --extra-shape: -4px;
    right: 15px;
    bottom: 50px;
  }
  .scroll-btn.show {
    bottom: 15px;
  }
}

.scrollToTop {
  position: fixed;
  right: 60px;
  bottom: 500px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  z-index: 96;
}
.scrollToTop.show {
  bottom: 60px;
  opacity: 1;
  visibility: visible;
}

/* Large devices */
@media (max-width: 1199px) {
  .play-btn.style4 {
    --icon-size: 80px;
    --icon-font-size: 20px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .play-btn {
    --icon-size: 60px;
  }
  .scrollToTop {
    right: 20px;
  }
  .scrollToTop.show {
    bottom: 20px;
  }
}
.vs-icon {
  min-width: 50px;
  min-height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0%;
  border: 0;
  color: var(--white-color);
  font-size: 16px;
  background-color: var(--sec-color);
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.vs-icon::before, .vs-icon::after {
  border-radius: 0%;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--title-color);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0) rotate(360deg);
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.vs-icon:hover {
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.vs-icon:hover::before {
  transform: translate(-50%, -50%) scale(1) rotate(0deg);
  background-color: var(--title-color);
  transition: all 0.3s ease-in-out;
}
.vs-icon.style2 {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.vs-icon.style2:hover::before, .vs-icon.style2:hover::after {
  background-color: var(--sec-color);
}
.vs-icon.style3 {
  background-color: var(--smoke-color);
  color: var(--title-color);
}
.vs-icon.style3:hover {
  color: var(--white-color);
}
@media (max-width: 767px) {
  .vs-icon.style3 {
    min-width: 50px;
    min-height: 50px;
  }
}

/*------------------- 3.3. Titles -------------------*/
.sec-title {
  font-size: 48px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.2;
  margin: -0.25em 0 15px 0;
  letter-spacing: 0.01em;
}
.sec-title.h2 {
  font-size: 40px;
}
@media (max-width: 991px) {
  .sec-title {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .sec-title {
    font-size: 28px;
  }
}
@media (max-width: 319px) {
  .sec-title {
    font-size: 24px;
  }
}
.sec-title > span {
  color: var(--sec-color);
}

.sec-subtitle {
  text-transform: capitalize;
  color: var(--theme-color);
  font-family: var(--title-font);
  font-size: 20px;
  font-weight: 600;
  margin: -0.25em 0 25px 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  letter-spacing: 0.03em;
}
.sec-subtitle.style2 {
  color: var(--sec-color);
}
.sec-subtitle::before {
  content: "";
  width: 60px;
  height: 2px;
  background-color: var(--theme-color);
  display: inline-block;
}
.sec-subtitle.style3::before, .sec-subtitle.style3::after, .sec-subtitle.style4::before, .sec-subtitle.style4::after {
  content: "";
  width: 60px;
  height: 2px;
  background-color: var(--white-color);
  display: inline-block;
}
.sec-subtitle.style4 {
  color: var(--sec-color);
}
.sec-subtitle.style4::before, .sec-subtitle.style4::after {
  background-color: var(--theme-color);
}
.sec-subtitle.style5::before, .sec-subtitle.style5::after {
  background-color: var(--sec-color);
}
.sec-subtitle.style6::before {
  background-color: var(--white-color);
}

.white-title .sec-subtitle,
.white-title .sec-title {
  color: var(--white-color);
}

.title-area {
  margin-bottom: calc(var(--section-title-space) - 30px);
}

.sec-btns {
  padding: 13px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.sec-btns i.fa-arrow-left {
  margin-left: 0;
  margin-right: 10px;
}

.sec-btns2 {
  margin-bottom: calc(var(--section-title-space) - 5px);
}

.sec-line-wrap {
  display: flex;
  gap: 25px;
  align-items: center;
  margin: 0 0 80px 0;
}
.sec-line-wrap .sec-title2 {
  margin: -0.3em 0 -0.4em 0;
}

.sec-line {
  background-color: var(--theme-color);
  flex: 1;
  height: 2px;
}

.border-title {
  position: relative;
  padding: 0 0 5px 0;
  margin-bottom: 0;
}
.border-title-border {
  width: 48px;
  height: 2px;
  display: inline-block;
  background-color: var(--theme-color);
  margin-bottom: 25px;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .sec-title4 {
    font-size: 42px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .sec-title4 {
    font-size: 36px;
    line-height: 1.4;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 36px;
  }
  .sec-line-wrap {
    margin: 0 0 60px 0;
  }
  .sec-subtitle2 {
    margin-bottom: 15px;
  }
  .title-area {
    margin-bottom: 40px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 30px;
  }
  .sec-subtitle {
    font-size: 16px;
  }
  .sec-line-wrap {
    margin: 0 0 40px 0;
    gap: 10px;
  }
  .sec-title4 {
    font-size: 30px;
    line-height: 1.4;
  }
}
/*------------------- 3.4. Common -------------------*/
body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

body::-webkit-scrollbar-track {
  background: rgba(252, 0, 18, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--theme-color);
  border-radius: 20px;
}

.image-scale-hover, .service-block--style2 {
  overflow: hidden;
}
.image-scale-hover img, .service-block--style2 img {
  transition: all ease 0.4s;
  transform: scale(1.001);
}
.image-scale-hover:hover img, .service-block--style2:hover img {
  transform: scale(1.2);
}

.alert-dismissible .btn-close {
  font-size: 18px;
  line-height: 1;
}

.z-index-step1 {
  position: relative;
  z-index: 4 !important;
}

.z-index-common {
  position: relative;
  z-index: 1;
}

.z-index-n1 {
  z-index: -1;
}

.media-body {
  flex: 1;
}

.badge {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 0.35em 0.55em;
  border-radius: 50%;
}

.hr-style1 {
  background-color: var(--white-color);
  margin: 20px 0 30px 0;
}

.new-label {
  font-size: 12px;
  background-color: var(--error-color);
  color: var(--white-color);
  line-height: 1;
  padding: 1px 5px;
  border-radius: 4px;
  text-transform: capitalize;
  position: relative;
  top: -1px;
}

.big-name {
  font-size: 450px;
  color: var(--white-color);
  -webkit-text-stroke: 2px #eef0ff;
  line-height: 1;
  letter-spacing: -0.08em;
  font-weight: 400;
  position: relative;
  z-index: 31;
  text-align: right;
  margin: -234px 30px -59px 0;
}

.sec-big-text2,
.sec-big-text {
  font-size: 160px;
  font-weight: 700;
  font-family: var(--title-font);
  line-height: 1;
  display: block;
  color: #f1f3f9;
  position: absolute;
  left: 0;
  right: 0;
  top: -37px;
  z-index: -1;
}

.sec-big-text2 {
  top: auto;
  left: auto;
  right: 120px;
  bottom: -26px;
}

.home-five-shape1 {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #f6f7fc;
  width: 100%;
  max-width: 1255px;
  z-index: -3;
}

.home-five-shape2 {
  position: absolute;
  left: -428px;
  bottom: 250px;
  width: 1024px;
  height: 1024px;
  border: 50px solid var(--theme-color);
  border-radius: 50%;
  z-index: -2;
}

.home-five-shape3 {
  position: absolute;
  right: 60px;
  bottom: 325px;
}

.home-five-wrap1 {
  position: relative;
  border-bottom: 7px solid var(--theme-color);
  z-index: 1;
}

/* Hight Resoulation devices */
@media (min-width: 1921px) {
  .ls-wrapper > .ls-layer.d-hd-none,
  .ls-wrapper > a > .ls-layer.d-hd-none,
  .d-hd-none {
    display: none !important;
  }
}
@media (min-width: 1500px) {
  .d-xxxl-block {
    display: block !important;
  }
}
/* Extra large devices */
@media (max-width: 1500px) {
  .big-name {
    font-size: 320px;
    margin: -168px 30px -59px 0;
  }
  .home-five-shape3 {
    right: 30px;
    bottom: 50px;
    max-width: 200px;
  }
  .home-five-shape2 {
    left: -18%;
    bottom: 34%;
    width: 500px;
    height: 500px;
  }
  .home-five-shape1 {
    max-width: 100%;
    right: 60px;
    width: auto;
  }
  .sec-big-text2,
  .sec-big-text {
    font-size: 130px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .big-name {
    font-size: 245px;
    margin: -128px 40px -42px 0;
  }
  .home-five-shape1 {
    right: 0;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .big-name {
    font-size: 190px;
    margin: -100px 40px -42px 0;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .big-name {
    font-size: 146px;
    margin: -78px 40px -25px 0;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .big-name {
    font-size: 75px;
    margin: -20px 0 -12px 0;
    letter-spacing: 0;
    text-align: center;
  }
}
.text-box {
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 40px 60px;
  max-width: 330px;
  box-shadow: 0px 4px 25px rgba(0, 70, 226, 0.07);
  min-height: 167px;
}
.text-box__number {
  font-size: 64px;
  color: var(--theme-color);
  font-family: var(--title-font);
  font-weight: 700;
}
.text-box__title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.text-box.style2 {
  position: absolute;
  padding: 19px 30px;
  min-height: 112px;
  max-width: 269px;
  background-color: var(--theme-color);
  top: 182px;
  left: 163px;
}
@media (max-width: 991px) {
  .text-box.style2 {
    top: 20px;
    left: 19px;
  }
}
.text-box.style2 > span, .text-box.style2 > h3 {
  color: var(--white-color);
}
.text-box.style2::before {
  position: absolute;
  content: "";
  width: 308px;
  height: 152px;
  border: 2px solid var(--theme-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-box {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 216px;
}
.icon-box__icon {
  min-width: 60px;
  min-height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border-radius: 0%;
}
.icon-box__title {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .icon-box__title {
    font-size: 18px;
  }
}
.icon-box.style2 {
  background-color: var(--smoke-color);
  max-width: 100%;
  padding: 30px;
  align-items: center;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
.icon-box.style2 .icon-box__icon {
  background-color: var(--white-color);
  border-radius: 0;
  color: var(--white-color);
}
.icon-box.style2 .icon-box__title {
  margin-bottom: 6px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}
.icon-box.style2 .icon-box__text {
  margin-bottom: 0;
  transition: all 0.3s ease-in-out;
}
.icon-box.style2:hover {
  background-color: var(--theme-color);
}
.icon-box.style2:hover .icon-box__title, .icon-box.style2:hover .icon-box__text {
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.icon-box.style3 {
  max-width: 100%;
  margin-bottom: 20px;
}
.icon-box.style3:last-child {
  margin-bottom: 0px;
}
.icon-box.style3 .icon-box__icon {
  border-radius: 0;
  font-size: 30px;
  color: var(--white-color);
  min-width: 70px;
  min-height: 70px;
  transition: all 0.3s ease-in-out;
}
.icon-box.style3 .icon-box__text {
  margin-bottom: 6px;
  font-size: 16px;
  font-family: var(--title-font);
  font-weight: 700;
}
.icon-box.style3 .icon-box__title {
  font-weight: 700;
  font-family: var(--title-color);
  font-family: var(--title-font);
  font-size: 24px;
}
@media (max-width: 767px) {
  .icon-box.style3 .icon-box__title {
    font-size: 20px;
  }
}
.icon-box.style3 .icon-box__title > a {
  transition: all 0.3s ease-in-out;
}
.icon-box.style3 .icon-box__title > p {
  margin-bottom: 0;
}
.icon-box.style3 .icon-box__title > a, .icon-box.style3 .icon-box__title > p {
  color: var(--title-color);
  font-family: var(--title-font);
}
.icon-box.style3:hover .icon-box__icon {
  background-color: var(--sec-color);
}
.icon-box.style3:hover .icon-box__title > a {
  color: var(--sec-color);
}

.list-style {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.list-style > li {
  padding-left: 28px;
  position: relative;
  margin-bottom: 10px;
  color: var(--title-color);
  font-weight: 500;
}
.list-style > li:last-child {
  margin-bottom: 0;
}
.list-style > li::before {
  content: "\f058";
  left: 0;
  position: absolute;
  top: 0px;
  font-family: var(--icon-font);
  font-weight: 600;
  color: var(--theme-color);
}

.divider1,
.divider2 {
  background-color: var(--border-color);
  opacity: 1;
}

.divider2 {
  height: 2px;
}

.banner-block {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 119px 33px;
}
.banner-block::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--title-color);
  opacity: 0.8;
  z-index: -1;
  left: 0;
  top: 0;
}
.banner-block__logo {
  margin-bottom: 19px;
}
.banner-block__title {
  color: var(--white-color);
  margin-bottom: 45px;
  line-height: 46px;
}
.banner-block__title > span {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA3IiBoZWlnaHQ9IjE0IiB2aWV3Qm94PSIwIDAgMTA3IDE0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMiAxMkMyMC4yOTUgNi4zNTQ0NCA2Ni41MDggLTIuOTE1MjIgMTA1IDUuMTcwNjkiIHN0cm9rZT0iI0VGMEYzQSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-position: bottom;
  color: var(--sec-color);
  padding-bottom: 12px;
  display: block;
  background-size: auto;
}

.bg-shape1 {
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: 500px;
  width: 100%;
  right: 0;
  background-color: var(--smoke-color);
  z-index: -1;
}
.bg-shape1 .shape1,
.bg-shape1 .shape2 {
  position: absolute;
}
@media (max-width: 767px) {
  .bg-shape1 .shape1,
  .bg-shape1 .shape2 {
    display: none;
  }
}
.bg-shape1 .shape2 {
  top: 74px;
  left: 207px;
}

.it-reveal-animation {
  clip-path: inset(0 0 100% 0);
  transition: 2s cubic-bezier(0.5, 0.5, 0, 1);
}

.it-reveal-animation.active {
  clip-path: inset(0 0 0 0);
  transition: 2s cubic-bezier(0.5, 0.5, 0, 1);
}

.table {
  border: 0;
  margin: -0.25rem -0.25rem;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table > :not(caption) > * > * {
  padding: 0.3rem 0.3rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border: 0;
  color: var(--white-color);
  text-align: left;
}

.table > tbody > tr > td:first-child {
  width: 50px;
}

/*------------------- 3.6. Font -------------------*/
.font-icon {
  font-family: var(--icon-font);
}

.font-title {
  font-family: var(--title-font);
}

.font-body {
  font-family: var(--body-font);
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-extrabold {
  font-weight: 800;
}

.fs-md {
  font-size: 18px;
}

.fs-xs {
  font-size: 14px;
}

/*------------------- 3.7. Background -------------------*/
.bg-theme {
  background-color: var(--theme-color) !important;
}

.bg-smoke {
  background-color: var(--smoke-color) !important;
}

.bg-white {
  background-color: var(--white-color) !important;
}

.bg-black {
  background-color: var(--black-color) !important;
}

.bg-title {
  background-color: var(--title-color) !important;
}

.bg-gray {
  background-color: #e5e5e5;
}

.background-image,
[data-bg-src] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-fluid {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.bg-auto {
  background-size: auto auto;
}

/*------------------- 3.8. Text Color -------------------*/
.text-theme {
  color: var(--theme-color) !important;
}

.text-title {
  color: var(--title-color) !important;
}

.text-body {
  color: var(--body-color) !important;
}

.text-white {
  color: var(--white-color) !important;
}

.text-yellow {
  color: var(--yellow-color) !important;
}

.text-success {
  color: var(--success-color) !important;
}

.text-error {
  color: var(--error-color) !important;
}

.text-inherit {
  color: inherit;
}
.text-inherit:hover {
  color: var(--theme-color);
}

a.text-theme:hover,
.text-reset:hover {
  text-decoration: underline;
}

.text-light2 {
  color: #919398;
}

/*------------------- 3.9. Overlay -------------------*/
.overlay,
.overlay2,
.overlay3,
.overlay4,
.overlay5,
.overlay6,
.overlay7 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.overlay2 {
  background: linear-gradient(90deg, #01133c 48.92%, rgba(1, 19, 60, 0) 119.11%);
  opacity: 0.9;
  z-index: -1;
}

.overlay3 {
  background: linear-gradient(90deg, #01133c 41.07%, rgba(1, 19, 60, 0) 100%);
  opacity: 0.9;
}

.overlay4 {
  background-color: var(--title-color);
  opacity: 0.85;
}

.overlay5 {
  background: linear-gradient(90deg, #01133c 57.39%, rgba(1, 19, 60, 0) 122.86%);
  opacity: 0.9;
}

.overlay6 {
  opacity: 0.85;
  background-color: var(--theme-color);
}

.overlay7 {
  background: linear-gradient(90deg, #01133c 41.07%, rgba(1, 19, 60, 0) 100%);
  opacity: 0.9;
  z-index: -1;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.element {
  position: absolute;
  width: 57%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--theme-color);
  clip-path: polygon(0 0, 100% 0, 74% 100%, 0% 100%);
  z-index: -1;
}
@media (max-width: 1199px) {
  .element {
    width: 75%;
  }
}
@media (max-width: 767px) {
  .element {
    width: 100%;
  }
}

[data-overlay] {
  position: relative;
}
[data-overlay] [class^=col-],
[data-overlay] [class*=col-] {
  z-index: 1;
}

[data-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

[data-overlay=theme]:before {
  background-color: var(--theme-color);
}

[data-overlay=title]:before {
  background-color: var(--title-color);
}

[data-overlay=white]:before {
  background-color: var(--white-color);
}

[data-overlay=black]:before {
  background-color: var(--black-color);
}

[data-opacity="1"]:before {
  opacity: 0.1;
}

[data-opacity="2"]:before {
  opacity: 0.2;
}

[data-opacity="3"]:before {
  opacity: 0.3;
}

[data-opacity="4"]:before {
  opacity: 0.4;
}

[data-opacity="5"]:before {
  opacity: 0.5;
}

[data-opacity="6"]:before {
  opacity: 0.6;
}

[data-opacity="7"]:before {
  opacity: 0.7;
}

[data-opacity="8"]:before {
  opacity: 0.8;
}

[data-opacity="9"]:before {
  opacity: 0.9;
}

[data-opacity="10"]:before {
  opacity: 1;
}

/*------------------- 3.10. Animation -------------------*/
.jump-reverse-img,
.jump-img,
.jump-reverse,
.jump {
  animation: jumpping var(--duration, 6s) infinite linear;
}

.jump-reverse-img,
.jump-img {
  --duration: 5s;
}

.jump-reverse-img,
.jump-reverse {
  --jump-y: -20px;
}

.rotate-reverse-img,
.rotate-img,
.rotate-reverse,
.rotate {
  animation: rotate var(--duration, 12s) infinite linear;
}

.rotate-reverse-img,
.rotate-img {
  --duration: 40s;
}

.rotate-reverse {
  --rotate-angle: -360deg;
}

.spin {
  animation: spin var(--duration, 40s) infinite linear;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.wow-animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadein {
  --animation-name: fadein-custom;
}

.slideinup {
  --animation-name: slideinup;
}

.slideindown {
  --animation-name: slideindown;
}

.slideinleft {
  --animation-name: slideinleft;
}

.slideinright {
  --animation-name: slideinright;
}

.animated {
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-name: var(--animation-name);
}

.ripple-animation, .play-btn:after, .play-btn:before {
  animation-duration: var(--ripple-ani-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ripple;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes slideinup {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideindown {
  0% {
    opacity: 0;
    transform: translateY(-70px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideinleft {
  0% {
    opacity: 0;
    transform: translateX(-70px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideinright {
  0% {
    opacity: 0;
    transform: translateX(70px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fadein-custom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes jumpping {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  40% {
    transform: translate3d(0, var(--jump-y, 20px), var(--jump-x, 0));
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(var(--rotate-angle, 360deg));
  }
}
@keyframes leftToRight {
  0%, 100% {
    left: 0px;
  }
  50% {
    left: 56px;
  }
}
/*------------------- 3.11. Animation -------------------*/
.cursor1 {
  position: fixed;
  width: 40px;
  height: 40px;
  border: 1px solid var(--theme-color);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.15s;
  z-index: 999;
  mix-blend-mode: difference;
}
@media (max-width: 1200px) {
  .cursor1 {
    display: none;
  }
}
.cursor1.hide {
  opacity: 0;
  visibility: hidden;
}

.cursor2 {
  position: fixed;
  width: 8px;
  height: 8px;
  background-color: var(--theme-color);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.2s;
  z-index: 999;
  mix-blend-mode: difference;
}
@media (max-width: 1200px) {
  .cursor2 {
    display: none;
  }
}
.cursor2.hide {
  opacity: 0;
  visibility: hidden;
}
.cursor2.circle {
  width: 60px;
  height: 60px;
}

/*=================================
    04. Template Style
==================================*/
/*------------------- 4.1. Widget  -------------------*/
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_archive ul,
.widget_categories ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget_nav_menu > ul,
.widget_meta > ul,
.widget_pages > ul,
.widget_archive > ul,
.widget_categories > ul {
  margin: 0 0 -38px 0;
}
.widget_nav_menu a,
.widget_meta a,
.widget_pages a,
.widget_archive a,
.widget_categories a {
  display: flex;
  margin: 0px 0px 20px;
  padding: 5px 20px 5px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  align-items: center;
  justify-content: space-between;
  background: var(--white-color);
  color: var(--title-color);
  text-transform: capitalize;
  font-family: var(--body-font);
  min-height: 50px;
  position: relative;
}
.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li {
  display: block;
  position: relative;
}
.widget_nav_menu li:last-child a,
.widget_meta li:last-child a,
.widget_pages li:last-child a,
.widget_archive li:last-child a,
.widget_categories li:last-child a {
  margin-bottom: 0;
}
.widget_nav_menu li > span,
.widget_meta li > span,
.widget_pages li > span,
.widget_archive li > span,
.widget_categories li > span {
  text-align: center;
  position: absolute;
  right: 0;
  top: -4.5px;
  font-size: 16px;
  transition: all ease 0.4s;
}
.widget_nav_menu li:hover > span,
.widget_meta li:hover > span,
.widget_pages li:hover > span,
.widget_archive li:hover > span,
.widget_categories li:hover > span {
  color: var(--theme-color);
}
.widget_nav_menu .children,
.widget_meta .children,
.widget_pages .children,
.widget_archive .children,
.widget_categories .children {
  margin-left: 10px;
}

.widget_categories a::before, .widget_categories a::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 100%;
  left: 0;
  display: block;
  transition: all 0.3s ease-in-out;
}
.widget_categories a::before {
  top: 0;
  background-color: var(--theme-color);
}
.widget_categories a::after {
  top: auto;
  bottom: 0;
  background-color: var(--sec-color);
  height: 0;
}
.widget_categories a:hover::after {
  height: 100%;
}
.widget_categories a:hover::before {
  height: 0;
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a {
  padding-right: 20px;
}

.widget_nav_menu .sub-menu {
  margin-left: 10px;
}

.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.wp-block-archives a:not(:hover) {
  color: inherit;
}

.vs-blog ul.wp-block-archives li {
  margin: 5px 0;
}

/* Small devices */
@media (max-width: 767px) {
  .widget_nav_menu a,
  .widget_meta a,
  .widget_pages a,
  .widget_archive a,
  .widget_categories a {
    font-size: 14px;
  }
}
.cat-item__number {
  transition: all 0.4s ease;
  color: var(--theme-color);
}
.cat-item:hover .cat-item__number {
  color: var(--sec-color);
}

.widget {
  padding: var(--widget-padding-y, 40px) var(--widget-padding-x, 30px);
  background-color: var(--smoke-color);
  position: relative;
  margin-bottom: 30px;
  border: 6px;
}
.widget_banner {
  padding: 0;
}
.widget select,
.widget input {
  height: 55px;
  border: none;
  background-color: #fff;
  padding-left: 20px;
  font-weight: 400;
}

.widget_title {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.41666667;
  margin: -0.38em 0 30px 0;
  font-family: var(--title-font);
  text-transform: capitalize;
  padding: 0 0px 5px 0;
}
.widget_title::before, .widget_title::after {
  position: absolute;
  content: "";
  min-width: 50px;
  min-height: 2px;
  bottom: 0;
  left: 0;
}
.widget_title::before {
  background-color: var(--sec-color);
  z-index: 1;
}
.widget_title::after {
  min-width: 100%;
  background-color: var(--border-color);
}

.widget .search-form {
  position: relative;
  display: flex;
}
.widget .search-form input {
  flex: 1;
}
.widget .search-form button {
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  height: 55px;
  width: 55px;
  line-height: 55px;
  font-size: 17px;
}
.widget .search-form button:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.wp-block-tag-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0;
  align-items: center;
}

.wp-block-tag-cloud a,
.tagcloud a {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 20px 21px 19px 21px;
  margin-right: 0;
  margin-bottom: 0;
  color: var(--black-color);
  text-transform: uppercase;
  background: var(--silverLightLight-color);
  border: 1px solid var(--border-color);
  border-radius: 50px;
  font-family: var(--title-font);
}
.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--white-color) !important;
}

.tagcloud {
  margin-right: -5px;
  margin-bottom: -10px;
}
.tagcloud a {
  background-color: var(--white-color);
  color: var(--title-color);
}

.recent-post,
.recent-product {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.recent-post:last-child,
.recent-product:last-child {
  margin-bottom: 0;
}
.recent-post .media-img,
.recent-product .media-img {
  margin-right: 15px;
  width: 100px;
  overflow: hidden;
}
.recent-post .media-img img,
.recent-product .media-img img {
  width: 100%;
  border-radius: 0px;
  transition: all ease 0.4s;
}
.recent-post .post-title,
.recent-product .post-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  margin: 0 0 3px 0;
}
.recent-post .post-title > a,
.recent-product .post-title > a {
  display: block;
}
.recent-post .recent-post-meta,
.recent-product .recent-post-meta {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: var(--theme-color);
  font-family: var(--title-font);
  display: inline-flex;
  align-items: center;
}
.recent-post .recent-post-meta i,
.recent-product .recent-post-meta i {
  margin-right: 5px;
  color: var(--theme-color);
}
.recent-post:hover .media-img img,
.recent-product:hover .media-img img {
  transform: scale(1.1);
}

.recent-product .media-img {
  min-width: 100px;
  min-height: 80px;
  background: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.recent-product .media-img img {
  width: inherit;
}
.recent-product .recent-post-meta {
  font-size: 16px;
}

.wp-block-calendar,
.calendar_wrap {
  position: relative;
  background-color: #fff;
  padding-bottom: 0;
  border: none;
}
.wp-block-calendar span[class*=wp-calendar-nav],
.calendar_wrap span[class*=wp-calendar-nav] {
  position: absolute;
  top: 8px;
  left: 20px;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 500;
  z-index: 1;
}
.wp-block-calendar span[class*=wp-calendar-nav] a,
.calendar_wrap span[class*=wp-calendar-nav] a {
  color: inherit;
}
.wp-block-calendar span.wp-calendar-nav-next,
.calendar_wrap span.wp-calendar-nav-next {
  left: auto;
  right: 20px;
}
.wp-block-calendar caption,
.calendar_wrap caption {
  caption-side: top;
  text-align: center;
  color: var(--title-color);
  background-color: #f1dbbe;
}
.wp-block-calendar th,
.calendar_wrap th {
  font-size: 14px;
  padding: 5px 5px;
  border: none;
  text-align: center;
  border-right: 1px solid #fff;
  color: #01133c;
  font-weight: 500;
}
.wp-block-calendar th a,
.calendar_wrap th a {
  color: inherit;
}
.wp-block-calendar td,
.calendar_wrap td {
  font-size: 14px;
  padding: 5px;
  color: #01133c;
  border: 1px solid #ededed;
  text-align: center;
  background-color: transparent;
  transition: all ease 0.4s;
}
.wp-block-calendar #today,
.calendar_wrap #today {
  color: var(--theme-color);
  background-color: var(--white-color);
  border-color: #ededed;
}
.wp-block-calendar thead,
.calendar_wrap thead {
  background-color: #f6f6f6;
}
.wp-block-calendar .wp-calendar-table,
.calendar_wrap .wp-calendar-table {
  margin-bottom: 0;
}
.wp-block-calendar .wp-calendar-nav .pad,
.calendar_wrap .wp-calendar-nav .pad {
  display: none;
}

.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.sidebar-gallery .gallery-thumb {
  overflow: hidden;
  border-radius: 5px;
}
.sidebar-gallery .gallery-thumb img {
  width: 100%;
  transform: scale(1);
  transition: all ease 0.4s;
}
.sidebar-gallery .gallery-thumb:hover img {
  transform: scale(1.2);
}

.widget_shopping_cart {
  text-align: left;
}
.widget_shopping_cart ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget_shopping_cart .mini_cart_item {
  position: relative;
  border-bottom: 1px solid var(--theme-color);
  padding: 0 0 32px 0px;
  margin: 0 0 27px 0;
  min-height: 90px;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.widget_shopping_cart .mini_cart_item a {
  color: var(--title-color);
}
.widget_shopping_cart .mini_cart_item > a > img {
  max-width: 80px;
  margin-right: 15px;
}
.widget_shopping_cart .remove {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--title-color);
  line-height: 1;
  font-size: 18px;
}
.widget_shopping_cart .remove:hover {
  color: var(--theme-color);
}
.widget_shopping_cart .img {
  position: absolute;
  left: 0;
  top: 3px;
  width: 90px;
  height: 90px;
  display: inline-block;
  border: 1px solid var(--sec-color);
}
.widget_shopping_cart .product-title {
  font-size: 14px;
  color: var(--title-color);
  font-weight: 400;
  margin-bottom: 0;
  display: inline-block;
}
.widget_shopping_cart .amount {
  display: block;
  font-weight: 600;
  color: var(--title-color);
  font-size: 16px;
}
.widget_shopping_cart .quantity {
  display: inline-flex;
  margin-top: 0px;
}
.widget_shopping_cart .qut-btn {
  border: 1px solid var(--sec-color);
  background-color: transparent;
  display: inline-block;
  background-color: transparent;
  width: 25px;
  height: 25px;
  padding: 0;
  font-size: 12px;
  z-index: 1;
  position: relative;
}
.widget_shopping_cart .qut-btn:hover {
  background-color: var(--title-color);
  border-color: transparent;
  color: var(--white-color);
}
.widget_shopping_cart .qty-input {
  border: 1px solid var(--sec-color);
  text-align: center;
  width: max-content;
  min-width: 40px;
  font-size: 12px;
  padding: 0;
  height: 25px;
  margin: 0 -1px;
}
.widget_shopping_cart .subtotal {
  float: right;
  text-align: right;
  font-size: 12px;
  margin-top: 19px;
}
.widget_shopping_cart .subtotal > span:not(.amount) {
  color: var(--body-color);
  font-weight: 300;
}
.widget_shopping_cart .subtotal .amount {
  font-size: 12px;
  display: inline-block;
}
.widget_shopping_cart .total {
  color: var(--title-color);
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 25px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.widget_shopping_cart .total .amount {
  font-size: inherit;
  display: inline-block;
  color: var(--theme-color);
}
.widget_shopping_cart .buttons {
  margin: 0;
  gap: 15px;
}

.sidebar-area {
  margin-bottom: -10px;
  position: sticky;
  top: 30px;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .sidebar-area {
    margin-bottom: 0;
  }
}
.sidebar-area ul.wp-block-latest-posts {
  margin-bottom: 0;
}
.sidebar-area ul.wp-block-latest-posts li:last-child {
  margin-bottom: 0;
}
.sidebar-area .newsletter-form button {
  width: 100%;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  height: 60px;
  margin-top: 10px;
}
.sidebar-area .widget .wp-block-search {
  margin-bottom: 0;
}
.sidebar-area .wp-block-group__inner-container h2 {
  font-size: 20px;
  line-height: 1em;
  margin-bottom: 20px;
  margin-top: -0.07em;
}
.sidebar-area ol.wp-block-latest-comments {
  padding: 0;
  margin: 0;
}
.sidebar-area ol.wp-block-latest-comments li {
  line-height: 1.5;
  margin: 0 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.sidebar-area ol.wp-block-latest-comments li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.recent-product {
  display: flex;
}
.recent-product .product-title {
  font-size: 16px;
}

.widget-workhours ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget-workhours li {
  background-color: var(--white-color);
  padding: 14.5px 20px;
  margin: 0 0 15px 0;
  font-family: var(--title-font);
  font-weight: 500;
}
.widget-workhours li:last-child {
  margin-bottom: 0;
}
.widget-workhours li i {
  margin-right: 10px;
}

.quote-box {
  position: relative;
  text-align: center;
  padding: 80px 30px;
  margin: 0 0 30px 0;
}
.quote-box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 26, 62, 0.7);
}
.quote-box__title {
  color: var(--white-color);
  position: relative;
  z-index: 1;
  max-width: 200px;
  margin: -0.3em auto 25px auto;
}

/* Large devices */
@media (max-width: 1199px) {
  .widget {
    --widget-padding-y: 25px;
    --widget-padding-x: 25px;
  }
  .widget_title {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .recent-post .post-title {
    font-size: 14px;
    line-height: 22px;
  }
  .recent-post .recent-post-meta a {
    font-size: 12px;
  }
  .widget-workhours li {
    padding: 14.5px 20px;
    font-size: 14px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .wp-block-tag-cloud a,
  .tagcloud a {
    padding: 10.5px 18px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .contact-widget .icon {
    --icon-size: 30px;
    --icon-font-size: 12px;
    top: 5px;
  }
  .contact-widget .contact {
    padding: 0 0 0 45px;
    margin: 0 0 20px 0;
    border-bottom: none;
  }
  .sidebar-gallery {
    gap: 10px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .widget {
    padding: 30px 20px;
  }
}
.sidebar-gallery .gallery-thumb {
  overflow: hidden;
  position: relative;
  border-radius: 0px;
}

.sidebar-gallery .gallery-thumb img {
  transition: all ease 0.4s;
  width: 100%;
  transform: scale(1);
}

.sidebar-gallery .gallery-thumb:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
}

.sidebar-gallery .gallery-thumb .gal-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  width: var(--icon-size, 35px);
  height: var(--icon-size, 35px);
  margin: calc(var(--icon-size, 35px) / -2) 0 0 calc(var(--icon-size, 35px) / -2);
  text-align: center;
  font-size: 18px;
  color: var(--white-color);
  background-color: transparent;
  border-radius: 50%;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sidebar-gallery .gallery-thumb .gal-btn:hover {
  transform: scale(1);
  background-color: var(--white-color);
  color: var(--theme-color);
}

.sidebar-gallery .gallery-thumb:hover:before {
  opacity: 0.8;
  visibility: visible;
}

.sidebar-gallery .gallery-thumb:hover .gal-btn {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.1s;
}

.sidebar-gallery .gallery-thumb:hover img {
  transform: scale(1.12);
}

.widget--bg1 {
  background-color: var(--blackSand-color);
}

.vs-widget-about__text {
  color: var(--silverDeep-color);
}

.widget-area {
  padding-top: 120px;
  padding-bottom: 93px;
}

.widget-area2 {
  padding-top: 60px;
}

.footer-widget,
.footer-widget .widget {
  padding: 0;
  border: none;
  padding-bottom: 0;
  background-color: transparent;
}
.footer-widget .widget_title {
  border: none;
  font-size: 24px;
  margin: 0 0 30px 0;
  padding: 0 0 20px 0;
  color: var(--white-color);
  position: relative;
  text-align: left;
}
.footer-widget .widget_title::before, .footer-widget .widget_title::after {
  content: "";
  position: absolute;
}
.footer-widget .widget_title::before {
  width: 60px;
  height: 4px;
  background-color: var(--white-color);
  left: 0;
  bottom: 0;
  border-radius: 2px;
}
.footer-widget .widget_title::after {
  width: 8px;
  height: 8px;
  outline: 4px solid var(--sec-color);
  border-radius: 50%;
  background-color: var(--white-color);
  left: 4px;
  bottom: -2px;
  animation: leftToRight infinite 5s linear;
  min-width: 5px;
  z-index: 1;
}
.footer-widget .vs-btn {
  background-color: var(--theme-color);
}
.footer-widget .vs-btn::before, .footer-widget .vs-btn::after {
  background-color: var(--sec-color);
}
.footer-widget.widget_nav_menu {
  margin-bottom: 30px;
  text-align: left;
}
.footer-widget.widget_nav_menu .widget_title {
  margin-bottom: 30px;
}
.footer-widget.widget_nav_menu a {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  max-width: 100%;
  padding: 0 0 0 0px;
  color: var(--white-color);
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  position: relative;
  min-height: auto;
  line-height: 1.625;
}
.footer-widget.widget_nav_menu a::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--white-color);
  bottom: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.footer-widget.widget_nav_menu a:hover {
  background-color: transparent;
  transform: translateX(5px);
}
.footer-widget.widget_nav_menu a:hover::before {
  width: 100%;
  left: 0;
}
.footer-widget.widget_nav_menu li > span {
  width: auto;
  height: auto;
  position: relative;
  background-color: transparent;
  color: var(--body-color);
  line-height: 1;
}
.footer-widget.widget_nav_menu li:last-child a {
  margin-bottom: 0;
}
.footer-widget .sidebar-gallery {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}
.footer-widget .sidebar-gallery a {
  overflow: hidden;
  border-radius: 0px;
  position: relative;
}
.footer-widget .sidebar-gallery a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.footer-widget .sidebar-gallery a img {
  transition: 0.4s ease-in-out;
}
.footer-widget .sidebar-gallery a .gal-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: scale(1.5);
  width: var(--icon-size, 35px);
  height: var(--icon-size, 35px);
  line-height: calc(var(--icon-size, 35px) + 2px);
  margin: calc(var(--icon-size, 35px) / -2) 0 0 calc(var(--icon-size, 35px) / -2);
  text-align: center;
  font-size: 18px;
  color: var(--white-color);
  background-color: transparent;
  border-radius: 50%;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
}
.footer-widget .sidebar-gallery a:hover img {
  transform: scale(1.12);
}
.footer-widget .sidebar-gallery a:hover::before {
  opacity: 0.8;
  visibility: visible;
}
.footer-widget .sidebar-gallery a:hover .gal-btn {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.footer-widget .sidebar-gallery a:hover .gal-btn:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
  background-color: var(--white-color);
  color: var(--theme-color);
}

.vs-widget-about .footer-text {
  max-width: 275px;
  font-family: var(--body-font);
  font-size: 16px;
}

.footer-insta-image {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.social-style1 a {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  color: var(--white-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--theme-color);
  background-color: var(--theme-color);
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.social-style1 a:hover {
  background-color: var(--sec-color);
  border-color: var(--sec-color);
  color: var(--white-color);
}
.social-style1 a:last-child {
  margin-right: 0;
}
@media (max-width: 1199px) {
  .social-style1 a {
    width: 40px;
    height: 40px;
  }
}

.footer-contact .menu a {
  padding-left: 24px;
}
.footer-contact .menu a i {
  font-size: 14px;
  top: 5px;
}

.footer-widget .newsletter-form .form_text {
  font-size: 16px;
  font-family: var(--body-font);
}
.footer-widget .newsletter-form .form-control {
  margin-bottom: 15px;
  background-color: transparent;
  height: 46px;
}
.footer-widget .newsletter-form .form-control:focus {
  color: var(--white-color);
}
.footer-widget .newsletter-form .vs-btn {
  width: unset;
}

/* Large devices */
@media (max-width: 1199px) {
  .footer-widget {
    margin-bottom: 30px;
  }
  .footer-widget .widget_title {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .footer-widget.widget_categories .widget_title, .footer-widget.widget_nav_menu .widget_title {
    margin-bottom: 25px;
  }
  .footer-widget.widget_categories a, .footer-widget.widget_nav_menu a {
    font-size: 14px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .footer-widget .widget_title {
    font-size: 22px;
  }
  .widget-area2 {
    padding-bottom: 20px;
  }
}
.widget__schedule > ul {
  padding-left: 0;
  margin-bottom: 0;
}
.widget__schedule > ul > li {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--white-color);
  gap: 43px;
  margin-bottom: 6px;
}
.widget__schedule > ul > li:last-child {
  margin-bottom: 0;
}
.widget__schedule > ul > li > span {
  color: var(--white-color);
}
.widget__schedule > ul > li > span > span {
  padding-left: 10px;
}

.price_slider_wrapper {
  margin-top: 40px;
}
.price_slider_wrapper .ui-slider {
  height: 10px;
  position: relative;
  width: 100%;
  background-color: var(--white-color);
  border: none;
  margin-top: 10px;
  margin-bottom: 35px;
  cursor: pointer;
  border-radius: 3px;
}
.price_slider_wrapper .ui-slider-handle {
  width: 20px;
  height: 20px;
  outline: none;
  border-radius: 50%;
  text-align: center;
  line-height: 10px;
  padding: 0;
  border: 5px solid var(--theme-color);
  cursor: pointer;
  position: absolute;
  z-index: 2;
  margin-top: 0;
  background-color: var(--white-color);
  transform: translateX(9px);
}
.price_slider_wrapper .ui-slider-handle:last-child {
  transform: translateX(-10px);
}
.price_slider_wrapper .ui-slider-range {
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  display: block;
  background-color: var(--theme-color);
}
.price_slider_wrapper .price_label {
  font-size: 16px;
  font-weight: 500;
  padding-top: 3px;
  color: var(--title-color);
}
.price_slider_wrapper .vs-btn {
  padding: 13px 30px;
  background-color: var(--theme-color);
  color: var(--white-color);
}
.price_slider_wrapper .vs-btn::before, .price_slider_wrapper .vs-btn::after {
  background-color: var(--sec-color);
}
.price_slider_wrapper .vs-btn:hover {
  color: var(--white-color);
}
.price_slider_wrapper .price_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chekbox-area {
  margin-bottom: 5px;
}

/*------------------- 4.2. Header  -------------------*/
.vs-header {
  position: relative;
  z-index: 41;
}

.main-menu,
.menu-style2 {
  padding-left: 15px;
}
.main-menu a,
.menu-style2 a {
  display: block;
  position: relative;
  font-family: var(--title-font);
  font-weight: 700;
  font-size: 17px;
  color: var(--white-color);
}
.main-menu > ul > li,
.menu-style2 > ul > li {
  margin: 0 9px;
}
.main-menu > ul > li > a,
.menu-style2 > ul > li > a {
  padding: 47px 0;
}
.main-menu > ul > li > a .new-label,
.menu-style2 > ul > li > a .new-label {
  position: absolute;
  top: -10px;
  right: -27px;
  font-size: 11px;
  border-radius: 3px;
}
.main-menu > ul > li > a .has-new-lable,
.menu-style2 > ul > li > a .has-new-lable {
  position: relative;
}
.main-menu ul,
.menu-style2 ul {
  margin: 0;
  padding: 0;
}
.main-menu ul li,
.menu-style2 ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
}
.main-menu ul li.menu-item-has-children > a:after,
.menu-style2 ul li.menu-item-has-children > a:after {
  content: "\f0d7";
  position: relative;
  font-family: var(--icon-font);
  margin-left: 5px;
  font-size: 16px;
  display: inline-block;
}
.main-menu ul li:last-child,
.menu-style2 ul li:last-child {
  margin-right: 0;
}
.main-menu ul li:first-child,
.menu-style2 ul li:first-child {
  margin-left: 0;
}
.main-menu ul li:hover > ul.sub-menu,
.main-menu ul li:hover ul.mega-menu,
.menu-style2 ul li:hover > ul.sub-menu,
.menu-style2 ul li:hover ul.mega-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  z-index: 9;
}
.main-menu ul.sub-menu,
.main-menu ul.mega-menu,
.menu-style2 ul.sub-menu,
.menu-style2 ul.mega-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: var(--white-color);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  visibility: hidden;
  min-width: 190px;
  width: max-content;
  padding: 7px;
  left: -14px;
  margin-top: 50px;
  opacity: 0;
  z-index: -1;
  border-bottom: 3px solid var(--theme-color);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09), 0px 3px 0px 0px rgba(231, 13, 60, 0.004);
  transform-origin: top center;
  transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
}
.main-menu ul.sub-menu a,
.main-menu ul.mega-menu a,
.menu-style2 ul.sub-menu a,
.menu-style2 ul.mega-menu a {
  font-size: 16px;
  line-height: 30px;
  color: var(--title-color);
}
.main-menu ul.sub-menu,
.menu-style2 ul.sub-menu {
  padding: 18px 20px;
  left: -27px;
}
.main-menu ul.sub-menu:before,
.menu-style2 ul.sub-menu:before {
  content: "";
  position: absolute;
  left: 34.33px;
  top: 34px;
  width: 1px;
  background-color: var(--theme-color);
  height: calc(100% - 65px);
  display: none;
}
.main-menu ul.sub-menu li,
.menu-style2 ul.sub-menu li {
  display: block;
  margin: 0 0;
  padding: 3px 9px;
}
.main-menu ul.sub-menu li.menu-item-has-children > a:after,
.menu-style2 ul.sub-menu li.menu-item-has-children > a:after {
  content: "\f105";
  float: right;
  top: 3px;
}
.main-menu ul.sub-menu li a,
.menu-style2 ul.sub-menu li a {
  position: relative;
  padding-left: 0px;
}
.main-menu ul.sub-menu li a:before,
.menu-style2 ul.sub-menu li a:before {
  content: "\f105";
  font-family: var(--icon-font);
  font-weight: 400;
  margin: 0 10px 0 0;
}
.main-menu ul.sub-menu li a:hover,
.menu-style2 ul.sub-menu li a:hover {
  color: var(--sec-color);
}
.main-menu ul.sub-menu li ul.sub-menu,
.menu-style2 ul.sub-menu li ul.sub-menu {
  left: 100%;
  right: auto;
  top: 0;
  margin: 0 0;
  margin-left: 20px;
}
.main-menu ul.sub-menu li ul.sub-menu li ul,
.menu-style2 ul.sub-menu li ul.sub-menu li ul {
  left: 100%;
  right: auto;
}
.main-menu .mega-menu-wrap,
.menu-style2 .mega-menu-wrap {
  position: static;
}
.main-menu ul.mega-menu,
.menu-style2 ul.mega-menu {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  max-width: var(--main-container);
  padding: 20px 15px 23px 15px;
  left: 50%;
  transform: translateX(-50%);
}
.main-menu ul.mega-menu .main-menu ul.mega-menu > li > ul > li > a,
.menu-style2 ul.mega-menu .main-menu ul.mega-menu > li > ul > li > a {
  position: relative;
}
.main-menu ul.mega-menu li,
.menu-style2 ul.mega-menu li {
  display: block;
  width: 100%;
  padding: 0 15px;
}
.main-menu ul.mega-menu li li,
.menu-style2 ul.mega-menu li li {
  padding: 4px 0;
}
.main-menu ul.mega-menu li a,
.menu-style2 ul.mega-menu li a {
  display: inline-block;
}
.main-menu ul.mega-menu li a:hover,
.menu-style2 ul.mega-menu li a:hover {
  color: var(--sec-color);
}
.main-menu ul.mega-menu > li > a,
.menu-style2 ul.mega-menu > li > a {
  display: block;
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 700;
  color: var(--title-color);
  border-color: var(--theme-color);
}
.main-menu ul.mega-menu > li > a::after, .main-menu ul.mega-menu > li > a::before,
.menu-style2 ul.mega-menu > li > a::after,
.menu-style2 ul.mega-menu > li > a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15px;
  height: 1px;
  background-color: var(--theme-color);
}
.main-menu ul.mega-menu > li > a::after,
.menu-style2 ul.mega-menu > li > a::after {
  width: calc(100% - 20px);
  left: 20px;
}
.main-menu ul.mega-menu > li > a:hover,
.menu-style2 ul.mega-menu > li > a:hover {
  padding-left: 0;
}
.main-menu ul.mega-menu > li > ul > li > a:before,
.menu-style2 ul.mega-menu > li > ul > li > a:before {
  content: "\f105";
  font-family: var(--icon-font);
  font-weight: 400;
  margin: 0 10px 0 0;
}
.main-menu .mega-menu-wrap.active > a,
.menu-style2 .mega-menu-wrap.active > a {
  color: var(--white-color);
}

.menu-style1 > ul > li > a::before,
.menu-style3 > ul > li > a::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--white-color);
  top: 62%;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.menu-style1 > ul > li > a:hover::before,
.menu-style3 > ul > li > a:hover::before {
  width: 100%;
  left: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.menu-style1 > ul > li.active > a::before,
.menu-style3 > ul > li.active > a::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--white-color);
  top: 62%;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.menu-style1 ul.sub-menu li.active > a,
.menu-style3 ul.sub-menu li.active > a {
  color: var(--sec-color);
}
.menu-style1 ul.mega-menu li li.active > a,
.menu-style3 ul.mega-menu li li.active > a {
  color: var(--sec-color);
}

.menu-style2 a {
  color: var(--title-color);
}
.menu-style2 a:hover {
  color: var(--sec-color);
}
.menu-style2 ul li.active > a {
  color: var(--sec-color);
}
.menu-style2 .mega-menu-wrap.active > a {
  color: var(--title-color);
}

.menu-style1 .mega-menu-wrap.active > a::before,
.menu-style3 .mega-menu-wrap.active > a::before {
  display: none;
}

.will-sticky .sticky-active {
  position: fixed;
  top: -100%;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  transition: all ease 0.8s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
}
.will-sticky .sticky-active.active {
  top: 0;
}
.will-sticky .sticky-active.active .header-logo::before {
  height: 100%;
  display: none;
}
.will-sticky .sticky-active.active.style3 {
  background-color: var(--theme-color);
}
.will-sticky .sticky-active.active .main-menu a {
  color: var(--title-color);
}
.will-sticky .sticky-active.active .main-menu a:hover {
  color: var(--theme-color);
}
.will-sticky .sticky-active.active .menu-style3 a {
  color: var(--white-color);
}
.will-sticky .sticky-active.active .menu-style3 a:hover {
  color: var(--title-color);
}
.will-sticky .sticky-active.active .menu-style3 ul.mega-menu li a,
.will-sticky .sticky-active.active .menu-style3 .menu-style2 ul.mega-menu li a {
  color: var(--title-color);
}
.will-sticky .sticky-active.active .menu-style3 ul.sub-menu li a,
.will-sticky .sticky-active.active .menu-style3 .main-menu ul.sub-menu a,
.will-sticky .sticky-active.active .menu-style3 .main-menu ul.mega-menu a {
  color: var(--title-color);
}
.will-sticky .sticky-active.active .menu-style3 ul.sub-menu li a:hover,
.will-sticky .sticky-active.active .menu-style3 .main-menu ul.sub-menu a:hover,
.will-sticky .sticky-active.active .menu-style3 .main-menu ul.mega-menu a:hover {
  color: var(--theme-color);
}
.will-sticky .sticky-active.active .vs-icon {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.will-sticky .sticky-active.active .vs-icon:hover {
  background-color: var(--sec-color);
}
.will-sticky .sticky-active.active .vs-btn,
.will-sticky .sticky-active.active .vs-menu-toggle {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.will-sticky .sticky-active.active .vs-btn:hover,
.will-sticky .sticky-active.active .vs-menu-toggle:hover {
  background-color: var(--sec-color);
}
.will-sticky.header-bottom3 .sticky-active.active .vs-icon {
  background-color: var(--white-color);
  color: var(--theme-color);
}
.will-sticky.header-bottom3 .sticky-active.active .vs-icon:hover {
  color: var(--white-color);
}
.will-sticky.header-bottom3 .sticky-active.active .vs-btn.style2 {
  background-color: var(--white-color);
  color: var(--theme-color);
}
.will-sticky.header-bottom3 .sticky-active.active .vs-btn.style2::before, .will-sticky.header-bottom3 .sticky-active.active .vs-btn.style2::after {
  background-color: var(--sec-color);
}
.will-sticky.header-bottom3 .sticky-active.active .vs-btn.style2:hover {
  color: var(--white-color);
}

.header-text {
  margin: 0;
}
.header-text i {
  margin-right: 10px;
}

.header-infos {
  display: flex;
  align-items: center;
}

.header-info {
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin-right: 20px;
  position: relative;
}
.header-info::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background-color: var(--smoke-color);
}
.header-info:last-child {
  padding-right: 0;
  margin-right: 0;
  border: 0;
}
.header-info:last-child::after {
  display: none;
}
.header-info:nth-child(2) {
  margin-left: 0;
  padding-left: 0;
  border-left: none;
}
.header-info_icon {
  color: var(--smoke-color);
  font-size: 16px;
  margin-right: 15px;
}
.header-info_label {
  font-size: 14px;
  color: var(--white-color);
  display: block;
  display: inline-block;
  position: relative;
}
.header-info_label::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--white-color);
  bottom: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.header-info_label:hover {
  color: var(--white-color);
}
.header-info_label:hover::before {
  width: 100%;
  left: 0;
}
.header-info a.header-info_label:hover {
  color: var(--white-color);
}
.header-info_link {
  color: var(--title-color);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.header-info_link a {
  color: inherit;
}
.header-info_link a:hover {
  color: var(--theme-color);
}
.header-info.style2 {
  padding: 0;
  border: none;
  margin: 0;
}
.header-info.style2 .header-info_icon {
  width: 60px;
  height: 60px;
  line-height: 56px;
  background-color: var(--theme-color);
  text-align: center;
  border-radius: 50%;
  margin-right: 15px;
}
.header-info.style2 .header-info_label {
  color: var(--title-color);
  text-transform: capitalize;
  font-weight: 500;
}
.header-info.style2 .header-info_link {
  color: var(--theme-color);
  font-weight: 400;
  font-size: 24px;
}
.header-info.style2 .header-info_link a:hover {
  color: var(--title-color);
}
.header-info.style3 {
  background-color: var(--theme-color);
  min-height: 95px;
  padding: 15px 70px 15px 60px;
  margin-bottom: -70px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1399px) {
  .header-info.style3 {
    padding: 15px 30px 15px 30px;
  }
}
.header-info.style3.bg {
  border-right: 1px solid var(--title-color);
  border-left: 1px solid var(--title-color);
}
.header-info.style3 .header-info_title {
  margin-bottom: 0;
  color: var(--white-color);
  font-size: 16px;
  line-height: 26px;
}
.header-info.style3 .header-info_label {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  font-family: var(--title-font);
}
@media (max-width: 1199px) {
  .header-info.style3 .header-info_label {
    font-size: 20px;
    line-height: 30px;
  }
}
.header-info.style3 .header-info_icon {
  font-size: 48px;
}
.header-info.style3 .header-info_text {
  color: var(--white-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 0;
  font-family: var(--title-font);
}
.header-info.style3:hover {
  background-color: var(--title-color);
}

.header-search {
  display: flex;
  width: 340px;
  max-width: 100%;
}
.header-search input {
  flex: 1;
  border: none;
  padding: 0 20px 0 20px;
  height: 35px;
  width: 100%;
  border-radius: 4px;
  transition: all ease 0.4s;
}
.header-search button {
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 13px;
  width: 35px;
  height: 35px;
  border-radius: 4px;
}
.header-search button:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.header-search input:focus {
  padding: 0 20px 0 20px;
  background-color: var(--smoke-color);
}

.header-links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.header-links li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: var(--title-color);
  font-family: var(--title-font);
  padding: 0 30px 0 0;
  margin: 0 26px 0 0;
  border-right: 1px solid #cad4f1;
  line-height: 23px;
}
.header-links li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
.header-links i {
  color: var(--theme-color);
  margin: 0 10px 0 0;
}
.header-links a {
  color: inherit;
}
.header-links a:hover {
  color: var(--theme-color);
}
.header-links.style-white i,
.header-links.style-white li {
  color: var(--white-color);
  border-color: #558eff;
}

.header-social {
  font-size: 14px;
}
.header-social .social-title {
  color: var(--title-color);
  display: inline-block;
}
.header-social a {
  display: inline-block;
  color: var(--title-color);
  margin-left: 16px;
}
.header-social a:hover {
  color: var(--theme-color);
}
.header-social.style-white .social-title,
.header-social.style-white a {
  color: var(--white-color);
}
.header-social.style-white a:hover {
  color: var(--theme-color);
}

.logo-style1 {
  background: linear-gradient(278.46deg, #00bcfa 0.91%, #0e59f2 71.89%, #0e59f2 100%);
  padding: 30px 35px 30px 35px;
  border-radius: 5px 0px 0px 5px;
  line-height: 0;
  margin: -1px 0;
}

.header-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 80px;
}
@media (max-width: 1199px) {
  .header-btns {
    padding-left: 0;
  }
}

.header-box {
  border: 10px solid var(--theme-color);
  background-color: var(--white-color);
  padding: 20px;
}
.header-box__icon {
  border-radius: 5px;
  margin: 0 15px 0 0;
  font-size: 18px;
  width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
}
.header-box__title {
  display: inline-block;
  color: var(--title-color);
  font-weight: 600;
  font-family: var(--title-font);
}
.header-box__number {
  display: block;
  width: fit-content;
  font-family: var(--title-font);
  font-size: 22px;
  font-weight: 700;
  color: var(--theme-color);
  margin: 15px 0 0 0;
}

.header-dropdown > .dropdown-toggle {
  color: var(--title-color);
}
.header-dropdown > .dropdown-toggle i {
  margin: 0 10px 0 0;
}
.header-dropdown > .dropdown-toggle:after {
  content: "\f107";
  font-family: var(--icon-font);
  border: none;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-left: 5px;
}
.header-dropdown > .dropdown-toggle:hover {
  color: var(--theme-color);
}
.header-dropdown ul.dropdown-menu {
  padding: 10px 15px;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.07);
  margin: 0;
  margin-left: -15px !important;
  margin-top: 15px !important;
  width: max-content;
  min-width: auto;
  position: relative;
  z-index: 1;
  border: none;
}
.header-dropdown ul.dropdown-menu li {
  margin-bottom: 10px;
}
.header-dropdown ul.dropdown-menu li:last-child {
  margin-bottom: 0;
}
.header-dropdown ul.dropdown-menu a {
  display: block;
  margin: 0 0;
  width: max-content;
  padding: 2px 10px;
  color: var(--title-color);
  font-weight: 400;
  font-size: 14px;
}
.header-dropdown ul.dropdown-menu a:hover {
  color: var(--theme-color);
}
.header-dropdown ul.dropdown-menu a:first-child {
  margin-top: 0;
  padding-top: 0;
}
.header-dropdown ul.dropdown-menu a:last-of-type, .header-dropdown ul.dropdown-menu a:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.header-dropdown ul.dropdown-menu:before {
  content: "";
  position: absolute;
  left: 47px;
  top: -7px;
  width: 14px;
  height: 14px;
  background-color: var(--white-color);
  z-index: -1;
  transform: rotate(45deg);
  border-top: 1px solid #ededed;
  border-left: 1px solid #ededed;
}
.header-dropdown.style-white > .dropdown-toggle {
  color: var(--white-color);
}
.header-dropdown.style-white > .dropdown-toggle:hover {
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.7);
}

.login-tab {
  display: flex;
  border-radius: 9999px;
  position: relative;
  z-index: 1;
}
.login-tab:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  border: 2px solid var(--theme-color);
  z-index: -1;
}
.login-tab a {
  display: inline-block;
  color: var(--theme-color);
  background-color: transparent;
  font-family: var(--title-font);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  padding: 18.5px 37px;
  line-height: 1;
  border-radius: inherit;
}
.login-tab a:hover, .login-tab a.active {
  color: var(--white-color);
}
.login-tab .indicator {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  background-color: var(--theme-color);
  z-index: -1;
  transition: all ease 0.4s;
  border-radius: inherit;
}

.header-layout1 .header-top,
.header-layout3 .header-top {
  background-color: var(--title-color);
  padding: 12px 0;
}

.header-layout1 .header-text {
  color: var(--white-color);
}

.header-layout2 {
  position: relative;
  margin-bottom: -55px;
}
.header-layout2 .header-shape {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 55px);
  z-index: -1;
  background-color: #f6f7fa;
}
.header-layout2 .header-top {
  padding: 27px 0;
}
.header-layout2 .menu-area {
  background-color: var(--white-color);
  padding: 0 30px 0 0;
  border-radius: 5px;
  box-shadow: 0px 17px 29px rgba(32, 56, 105, 0.18);
}
.header-layout2 .vs-menu-toggle {
  border-radius: 5px;
}
.header-layout2 .will-sticky .sticky-active {
  box-shadow: none;
  background-color: transparent;
}

@media (max-width: 991px) {
  .header-logo3 {
    padding: 15px 0;
  }
}

.header-layout3 {
  background-color: transparent;
  margin-bottom: -51px;
}
@media (max-width: 991px) {
  .header-layout3 {
    padding-top: 30px;
  }
}
.header-layout3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 70%;
  background-color: var(--title-color);
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
}
.header-layout3 .header-top {
  background-color: var(--title-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .header-search {
    width: 200px;
  }
  .main-menu > ul > li {
    margin: 0 14px;
  }
  .header-layout2 {
    margin: 0;
  }
  .header-layout2 .header-shape {
    height: 100%;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .header-links li {
    padding-right: 15px;
    margin-right: 10px;
  }
  .header-logo {
    padding: 15px 0;
  }
  .logo-style1 {
    padding: 20px 20px 20px 20px;
  }
  .header-layout1 .vs-menu-toggle {
    margin: 10px 0;
  }
  .header-layout2 .menu-area {
    padding: 0 20px 0 0;
  }
}
.header-logo {
  padding: 24px 30px 24px 0;
  min-height: 110px;
  position: relative;
  z-index: 1;
}
.header-logo::before {
  position: absolute;
  content: "";
  width: 10000%;
  height: 120px;
  background: var(--white-color);
  z-index: -1;
  top: -5px;
  right: -15px;
}
@media (max-width: 991px) {
  .header-logo::before {
    display: none;
  }
}
@media (max-width: 991px) {
  .header-logo {
    min-height: auto;
    padding: 12px 30px 12px 0;
  }
}
@media (max-width: 991px) {
  .header-logo {
    min-height: auto;
    padding: 12px 0px 12px 0;
  }
}

.header-bottom {
  background-color: var(--theme-color);
  position: relative;
  z-index: 1;
  min-height: 115px;
}
@media (max-width: 991px) {
  .header-bottom {
    min-height: auto;
  }
}

.element1 {
  position: absolute;
  min-width: 524px;
  height: 100%;
  background: var(--white-color);
  z-index: -1;
}
@media (max-width: 1919px) {
  .element1 {
    min-width: 300px;
  }
}
@media (max-width: 1399px) {
  .element1 {
    min-width: 190px;
  }
}
@media (max-width: 991px) {
  .element1 {
    min-width: 40%;
  }
}
@media (max-width: 767px) {
  .element1 {
    min-width: 55%;
  }
}

.menu-top {
  position: relative;
  z-index: 9;
}

.menu-top3 {
  background-color: var(--theme-color);
  margin: 0 -120px;
  padding: 0 120px;
}
@media (max-width: 1499px) {
  .menu-top3 {
    margin: 0 0px;
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .menu-top3 {
    padding: 10px 5px;
  }
}

/*------------------- 4.3. Footer  -------------------*/
.widget-area {
  padding-top: 48px;
  padding-bottom: 10px;
}

.copyright-wrap {
  text-align: center;
  padding: 29px 0;
  background-color: var(--title-color);
}
.copyright-wrap.style2 {
  background-color: var(--theme-color);
}
.copyright-wrap.style2 .copyright-text > a:hover {
  color: var(--title-color);
}
.copyright-wrap.style3 {
  background-color: var(--theme-color);
}

.copyright-text {
  margin: 0;
  color: var(--white-color);
}
.copyright-text > a {
  font-weight: 500;
  color: var(--white-color);
}
.copyright-text > a:hover {
  color: var(--theme-color);
  text-decoration: underline;
}

.footer-text {
  color: var(--white-color);
  margin-bottom: 0;
  max-width: 87%;
}
@media (max-width: 767px) {
  .footer-text {
    max-width: 100%;
  }
}

.footer-communicate {
  max-width: 92%;
}
.footer-communicate > ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.footer-communicate > ul > li {
  margin-bottom: 30px;
}
.footer-communicate > ul > li:last-child {
  margin-bottom: 0;
}
.footer-communicate > ul > li > a:hover {
  color: var(--sec-color);
}
.footer-communicate__text {
  padding: 0px 0px 0 45px;
  position: relative;
  display: flex;
  color: var(--white-color);
  min-height: 30px;
  align-items: self-start;
  text-align: left;
}
.footer-communicate__text > span {
  display: inline-block;
  padding-top: 3px;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.footer-communicate__text > span.link::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--white-color);
  bottom: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.footer-communicate__text > span.link:hover::before {
  width: 100%;
  left: 0;
}
.footer-communicate__text > i {
  position: absolute;
  left: 0;
  min-width: 30px;
  min-height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  font-size: 14px;
}
.footer-communicate__text:hover > span {
  transition: all 0.3s ease-in-out;
  color: var(--white-color);
}

.footer--layout1 {
  overflow: hidden;
}
.footer--layout1 .copyright-text > a:hover {
  color: var(--theme-color);
  text-decoration: none;
}
.footer--layout1 .overlay {
  background: linear-gradient(90deg, #01133c 57.39%, rgba(1, 19, 60, 0) 122.86%);
  opacity: 0.95;
  z-index: -1;
}
.footer--layout3 {
  background-color: var(--title-color);
}
.footer--layout3 .copyright-text > a:hover {
  color: var(--sec-color);
  text-decoration: none;
}

.footer-top {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 30px;
}

/* Medium devices */
@media (max-width: 991px) {
  .footer--layout1 {
    text-align: center;
  }
  .footer--layout1 .footer-text {
    margin-left: auto;
    margin-right: auto;
  }
}
/*------------------- 4.4. Breadcrumb  -------------------*/
.breadcumb-menu {
  max-width: 100%;
  margin: -1px;
  padding: 0;
  list-style-type: none;
  display: inline-flex;
  background-color: var(--white-color);
  min-width: 182px;
  padding: 15px 30px;
  align-items: center;
}
.breadcumb-menu li {
  display: inline-block;
  list-style: none;
  position: relative;
}
.breadcumb-menu li:after {
  content: "\f101";
  position: relative;
  margin: 0 10px;
  font-family: var(--icon-font);
  font-size: 27px;
  font-weight: 400;
  padding-top: 5px;
}
@media (max-width: 767px) {
  .breadcumb-menu li:after {
    font-size: 20px;
    padding-top: 4px;
  }
}
.breadcumb-menu li:last-child:after {
  display: none;
}
.breadcumb-menu li,
.breadcumb-menu a,
.breadcumb-menu span {
  white-space: normal;
  color: inherit;
  word-break: break-word;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 20px;
  font-family: var(--title-font);
  color: var(--title-color);
  display: inline-flex;
  align-items: center;
}
@media (max-width: 767px) {
  .breadcumb-menu li,
  .breadcumb-menu a,
  .breadcumb-menu span {
    font-size: 14px;
  }
}
.breadcumb-menu > li {
  color: var(--theme-color);
}
.breadcumb-menu > li::after {
  color: var(--title-color);
}
.breadcumb-menu > li > a {
  color: var(--title-color);
}
.breadcumb-menu > li > a:hover {
  color: var(--theme-color);
}

.breadcumb-title {
  color: var(--white-color);
  margin-bottom: 128px;
  font-size: 64px;
}
@media (max-width: 767px) {
  .breadcumb-title {
    margin-bottom: 40px;
  }
}

.breadcumb-text {
  color: var(--silver-color);
  margin-bottom: 0;
  max-width: 50%;
  font-size: 22px;
}
@media (max-width: 1199px) {
  .breadcumb-text {
    max-width: 73%;
  }
}
@media (max-width: 991px) {
  .breadcumb-text {
    max-width: 90%;
  }
}
@media (max-width: 767px) {
  .breadcumb-text {
    max-width: 100%;
    font-size: 17px;
  }
}

.breadcumb-wrapper {
  padding-top: 170px;
  background-color: var(--title-color);
  position: relative;
  z-index: 1;
  /* Medium devices */
}
.breadcumb-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  z-index: -1;
  background: linear-gradient(90deg, #01133c 31.22%, rgba(1, 19, 60, 0.33) 58.62%);
}
@media (max-width: 991px) {
  .breadcumb-wrapper {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  .breadcumb-wrapper {
    padding-top: 50px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .breadcumb-title {
    max-width: 100%;
    font-size: 40px;
  }
  .breadcumb-menu {
    margin-top: 15px;
  }
}
/*------------------- 4.5. Pagination  -------------------*/
.vs-pagination {
  margin-bottom: 30px;
}
.vs-pagination ul {
  margin: 0;
  padding: 0;
}
.vs-pagination li {
  display: inline-block;
  margin: 0 3px;
  list-style-type: none;
}
.vs-pagination li:last-child {
  margin-right: 0;
}
.vs-pagination li:first-child {
  margin-left: 0;
}
.vs-pagination span,
.vs-pagination a {
  display: inline-block;
  font-family: var(--title-font);
  color: var(--title-color);
  text-align: center;
  position: relative;
  border: none;
  width: 56px;
  height: 56px;
  line-height: 56px;
  font-weight: 400;
  font-size: 20px;
  z-index: 1;
  background-color: var(--white-color);
  border-radius: 5px;
}
.vs-pagination span.active, .vs-pagination span:hover,
.vs-pagination a.active,
.vs-pagination a:hover {
  color: var(--white-color);
  background-color: var(--sec-color);
  box-shadow: none;
}

.post-pagination {
  border-bottom: 1px solid var(--border-color);
  padding: 40px 0;
}

.post-pagi-box {
  display: flex;
  align-items: center;
}
.post-pagi-box > a {
  color: var(--title-color);
  font-weight: 700;
  font-size: 20px;
  font-family: var(--title-font);
}
.post-pagi-box > a:hover {
  color: var(--theme-color);
}
.post-pagi-box img {
  width: 80px;
  margin-right: 25px;
}
.post-pagi-box.next {
  flex-direction: row-reverse;
}
.post-pagi-box.next img {
  margin-right: 0;
  margin-left: 25px;
}

.pagi-icon {
  color: var(--theme-color);
  font-size: 40px;
}
.pagi-icon:hover {
  color: var(--sec-color);
}

/* Small devices */
@media (max-width: 767px) {
  .vs-pagination span,
  .vs-pagination a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    padding: 0 10px;
  }
  .post-pagi-box > a {
    font-size: 12px;
  }
  .post-pagi-box img {
    width: 40px;
    margin-right: 15px;
  }
  .post-pagi-box.next img {
    margin-right: 0;
    margin-left: 15px;
  }
}
.vs-pagination {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.vs-pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 0;
  background-color: var(--smoke-color);
  padding: 10px;
}

.vs-pagination span,
.vs-pagination a {
  position: relative;
  display: inline-block;
  text-align: center;
  border: none;
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 700;
  width: 30px;
  height: 30px;
  line-height: 30px;
  z-index: 1;
  text-transform: capitalize;
  background-color: var(--white-color);
  border-radius: 0;
  color: var(--title-color);
}

.vs-pagination span:before,
.vs-pagination a:before {
  content: "";
  position: absolute;
  left: 3px;
  right: 3px;
  bottom: 3px;
  top: 3px;
  background-color: var(--vs-secondary-color);
  border-radius: inherit;
  transition: all ease 0.4s;
  transform: scale(0.6);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.vs-pagination span.active,
.vs-pagination span:hover,
.vs-pagination a.active,
.vs-pagination a:hover {
  color: var(--white-color);
  border-color: rgba(0, 0, 0, 0);
}

.vs-pagination span.active:before,
.vs-pagination span:hover:before,
.vs-pagination a.active:before,
.vs-pagination a:hover:before {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.vs-pagination li {
  display: inline-block;
  margin: 0 2.5px;
  list-style-type: none;
}

.vs-pagination .pagi-btn {
  width: auto;
  height: auto;
  line-height: 1;
  padding: 17px 31px;
  border-radius: 0px;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-transform: capitalize;
  display: inline-flex;
  gap: 5px;
}
.vs-pagination .pagi-btn:hover {
  background-color: var(--sec-color);
}

.vs-pagination .pagi-btn:before {
  background-color: var(--sec-color);
}

@media (max-width: 767px) {
  .vs-pagination {
    gap: 5px;
  }
  .vs-pagination ul {
    padding: 3px 3px;
  }
  .vs-pagination span,
  .vs-pagination a {
    font-size: 14px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .vs-pagination li {
    margin: 0 0;
  }
  .vs-pagination .pagi-btn {
    padding: 11px 15px;
    font-size: 12px;
  }
}
/*------------------- 4.6. Blog  -------------------*/
blockquote {
  display: block;
  position: relative;
  overflow: hidden;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
  background-color: var(--quote-bg, #eff1f5);
  border-left: 4px solid var(--theme-color);
  padding: 43px 50px 42px 35px;
  margin: 35px 0;
}
blockquote p {
  font-family: inherit;
  color: inherit;
  z-index: 3;
  width: 100%;
  margin-bottom: 0 !important;
  line-height: 1.5;
  position: relative;
}
blockquote:before {
  content: "\f10e";
  font-family: var(--icon-font);
  position: absolute;
  right: 40px;
  bottom: 30px;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1;
  color: var(--theme-color);
  opacity: 0.3;
}
blockquote p {
  margin-bottom: 0;
}
blockquote p a {
  color: inherit;
}
blockquote cite {
  color: var(--theme-color);
  font-family: var(--title-font);
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  padding-left: 45px;
  line-height: 1;
  margin-top: 20px;
  font-style: normal;
}
blockquote cite:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 30px;
  height: 2px;
  border-top: 2px solid var(--theme-color);
}

blockquote.vs-quote {
  text-align: left;
  padding: 50px 60px;
  border-left: 4px solid var(--theme-color);
}
blockquote.vs-quote:before {
  position: absolute;
  top: 30px;
  right: 40px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: var(--theme-color);
  font-size: 110px;
  display: inline-block;
  opacity: 0.2;
}
blockquote.vs-quote cite {
  padding: 0;
  margin-top: 15px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
blockquote.vs-quote cite::before {
  position: static;
}
blockquote.vs-quote .quote-author {
  display: block;
  font-size: 14px;
  color: #74787c;
  font-family: var(--title-font);
  display: block;
  font-weight: 400;
}

.blog-meta span,
.blog-meta a {
  display: inline-block;
  margin-right: 16px;
  font-size: 16px;
  color: #8b919c;
}
.blog-meta span:last-child,
.blog-meta a:last-child {
  margin-right: 0;
}
.blog-meta span i,
.blog-meta a i {
  margin-right: 10px;
  color: var(--theme-color);
}
.blog-meta a:hover {
  color: var(--theme-color);
}

.blog-category {
  margin-bottom: -10px;
}
.blog-category a {
  display: inline-block;
  color: var(--white-color);
  padding: 4.5px 24.5px;
  margin-right: 5px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  background-color: var(--theme-color);
}
.blog-category a:hover {
  background-color: var(--white-color);
  color: var(--body-color);
  border-color: var(--theme-color);
}

.blog-title a {
  color: inherit;
  display: inline-block;
}
.blog-title a:hover {
  color: var(--theme-color);
}

.blog--layout2 {
  padding-top: 235px;
  margin-top: -155px;
}

.vs-blog {
  margin-bottom: 30px;
}
.vs-blog .blog-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.vs-blog .blog-img .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.vs-blog .blog-img .play-btn > i {
  background-color: var(--theme-color);
}
.vs-blog .blog-img .play-btn::before, .vs-blog .blog-img .play-btn::after {
  background-color: var(--theme-color);
}
.vs-blog .blog-img .slick-prev.slick-arrow {
  left: 5%;
}
.vs-blog .blog-img .slick-next.slick-arrow {
  left: auto;
  right: 5%;
}
.vs-blog .blog-img__img {
  transform: scale(1) rotate(0deg);
  transition: all 0.6s ease-in-out;
}
.vs-blog .blog-img::before, .vs-blog .blog-img::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0%;
  background-color: var(--title-color);
  right: 0;
  left: 0;
  transition: all 0.6s ease-in-out;
  z-index: 1;
}
.vs-blog .blog-img::after {
  top: 0;
  opacity: 0.35;
}
.vs-blog .blog-img::before {
  bottom: 0;
  opacity: 0.35;
}
.vs-blog__meta {
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 20px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: start;
  z-index: 2;
  overflow: hidden;
}
@media (max-width: 991px) {
  .vs-blog__meta {
    max-width: 300px;
  }
}
.vs-blog__meta > ul {
  margin-bottom: 0;
  padding-left: 0;
  display: inline-flex;
  list-style: none;
  background-color: var(--white-color);
  min-height: 40px;
  align-items: center;
  padding: 0 20px;
  position: relative;
}
@media (max-width: 1399px) {
  .vs-blog__meta > ul {
    padding: 0 10px;
  }
}
.vs-blog__meta > ul::before {
  position: absolute;
  content: "";
  width: 0px;
  min-height: 4px;
  background-color: var(--theme-color);
  left: 0px;
  transition: all 0.3s ease-in-out;
  bottom: 0;
}
@media (max-width: 991px) {
  .vs-blog__meta > ul::before {
    display: none;
  }
}
.vs-blog__meta > ul > li {
  padding-right: 20px;
  margin-right: 20px;
  position: relative;
}
@media (max-width: 1399px) {
  .vs-blog__meta > ul > li {
    padding-right: 10px;
    margin-right: 10px;
  }
}
.vs-blog__meta > ul > li::after {
  content: "";
  width: 1px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--border-color);
  right: 0;
}
.vs-blog__meta > ul > li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.vs-blog__meta > ul > li:last-child::after {
  display: none;
}
.vs-blog .blog-date {
  min-height: 82px;
  min-width: 79px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--theme-color);
  font-size: 30px;
  font-weight: 700;
  color: var(--white-color);
  line-height: 1.2;
  font-family: var(--title-font);
  padding: 10px 11px;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.vs-blog .blog-date::before {
  position: absolute;
  content: "";
  width: 79px;
  min-height: 4px;
  background-color: var(--sec-color);
  left: 0px;
  transition: all 0.3s ease-in-out;
  bottom: 0;
}
@media (max-width: 991px) {
  .vs-blog .blog-date::before {
    display: none;
  }
}
.vs-blog .blog-date > span {
  font-size: 14px;
  line-height: 1.71428571;
}
.vs-blog .blog-title {
  font-size: 24px;
  line-height: 1.41666667;
  margin-bottom: 22px;
}
.vs-blog .blog-title > a:hover {
  color: var(--theme-color);
}
.vs-blog .blog-content {
  background-color: var(--smoke-color);
  padding: 30px;
}
@media (max-width: 1199px) {
  .vs-blog .blog-content {
    padding: 15px;
  }
}
.vs-blog .blog-meta {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: var(--title-color);
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}
.vs-blog .blog-meta > i {
  font-size: 18px;
  color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}
.vs-blog .blog-meta:hover {
  color: var(--sec-color);
  transition: all 0.3s ease-in-out;
}
.vs-blog .blog-meta:hover > i {
  color: var(--sec-color);
  transition: all 0.3s ease-in-out;
}
.vs-blog .vs-btn {
  background-color: var(--theme-color);
  display: block;
  clip-path: polygon(140% 0, 100% 70%, 95% 100%, 0 100%, 0 0);
}
.vs-blog .vs-btn::after, .vs-blog .vs-btn::before {
  background-color: var(--sec-color);
}
.vs-blog:hover .blog-img__img {
  transform: scale(1.3) rotate(2deg) translateX(35px) translateY(35px);
  transition: all 0.6s ease-in-out;
}
.vs-blog:hover .blog-img::before, .vs-blog:hover .blog-img::after {
  height: 100%;
}
.vs-blog:hover .blog-img .play-btn > i {
  background-color: var(--theme-color);
}
.vs-blog:hover .blog-img .play-btn::before, .vs-blog:hover .blog-img .play-btn::after {
  background-color: var(--theme-color);
}
.vs-blog:hover .blog-img .play-btn:hover > i {
  background-color: var(--sec-color);
}
.vs-blog:hover .blog-img .play-btn:hover::before, .vs-blog:hover .blog-img .play-btn:hover::after {
  background-color: var(--sec-color);
}
.vs-blog:hover .vs-blog__meta::before {
  width: 86%;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.vs-blog:hover .vs-blog__meta > ul::before {
  width: 100%;
}
.vs-blog:hover .blog-date {
  background-color: var(--theme-color);
}
.vs-blog.style2 .blog-title {
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 30px;
}
.vs-blog.style2 .blog-meta {
  font-size: 16px;
}
.vs-blog.style2 .vs-blog__meta {
  bottom: 40px;
  left: 40px;
}
.vs-blog.style2 .vs-blog__meta::before {
  display: none;
}
.vs-blog.style2 .vs-blog__meta > ul {
  padding: 0 40px;
  position: relative;
  min-height: 50px;
}
.vs-blog.style2 .vs-blog__meta > ul::before {
  position: absolute;
  content: "";
  width: 0%;
  min-height: 8px;
  background-color: var(--theme-color);
  left: 0px;
  transition: all 1s ease-in-out;
  bottom: 0;
}
.vs-blog.style2 .vs-blog__meta > ul > li {
  padding-right: 44px;
  margin-right: 44px;
}
.vs-blog.style2 .vs-blog__meta > ul > li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.vs-blog.style2 .vs-btn {
  display: inline-block;
  clip-path: polygon(140% 0, 100% 70%, 90% 100%, 0 100%, 0 0);
}
.vs-blog.style2 .blog-date {
  font-size: 40px;
  position: relative;
  min-height: 122px;
  min-width: 122px;
  padding: 19px 11px;
  transition: all 1s ease-in-out;
}
.vs-blog.style2 .blog-date::before {
  position: absolute;
  content: "";
  width: 100%;
  min-height: 8px;
  background-color: var(--theme-color);
  left: 0px;
  transition: all 0.3s ease-in-out;
  bottom: 0;
}
.vs-blog.style2 .blog-date > span {
  font-size: 20px;
}
.vs-blog.style2:hover .vs-blog__meta > ul::before {
  width: 100%;
}
.vs-blog.style3 {
  background-color: var(--white-color);
}
.vs-blog.style3 .blog-meta > img {
  border-radius: 999px;
}
.vs-blog.style3 .blog-img {
  max-height: 280px;
}
.vs-blog.style3 .blog-img__img {
  max-height: 280px;
  object-fit: cover;
  width: 100%;
}
.vs-blog.style3 .blog-content {
  background-color: var(--white-color);
}
.vs-blog.style3 .vs-blog__meta {
  left: auto;
  right: 30px;
  bottom: 30px;
}
.vs-blog.style3 .blog-date {
  padding: 0;
  padding-top: 8px;
  background-color: var(--theme-color);
  justify-content: space-between;
}
.vs-blog.style3 .blog-date > span {
  font-size: 14px;
  line-height: 1.71428571;
  min-height: 26px;
  display: block;
  width: 100%;
  background: var(--title-color);
}
.vs-blog.style3 .blog-date::before {
  display: none;
}
.vs-blog.style3:hover .blog-img__img {
  transform: scale(1.2) rotate(1.5deg) translateX(5px) translateY(5px);
}
.vs-blog.style4 .blog-content {
  background-color: var(--white-color);
}

.blog-inner-title {
  font-size: 36px;
  margin-top: -0.2em;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.blog-single {
  position: relative;
  margin-bottom: 30px;
  background: var(--white-color);
  padding: 0.1px 0 0.1px 0;
  border: 1px solid var(--border-color);
}
.blog-single .blog-inner-img {
  margin-bottom: 25px;
}
.blog-single .blog-meta {
  margin: -0.2em 0 20px 0;
}
.blog-single .blog-title {
  font-size: 30px;
  margin-bottom: 17px;
}
.blog-single .link-btn {
  width: fit-content;
  display: block;
  margin-top: 26px;
}
.blog-single .blog-date {
  color: var(--body-color);
}
.blog-single .blog-date i {
  color: var(--theme-color);
  margin: 0 10px 0 0;
}
.blog-single .share-links-title {
  font-size: 20px;
  font-weight: 700;
  color: var(--title-color);
  font-family: var(--title-font);
  display: inline-block;
}
.blog-single .share-links {
  margin: 50px 0 0 0;
  padding: 40px 0 20px;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
.blog-single .share-links .tagcloud {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 0;
  margin-bottom: 0;
  gap: 10px;
  margin-bottom: 20px;
}
.blog-single .share-links .tagcloud .share-links-title {
  margin-right: 10px;
}
.blog-single .share-links .tagcloud a {
  border-radius: 0;
  color: var(--theme-color);
  background: var(--smoke-color);
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  padding: 7px 18px;
  min-height: 30px;
  border: 0;
  font-weight: 600;
}
.blog-single .share-links .tagcloud a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.blog-single .social-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}
.blog-single .social-links .share-links-title {
  margin-right: 10px;
}
.blog-single .social-links li {
  display: inline-block;
}
.blog-single .social-links a {
  min-height: 40px;
  min-width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
}
.blog-single .social-links a:hover {
  color: var(--white-color);
  background-color: var(--sec-color);
}
.blog-single .blog-content {
  margin: var(--blog-space-y, 40px) var(--blog-space-x, 40px) var(--blog-space-y, 40px) var(--blog-space-x, 40px);
  padding: 0;
  position: relative;
  background-color: transparent;
}
.blog-single .blog-audio {
  line-height: 1;
}
.blog-single .blog-audio,
.blog-single .blog-img {
  position: relative;
  background-color: var(--smoke-color);
}
.blog-single .blog-audio::before,
.blog-single .blog-img::before {
  display: none;
}
.blog-single .blog-audio::after,
.blog-single .blog-img::after {
  opacity: 0.3;
  height: 100%;
}
.blog-single .blog-audio img,
.blog-single .blog-audio > *,
.blog-single .blog-img img,
.blog-single .blog-img > * {
  border-radius: 5px 5px 0 0;
}
.blog-single .blog-img .slick-arrow {
  --pos-x: 30px;
  border: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 5px;
}
.blog-single .blog-img .slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.blog-single .blog-img .play-btn {
  --icon-size: 120px;
  --icon-font-size: 48px;
  position: absolute;
  left: 50%;
  top: 50%;
}
.blog-single .blog-img .play-btn::before, .blog-single .blog-img .play-btn::after {
  border-radius: 0;
}
.blog-single .blog-img .play-btn > i {
  border-radius: 0;
  background-color: rgba(var(--theme-color-rgb), 0.7);
}
.blog-single:hover .blog-img .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.blog-author {
  background-color: var(--smoke-color);
  padding: 30px;
  display: flex;
  align-items: center;
  margin: var(--blog-space-y, 60px) 0;
}
.blog-author .media-img {
  width: 189px;
  margin: 0 30px 0 0;
}
.blog-author .author-name {
  font-size: 24px;
  font-weight: 700;
  margin: -0.3em 0 0px 0;
}
.blog-author .author-degi {
  color: var(--theme-color);
}
.blog-author .author-text {
  margin: 0 0 -0.1em 0;
}

.blog-details .blog-single {
  background: transparent;
  box-shadow: none;
  padding: 0;
}
.blog-details .blog-img {
  margin: 0 0 40px 0;
}
.blog-details .blog-img img {
  border-radius: 0;
}
.blog-details .blog-content {
  margin: 0;
}

.blog-style1 {
  background-color: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.07);
  border-radius: 5px;
}
.blog-style1 .blog-meta {
  margin: 0 0 10px 0;
}
.blog-style1 .blog-content {
  padding: 35px 40px 33px 40px;
}

.blog-style2 {
  background-color: var(--white-color);
  box-shadow: none;
}
.blog-style2 .blog-meta {
  margin: 0 0 15px;
}
.blog-style2 .blog-text {
  margin: 0 0 20px 0;
}
.blog-style2 .blog-content {
  border: 2px solid #ecf2fd;
  border-top: none;
  padding: 35px 40px 40px 40px;
}

.blog-style3 .blog-img {
  overflow: hidden;
  margin: -2px;
}
.blog-style3 .blog-img img {
  transform: scale(1);
  transition: all ease 0.4s;
}
.blog-style3 .blog-body {
  border: 2px solid #f4f5f8;
}
.blog-style3:nth-child(odd) .blog-body {
  display: flex;
  flex-direction: column-reverse;
}
.blog-style3 .blog-content {
  padding: 35px 40px 30px 40px;
}
.blog-style3 .blog-meta {
  margin-bottom: 15px;
}
.blog-style3 .blog-meta i {
  color: var(--body-color);
}
.blog-style3 .blog-meta a {
  position: relative;
  line-height: 1;
  border-right: 1px solid #c8c8c8;
  margin-right: 12px;
  padding: 0 15px 0 0;
}
.blog-style3 .blog-meta a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
.blog-style3 .blog-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.blog-style3 .link-btn {
  text-transform: capitalize;
}
.blog-style3:hover .blog-img img {
  transform: scale(1.1);
}

.blog-style4 {
  box-shadow: 8.5px 0 60px 0px rgba(166, 170, 184, 0.05);
  border-radius: 20px;
  padding: 20px;
  border: 2px solid #eff2fb;
}
.blog-style4 .blog-content {
  padding: 0 20px 10px 20px;
}
.blog-style4 .blog-img {
  overflow: hidden;
  border-radius: 20px;
  margin: 0 0 32px 0;
}
.blog-style4 .blog-img img {
  transform: scale(1);
  transition: all ease 0.4s;
}
.blog-style4 .blog-meta {
  margin-bottom: 4px;
}
.blog-style4 .blog-meta i {
  color: var(--body-color);
}
.blog-style4 .blog-meta a {
  font-size: 14px;
}
.blog-style4 .blog-title {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 22px;
}
.blog-style4:hover .blog-img img {
  transform: scale(1.2);
}

@media (min-width: 1199px) {
  .blog-style4-slider {
    max-width: 775px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .blog-style2 .blog-content {
    padding: 30px 25px 35px 25px;
  }
  .blog-style1 .blog-content {
    padding: 35px 25px 33px 25px;
  }
  .blog-style1 .blog-title {
    font-size: 18px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  blockquote {
    font-size: 20px;
    padding: 23px 30px 26px 30px;
  }
  blockquote cite {
    font-size: 16px;
  }
  .blog-single {
    --blog-space-y: 40px;
    --blog-space-x: 30px;
  }
  .blog-single .blog-title {
    font-size: 24px;
  }
  .blog-meta span,
  .blog-meta a {
    font-size: 14px;
  }
  .blog-style3 .blog-content {
    padding: 25px 25px 30px 25px;
  }
  .blog-style4 {
    padding: 10px;
  }
  .blog-style4 .blog-content {
    padding: 0 15px 20px 15px;
  }
  .blog-style4 .blog-title {
    margin-bottom: 15px;
    font-size: 20px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  blockquote.vs-quote {
    padding: 40px 15px;
  }
  blockquote.vs-quote p {
    line-height: 1.8;
  }
  blockquote.vs-quote cite {
    padding: 0;
    margin-top: 10px;
  }
  .blog-inner-title {
    font-size: 26px;
  }
  .blog-single {
    --blog-space-y: 40px;
    --blog-space-x: 20px;
  }
  .blog-single .blog-title {
    font-size: 20px;
  }
  .blog-single .blog-img .play-btn {
    --icon-size: 60px;
    --icon-font-size: 20px;
  }
  .blog-author {
    display: block;
    padding: 30px 15px;
  }
  .blog-author .media-img {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .blog-author .media-img img {
    width: 100%;
  }
  .blog-author .author-name {
    font-size: 18px;
  }
  .blog-author .author-degi {
    margin-bottom: 10px;
  }
}
/*------------------- 4.7. Comments  -------------------*/
.comment-respond {
  margin: var(--blog-space-y, 50px) 0 30px 0;
  position: relative;
}
.comment-respond .form-title a#cancel-comment-reply-link {
  font-size: 0.7em;
  text-decoration: underline;
}
.comment-respond .custom-checkbox.notice {
  margin-top: 10px;
  margin-bottom: 25px;
}
.comment-respond .row {
  --bs-gutter-x: 20px;
}
.comment-respond .form-control {
  background-color: var(--smoke-color);
  border: none;
  height: 50px;
}
.comment-respond input[type=checkbox] ~ label:before {
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 0px;
}
.comment-respond input[type=checkbox]:checked ~ label:before {
  border: 1px solid var(--border-color);
  color: var(--theme-color);
}
.comment-respond .custom-checkbox > label {
  color: var(--body-color);
}
.comment-respond .form-text {
  margin-bottom: 25px;
}
.comment-respond .vs-btn {
  clip-path: none;
}

.question-form .comment-respond,
.review-form .comment-respond {
  margin: 0 0 20px 0;
}
.question-form .form-control,
.review-form .form-control {
  background-color: transparent;
  border: 1px solid #e4e4e4;
}
.question-form .rating-select,
.review-form .rating-select {
  display: flex;
  align-items: center;
}
.question-form .rating-select label,
.review-form .rating-select label {
  font-weight: 500;
  color: var(--title-color);
}

.vs-comments-wrap {
  margin: var(--blog-space-y, 50px) 0 var(--blog-space-y, 0px) 0;
}
.vs-comments-wrap .description p:last-child {
  margin-bottom: 0;
}
.vs-comments-wrap .comment-respond {
  margin: 30px 0;
}
.vs-comments-wrap pre {
  background: #ededed;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.vs-comments-wrap blockquote {
  background-color: #eaf8f9;
}
.vs-comments-wrap li {
  margin: 0;
}
.vs-comments-wrap .vs-post-comment {
  display: flex;
  position: relative;
  padding: 40px 40px 30px;
  background: var(--smoke-color);
}
.vs-comments-wrap ul.comment-list {
  list-style: none;
  margin: -10px 0 0 0;
  padding: 0;
}
.vs-comments-wrap ul.comment-list ul ul,
.vs-comments-wrap ul.comment-list ul ol,
.vs-comments-wrap ul.comment-list ol ul,
.vs-comments-wrap ul.comment-list ol ol {
  margin-bottom: 0;
}
.vs-comments-wrap .comment-avater {
  width: 110px;
  height: 110px;
  margin-right: 20px;
  overflow: hidden;
  background-color: var(--white-color);
}
.vs-comments-wrap .comment-avater img {
  width: 100%;
}
.vs-comments-wrap .comment-content {
  flex: 1;
  position: relative;
}
.vs-comments-wrap .commented-on {
  font-size: 16px;
  display: inline-flex;
  margin-bottom: 7px;
  margin-top: 0;
  font-weight: 700;
  color: var(--title-color);
  font-family: var(--title-font);
  align-items: center;
}
.vs-comments-wrap .commented-on i {
  margin-right: 7px;
  font-size: 0.9rem;
  color: var(--theme-color);
}
.vs-comments-wrap .name {
  margin-bottom: 7px;
  font-size: 20px;
  font-weight: 700;
}
.vs-comments-wrap .comment-top {
  display: flex;
  justify-content: space-between;
}
.vs-comments-wrap .children {
  margin: 20px 0 20px 60px;
  padding: 0;
  list-style-type: none;
}
.vs-comments-wrap .reply_and_edit {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 1;
}
.vs-comments-wrap .replay-btn {
  font-weight: 700;
  font-size: 16px;
  display: inline-flex;
  font-family: var(--title-font);
  color: var(--theme-color);
  align-items: center;
  gap: 8px;
}
.vs-comments-wrap .replay-btn i {
  margin-right: 0px;
  font-size: 16px;
}
.vs-comments-wrap .replay-btn:hover {
  color: var(--title-color);
}
.vs-comments-wrap .star-rating {
  font-size: 14px;
  margin-bottom: 7px;
  top: 0;
  right: 0;
  width: 95px;
}
.vs-comments-wrap .star-rating::before {
  color: var(--sec-color);
}

.vs-comments-wrap.vs-comment-form {
  margin: 0;
}

/* Large devices */
@media (max-width: 1199px) {
  .vs-comments-wrap .vs-post-comment {
    display: block;
  }
  .vs-comments-wrap .star-rating {
    position: relative;
    top: 0;
    right: 0;
  }
  .vs-comments-wrap .comment-top {
    display: block;
  }
  .vs-comments-wrap .comment-avater {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .vs-comments-wrap .children {
    margin-left: 40px;
  }
  .vs-comments-wrap .reply_and_edit {
    position: relative;
    top: 0;
    margin: 15px 0 0 0;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .vs-comments-wrap .children {
    margin-left: 20px;
  }
  .vs-comments-wrap .name {
    font-size: 18px;
  }
  .comment-respond .form-inner {
    padding: 30px 15px;
  }
}
/*------------------- 4.8. Hero Area  -------------------*/
.hero-title,
.hero-text,
.vs-btn {
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-name: var(--animation-name);
}

.hero-bg, .hero-bg3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  opacity: 1;
  transform: scale(1);
  transition: transform 7000ms ease, opacity 1500ms ease-in;
}
.hero-bg::before, .hero-bg3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #01133c 34.22%, rgba(1, 19, 60, 0) 74.58%);
  opacity: 0.9;
}
.hero-inner, .hero-inner3 {
  min-height: 920px;
  display: flex;
  align-items: center;
}
@media (max-width: 1919px) {
  .hero-inner, .hero-inner3 {
    min-height: 695px;
  }
}
.hero-title, .hero-text {
  color: var(--white-color);
}
.hero-title {
  font-size: 64px;
  line-height: 74px;
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .hero-title {
    font-size: 40px;
    line-height: 50px;
  }
}
.hero-title > span {
  color: var(--sec-color);
}
.hero-text {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 32px;
  padding-right: 20px;
}
@media (max-width: 1399px) {
  .hero-text {
    padding-right: 0;
  }
}
.hero--layout1 .hero-inner {
  min-height: 930px;
}
@media (max-width: 1499px) {
  .hero--layout1 .hero-inner {
    min-height: 750px;
    padding-bottom: 100px;
  }
}
@media (max-width: 1199px) {
  .hero--layout1 .hero-inner {
    min-height: 700px;
    padding-bottom: 100px;
  }
}
.hero--layout1 .hero-bg::before {
  background: linear-gradient(90deg, #01133c 37.67%, rgba(1, 19, 60, 0) 58.62%);
  opacity: 0.9;
}
.hero--layout1 .hero-text, .hero--layout2 .hero-text, .hero--layout3 .hero-text {
  animation-delay: 0.5s;
}
.hero--layout1 .vs-btn, .hero--layout2 .vs-btn, .hero--layout3 .vs-btn {
  animation-delay: 0.8s;
}
.hero--layout1 .slick-dots, .hero--layout2 .slick-dots, .hero--layout3 .slick-dots {
  position: absolute;
  top: auto;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
  right: 0;
  display: flex;
  gap: 10px;
  bottom: 20%;
  justify-content: center;
}
@media (max-width: 1199px) {
  .hero--layout1 .slick-dots, .hero--layout2 .slick-dots, .hero--layout3 .slick-dots {
    display: none;
  }
}
.hero--layout1 .slick-dots button, .hero--layout2 .slick-dots button, .hero--layout3 .slick-dots button {
  font-size: 30px;
  width: 30px;
  height: 10px;
  border-radius: 0%;
  background-color: transparent;
  color: var(--white-color);
  border: 2px solid var(--white-color);
  font-family: var(--title-font);
  font-size: 20px;
  font-weight: 700;
  text-indent: -9999999999px;
}
.hero--layout1 .slick-dots button li, .hero--layout2 .slick-dots button li, .hero--layout3 .slick-dots button li {
  margin-bottom: 0;
}
.hero--layout1 .slick-dots button::before, .hero--layout2 .slick-dots button::before, .hero--layout3 .slick-dots button::before {
  display: none;
}
.hero--layout1 .slick-dots li, .hero--layout2 .slick-dots li, .hero--layout3 .slick-dots li {
  display: block;
  margin: 0 0 20px 0;
}
.hero--layout1 .slick-dots li.slick-active button, .hero--layout2 .slick-dots li.slick-active button, .hero--layout3 .slick-dots li.slick-active button {
  transform: scale(1);
  background-color: var(--theme-color);
  color: var(--white-color);
  border: 2px solid var(--white-color);
}
.hero--layout1 .slick-dots li:last-child, .hero--layout2 .slick-dots li:last-child, .hero--layout3 .slick-dots li:last-child {
  margin-bottom: 0;
}
.hero--layout1 .slick-current, .hero--layout2 .slick-current, .hero--layout3 .slick-current {
  --animation-name: slideinup;
}
.hero-bg3::before {
  background: linear-gradient(90deg, #01133c 9%, rgba(1, 19, 60, 0) 75.58%);
  opacity: 0.9;
}

.call-block2 {
  position: absolute;
  bottom: 145px;
  right: 0;
  background-color: var(--theme-color);
  z-index: 2;
  color: var(--white-color);
  padding: 0 77px 0 140px;
  min-height: 150px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .call-block2 {
    display: none;
  }
}
.call-block2 .vc-icon {
  position: absolute;
  left: -75px;
  min-width: 150px;
  min-height: 150px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--theme-color);
  font-size: 64px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.call-block2 .vc-icon::before, .call-block2 .vc-icon::after {
  position: absolute;
  content: "";
  min-width: 200px;
  min-height: 200px;
  background-color: var(--white-color);
  border-radius: 50%;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.call-block2 .vc-title,
.call-block2 .vc-number {
  font-size: 24px;
  display: block;
  font-weight: 700;
  font-family: var(--title-font);
  line-height: 1.3;
  transition: all 0.3s ease-in-out;
}
.call-block2 .vc-number {
  font-size: 40px;
}
.call-block2:hover {
  background-color: var(--sec-color);
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.call-block2:hover .vc-icon {
  background-color: var(--sec-color);
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}

/*------------------- 4.9. Error  -------------------*/
.error-content .sec-title,
.comming-content .sec-title {
  font-size: 260px;
  font-weight: 800;
  line-height: 1;
  color: var(--white-color);
  margin: 0 0 0px 0;
}
.error-content .sec-title2,
.comming-content .sec-title2 {
  font-size: 40px;
  margin: 0 0 10px 0;
}
.error-content .sec-title2 > span,
.comming-content .sec-title2 > span {
  color: var(--theme-color);
}
.error-content .error-content__text,
.comming-content .error-content__text {
  color: var(--white-color);
  font-size: 20px;
  margin-bottom: 35px;
}

.comming-content .sec-title {
  font-size: 25px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .comming-content .sec-title {
    font-size: 18px;
  }
}
.comming-content .sec-title2 {
  font-size: 60px;
  color: var(--white-color);
}
@media (max-width: 767px) {
  .comming-content .sec-title2 {
    font-size: 30px;
  }
}

/*------------------- 4.10. Feature  -------------------*/
/*------------------- 4.11. About  -------------------*/
.about-logo {
  position: absolute;
  top: 0;
  min-width: 330px;
  min-height: 330px;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  background: var(--theme-color);
  border-radius: 50%;
  left: 0;
  z-index: -1;
  padding-left: 55px;
  opacity: 1;
}
@media (max-width: 991px) {
  .about-logo {
    min-width: 100%;
    display: none;
  }
}
.about-img {
  min-height: 635px;
  position: relative;
  padding-top: 80px;
  margin-left: -165px;
  padding-left: 165px;
  z-index: 1;
}
@media (max-width: 767px) {
  .about-img {
    margin-left: 0;
    padding-left: 0;
  }
}
.about-img .img2 {
  position: absolute;
  right: 0;
  bottom: 0;
}
.about--layout2 {
  background-color: var(--smoke-color);
}
.about-img2 {
  position: relative;
  padding: 40px 43px 107px 0;
  z-index: 1;
}
@media (max-width: 767px) {
  .about-img2 {
    padding: 0px 0px 150px 0;
  }
}
.about-img2 .element2 {
  position: absolute;
  top: 0;
  min-width: 336px;
  min-height: 460px;
  background-color: var(--theme-color);
  z-index: -1;
  right: 8%;
}
@media (max-width: 767px) {
  .about-img2 .element2 {
    display: none;
  }
}
.about-img2 .text-box {
  position: absolute;
  bottom: 0;
  left: auto;
  right: 8%;
}
@media (max-width: 991px) {
  .about-img2 .play-btn2 {
    display: none;
  }
}
.about-img3 {
  display: flex;
  column-gap: 30px;
}
@media (max-width: 1199px) {
  .about-img3 {
    column-gap: 15px;
  }
}
.about-img3 img {
  margin-bottom: 30px;
}
@media (max-width: 1199px) {
  .about-img3 img {
    margin-bottom: 15px;
  }
}
.about-img3 .img1 {
  padding-top: 110px;
}
@media (max-width: 1199px) {
  .about-img3 .img1 {
    padding-top: 80px;
  }
}
.about-img3 .img3 {
  text-align: center;
}

.features {
  display: flex;
  gap: 60px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 40px;
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .features {
    flex-wrap: wrap;
    gap: 30px;
  }
}

.progress-box {
  margin: 0 0 25px 0;
}
.progress-box__number, .progress-box__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  font-family: var(--title-font);
  color: var(--title-color);
  display: inline-block;
  margin: 0 0 11px 0;
}
.progress-box__number {
  float: right;
  margin: 5px 0 0 0;
}
.progress-box__progress {
  border: 1px solid var(--theme-color);
  height: 16px;
  padding: 4px;
  border-radius: 8px;
  background-color: var(--white-color);
}
.progress-box__bar {
  height: 100%;
  background-color: var(--theme-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
}
.progress-box.style2 .progress-box__title, .progress-box.style2 .progress-box__number {
  color: var(--body-color);
  font-weight: 400;
  font-family: var(--body-font);
}
.progress-box.style2 .progress-box__progress, .progress-box.style2 .progress-box__bar {
  border-radius: 0;
}
.progress-box.style2:last-child {
  margin-bottom: 0;
}

.play-btn2 {
  position: absolute;
  top: 60px;
  left: 45px;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
.play-btn2 > span {
  background-color: var(--theme-color);
  width: 60px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 7px solid var(--white-color);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.play-btn2::before {
  content: "";
  position: absolute;
  width: 80px;
  height: 167px;
  background-color: var(--theme-color);
  z-index: -1;
  bottom: -22.74px;
  left: 0;
  transform: translateX(-50%) rotate(-45deg);
  border-radius: 0px 0px 40px 40px;
  transition: all 0.3s ease-in-out;
}
.play-btn2:hover::before {
  background-color: var(--sec-color);
}
.play-btn2:hover > span {
  background-color: var(--sec-color);
}

/*------------------- 4.12. Service  -------------------*/
.service--layout1 {
  padding-bottom: 250px;
  background-attachment: fixed;
}
.service-carousel .slick-list {
  margin: -30px 0;
  padding: 30px 0;
}
.service--layout3 {
  margin-top: -130px;
  background-position-y: 0;
  background-color: var(--smoke-color);
}
.service--layout4 {
  padding-top: 268px;
}
.service-block--style1, .service-block--style2 {
  text-align: center;
  background: var(--white-color);
  box-shadow: 0px 0px 10px rgba(1, 19, 60, 0.1);
  padding: 40px 30px;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
.service-block--style1 .service-block__icon, .service-block--style2 .service-block__icon {
  min-width: 120px;
  min-height: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--smoke-color);
  border-radius: 50%;
  background-size: 100% 80%;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}
.service-block--style1 .service-block__icon > img, .service-block--style2 .service-block__icon > img {
  filter: none;
  transition: all 0.3s ease-in-out;
}
.service-block--style1 .service-block__icon::before, .service-block--style2 .service-block__icon::before {
  border-radius: 50%;
  background-color: var(--theme-color);
  content: "";
  min-width: 90px;
  min-height: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0) rotate(360deg);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.service-block--style1 .service-block__title, .service-block--style2 .service-block__title {
  margin-bottom: 10px;
}
.service-block--style1 .service-block__title > a, .service-block--style2 .service-block__title > a {
  color: var(--theme-color);
}
.service-block--style1 .service-block__title > a:hover, .service-block--style2 .service-block__title > a:hover {
  color: var(--sec-color);
}
.service-block--style1 .service-block__text, .service-block--style2 .service-block__text {
  transition: all 0.3s ease-in-out;
}
.service-block--style1 .vs-btn, .service-block--style2 .vs-btn {
  padding: 12px 30px;
  background-color: var(--theme-color);
}
.service-block--style1:hover, .service-block--style2:hover {
  background-color: var(--theme-color);
}
.service-block--style1:hover .service-block__icon, .service-block--style2:hover .service-block__icon {
  background-size: 100% 0%;
}
.service-block--style1:hover .service-block__icon > img, .service-block--style2:hover .service-block__icon > img {
  filter: brightness(0) invert(1);
  transition: all 0.3s ease-in-out;
}
.service-block--style1:hover .service-block__icon::before, .service-block--style2:hover .service-block__icon::before {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1) rotate(0deg);
}
.service-block--style1:hover .service-block__title > a, .service-block--style2:hover .service-block__title > a {
  color: var(--white-color);
}
.service-block--style1:hover .service-block__text, .service-block--style2:hover .service-block__text {
  color: var(--white-color);
}
.service-block--style1:hover .vs-btn, .service-block--style2:hover .vs-btn {
  color: var(--theme-color);
  background-color: var(--white-color);
}
.service-block--style1:hover .vs-btn::before, .service-block--style1:hover .vs-btn::after, .service-block--style2:hover .vs-btn::before, .service-block--style2:hover .vs-btn::after {
  background-color: var(--sec-color);
}
.service-block--style1:hover .vs-btn:hover, .service-block--style2:hover .vs-btn:hover {
  color: var(--white-color);
}
.service-block--style1 .vs-btn::before, .service-block--style1 .vs-btn::after {
  background-color: var(--sec-color);
}
.service-block--style1 .vs-btn:hover {
  background-color: var(--sec-color);
}
.service-block--style2 {
  padding: 0 0;
}
.service-block--style2 .service-block__icon {
  background-color: var(--white-color);
  margin-top: -120px;
}
.service-block--style2 .service-block__content {
  padding: 0 30px 30px;
}
@media (max-width: 991px) {
  .service-block--style2 .service-block__content {
    padding: 0 19px 30px;
  }
}
.service-block--style2 .service-block__thumb img {
  width: 100%;
}

.service-thumb-slide {
  margin: 0 -10px;
}
.service-thumb-slide .thumb {
  padding: 0 10px;
}

.video-box .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0;
}
.video-box .play-btn::before, .video-box .play-btn::after {
  border-radius: 0;
  background-color: var(--theme-color);
}
.video-box .play-btn i {
  border-radius: 0;
  background-color: var(--theme-color);
}
.video-box .play-btn:hover::before, .video-box .play-btn:hover::after {
  background-color: var(--sec-color);
}
.video-box .play-btn:hover i {
  background-color: var(--sec-color);
}

/*------------------- 4.13. Counter  -------------------*/
.counter--layout2 {
  background-color: var(--smoke-color);
}
.counter-inner {
  padding-left: 120px;
  padding-right: 120px;
  margin: -160px -120px 0;
  position: relative;
  z-index: 2;
  background-attachment: fixed;
  padding-bottom: calc(var(--section-space) - 30px);
  padding-top: var(--section-space);
}
@media (max-width: 1399px) {
  .counter-inner {
    margin: -160px 0 0;
    padding: 60px 60px 30px;
  }
}
@media (max-width: 1199px) {
  .counter-inner {
    padding: 30px 30px 0px;
  }
}
.counter-block {
  display: flex;
  align-items: center;
  gap: 20px;
}
.counter-block__icon {
  display: inline-flex;
  min-width: 80px;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
}
.counter-block__heading {
  font-size: 16px;
  margin-bottom: 0;
  color: var(--white-color);
}
.counter-block__number {
  font-size: 40px;
  font-weight: 700;
  color: var(--white-color);
  font-family: var(--title-font);
  margin-bottom: 3px;
  display: block;
  line-height: 50px;
}
.counter-block.style2 .counter-block__icon {
  background-color: var(--title-color);
  color: var(--white-color);
}
.counter-block.style2 .counter-block__number {
  color: var(--title-color);
}
.counter-block.style2 .counter-block__heading {
  color: var(--theme-color);
}

.offer-counter {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  background: var(--white-color);
  margin-bottom: 30px;
  min-height: 140px;
  align-items: center;
}
@media (max-width: 767px) {
  .offer-counter {
    flex-direction: column;
  }
}
.offer-counter > li {
  line-height: 1;
  padding: 16px 30px 16px 30px;
  position: relative;
}
.offer-counter > li::before {
  content: "";
  position: absolute;
  height: 37px;
  width: 1px;
  background: var(--grey-color);
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0.49px;
}
@media (max-width: 767px) {
  .offer-counter > li::before {
    display: none;
  }
}
.offer-counter > li:first-child {
  padding-left: 0;
}
.offer-counter > li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.offer-counter > li:last-child::before {
  display: none;
}
@media (max-width: 767px) {
  .offer-counter > li {
    padding-right: 0;
    padding-left: 0;
  }
}
.offer-counter .count-number {
  font-size: 40px;
  font-weight: 800;
  color: var(--theme-color);
  font-family: var(--title-font);
  margin-bottom: 5px;
}
.offer-counter .count-name {
  font-size: 18px;
  color: var(--black-color);
  font-family: var(--title-font);
  font-weight: 800;
  text-transform: lowercase;
  display: inline-block;
}

/*------------------- 4.14. Team  -------------------*/
.team-block--style1 {
  background-color: var(--smoke-color);
  text-align: center;
  padding: 40px 20px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
}
.team-block--style1 .team-block__img {
  max-width: 150px;
  max-height: 150px;
  margin: 0 auto 22px;
  border-radius: 50%;
  overflow: hidden;
}
.team-block--style1 .team-block__img img {
  transition: all 0.3s ease-in-out;
}
.team-block--style1 .team-block__name {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 5px;
}
.team-block--style1 .team-block__name > a {
  color: var(--title-color);
  transition: all 0.3s ease-in-out;
}
.team-block--style1 .team-block__desig {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color);
  display: block;
  margin-bottom: 23px;
  transition: all 0.3s ease-in-out;
}
.team-block--style1 .social-style1 {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.team-block--style1 .social-style1 > a {
  width: 35px;
  height: 35px;
  font-size: 14px;
  background: var(--white-color);
  color: var(--title-color);
  margin-right: 0;
  border: none;
}
.team-block--style1 .social-style1 > a:hover {
  background-color: var(--white-color);
  color: var(--sec-color);
}
.team-block--style1:hover {
  background-color: var(--sec-color);
}
.team-block--style1:hover .team-block__name > a {
  color: var(--white-color);
}
.team-block--style1:hover .team-block__desig {
  color: var(--white-color);
}
.team-block--style1:hover .social-style1 > a {
  background-color: var(--white-color);
  color: var(--sec-color);
}
.team-block--style1:hover .social-style1 > a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.team-block--style1:hover .team-block__img img {
  transition: all 0.3s ease-in-out;
  transform: scale(1.1) rotate(0deg);
}
.team-block--style2, .team-block--style3 {
  margin-bottom: 30px;
}
.team-block--style2 .team-block__img, .team-block--style3 .team-block__img {
  position: relative;
}
.team-block--style2 .team-block__img img, .team-block--style3 .team-block__img img {
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .team-block--style2 .team-block__img img, .team-block--style3 .team-block__img img {
    width: 100%;
  }
}
.team-block--style2 .team-block__body, .team-block--style3 .team-block__body {
  position: relative;
  background-color: var(--smoke-color);
  padding: 22px 30px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1199px) {
  .team-block--style2 .team-block__body, .team-block--style3 .team-block__body {
    padding: 20px 20px;
  }
}
.team-block--style2 .team-block__name, .team-block--style3 .team-block__name {
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}
.team-block--style2 .team-block__name > a, .team-block--style3 .team-block__name > a {
  display: block;
  color: var(--title-color);
}
.team-block--style2 .team-block__name > a:hover, .team-block--style3 .team-block__name > a:hover {
  color: var(--theme-color);
}
.team-block--style2 .team-block__desig, .team-block--style3 .team-block__desig {
  font-weight: 600;
  color: var(--theme-color);
  font-family: var(--title-font);
  transition: all 0.3s ease-in-out;
}
.team-block--style2 .social-style1, .team-block--style3 .social-style1 {
  position: absolute;
  bottom: -25px;
  right: 30px;
}
@media (max-width: 1199px) {
  .team-block--style2 .social-style1, .team-block--style3 .social-style1 {
    right: 15px;
  }
}
.team-block--style2 .social-style1 > ul, .team-block--style3 .social-style1 > ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.team-block--style2 .social-style1 > ul > li, .team-block--style3 .social-style1 > ul > li {
  position: relative;
  z-index: 1;
  width: 50px;
  cursor: pointer;
}
.team-block--style2 .social-style1 > ul > li > span, .team-block--style3 .social-style1 > ul > li > span {
  width: 50px;
  height: 50px;
  background-color: var(--theme-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.team-block--style2 .social-style1 .sub-list, .team-block--style3 .social-style1 .sub-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 60px;
  right: 0;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%) scaleY(0);
  width: 50px;
  background: transparent;
  gap: 20px;
  padding: 15px 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  transform-origin: bottom;
  opacity: 0;
  visibility: hidden;
}
.team-block--style2 .social-style1 .sub-list > a, .team-block--style3 .social-style1 .sub-list > a {
  margin-right: 0;
  width: unset;
  height: unset;
  background-color: transparent;
  color: var(--white-color);
  border: none;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  transform: translateX(20px);
  opacity: 0;
  visibility: hidden;
}
.team-block--style2 .social-style1 .sub-list > a:nth-child(1), .team-block--style3 .social-style1 .sub-list > a:nth-child(1) {
  transition-delay: 0.16s;
}
.team-block--style2 .social-style1 .sub-list > a:nth-child(2), .team-block--style3 .social-style1 .sub-list > a:nth-child(2) {
  transition-delay: 0.13s;
}
.team-block--style2 .social-style1 .sub-list > a:nth-child(3), .team-block--style3 .social-style1 .sub-list > a:nth-child(3) {
  transition-delay: 0.1s;
}
.team-block--style2 .social-style1 .sub-list > a:nth-child(4), .team-block--style3 .social-style1 .sub-list > a:nth-child(4) {
  transition-delay: 0.09s;
}
.team-block--style2 .social-style1 .sub-list > a:nth-child(5), .team-block--style3 .social-style1 .sub-list > a:nth-child(5) {
  transition-delay: 0.06s;
}
.team-block--style2 .social-style1 .sub-list > a:nth-child(6), .team-block--style3 .social-style1 .sub-list > a:nth-child(6) {
  transition-delay: 0.03s;
}
.team-block--style2 .social-style1 > ul > li:hover .sub-list, .team-block--style3 .social-style1 > ul > li:hover .sub-list {
  background: var(--theme-color);
  transform: translateX(-50%) scaleY(1);
  opacity: 1;
  visibility: visible;
}
.team-block--style2 .social-style1 > ul > li:hover .sub-list > a, .team-block--style3 .social-style1 > ul > li:hover .sub-list > a {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.team-block--style2 .social-style1 > ul > li:hover .sub-list > a:nth-child(1), .team-block--style3 .social-style1 > ul > li:hover .sub-list > a:nth-child(1) {
  transition-delay: 0.03s;
}
.team-block--style2 .social-style1 > ul > li:hover .sub-list > a:nth-child(2), .team-block--style3 .social-style1 > ul > li:hover .sub-list > a:nth-child(2) {
  transition-delay: 0.06s;
}
.team-block--style2 .social-style1 > ul > li:hover .sub-list > a:nth-child(3), .team-block--style3 .social-style1 > ul > li:hover .sub-list > a:nth-child(3) {
  transition-delay: 0.09s;
}
.team-block--style2 .social-style1 > ul > li:hover .sub-list > a:nth-child(4), .team-block--style3 .social-style1 > ul > li:hover .sub-list > a:nth-child(4) {
  transition-delay: 0.1s;
}
.team-block--style2 .social-style1 > ul > li:hover .sub-list > a:nth-child(5), .team-block--style3 .social-style1 > ul > li:hover .sub-list > a:nth-child(5) {
  transition-delay: 0.13s;
}
.team-block--style2 .social-style1 > ul > li:hover .sub-list > a:nth-child(6), .team-block--style3 .social-style1 > ul > li:hover .sub-list > a:nth-child(6) {
  transition-delay: 0.16s;
}
.team-block--style2 .social-style1 > ul > li:hover .sub-list > a:hover, .team-block--style3 .social-style1 > ul > li:hover .sub-list > a:hover {
  color: var(--sec-color);
}
.team-block--style2:hover .social-style1 > ul > li > span, .team-block--style3:hover .social-style1 > ul > li > span {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.team-block--style2:hover .team-block__body, .team-block--style3:hover .team-block__body {
  background-color: var(--sec-color);
}
.team-block--style2:hover .team-block__name,
.team-block--style2:hover .team-block__desig, .team-block--style3:hover .team-block__name,
.team-block--style3:hover .team-block__desig {
  color: var(--white-color);
}
.team-block--style2:hover .team-block__name > a, .team-block--style3:hover .team-block__name > a {
  color: var(--white-color);
}
.team-block--style2:hover .team-block__img img, .team-block--style3:hover .team-block__img img {
  transition: all 0.3s ease-in-out;
}
.team-block--style3 {
  background-color: var(--white-color);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.07);
}
.team-block--style3 .team-block__body {
  background-color: transparent;
  border-radius: 0 0px 0 0;
  margin-top: -76px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgzIiBoZWlnaHQ9IjE0MSIgdmlld0JveD0iMCAwIDI4MyAxNDEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wIDEzLjQ5OTdWMTQwLjNIMjgzVjcyLjI5OTdDMjgzIDcyLjI5OTcgMjExIDkuMjk5NzYgMTI4LjMgMS42OTk3NkM0NS43IC01LjgwMDI0IDAgMTMuNDk5NyAwIDEzLjQ5OTdaIiBmaWxsPSIjMDA0NkUyIi8+Cjwvc3ZnPgo=");
  padding: 42px 30px 30px;
  transition: all 0.5s ease-in-out;
}
.team-block--style3 .team-block__name > a {
  color: var(--white-color);
}
.team-block--style3 .team-block__desig {
  color: var(--white-color);
}
.team-block--style3 .social-style1 {
  position: absolute;
  bottom: auto;
  right: 20px;
  top: 16px;
}
.team-block--style3 .social-style1 > ul > li .sub-list {
  border-radius: 25px;
  bottom: 40px;
}
.team-block--style3 .social-style1 > ul > li > span {
  border-radius: 50%;
  background: var(--white-color);
  color: var(--theme-color);
}
.team-block--style3:hover .social-style1 > ul > li .sub-list {
  bottom: 60px;
}
.team-block--style3:hover .team-block__body {
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgzIiBoZWlnaHQ9IjE0MSIgdmlld0JveD0iMCAwIDI4MyAxNDEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxtYXNrIGlkPSJtYXNrMF8yMDY5XzciIHN0eWxlPSJtYXNrLXR5cGU6bHVtaW5hbmNlIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjgzIiBoZWlnaHQ9IjE0MSI+CjxwYXRoIGQ9Ik0yODMgMEgwVjE0MC4zSDI4M1YwWiIgZmlsbD0id2hpdGUiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzIwNjlfNykiPgo8cGF0aCBkPSJNMCAxMy41MDAyVjE0MC4zSDI4M1Y3Mi4zMDAyQzI4MyA3Mi4zMDAyIDIxMSA5LjMwMDI1IDEyOC4zIDEuNzAwMjVDNDUuNyAtNS43OTk3NSAwIDEzLjUwMDIgMCAxMy41MDAyWiIgZmlsbD0iI0VGMEYzQSIvPgo8L2c+Cjwvc3ZnPgo=");
}

.tdetails-name {
  margin-bottom: 13px;
}
.tdetails-desig {
  font-size: 20px;
  color: var(--theme-color);
  font-family: var(--title-font);
  font-weight: 700;
}
.tdetails-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.footer-communicate.style2 > ul > li {
  margin-bottom: 10px;
}
.footer-communicate.style2 .footer-communicate__text > span {
  color: var(--body-color);
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}
.footer-communicate.style2 .footer-communicate__text > span:hover {
  color: var(--theme-color);
}

.social-style1.style2 {
  display: flex;
  align-items: center;
}
.social-style1.style2 > a {
  background-color: var(--theme-color);
  color: var(--white-color);
  width: 30px;
  height: 30px;
  font-size: 14px;
  border-color: var(--theme-color);
}
.social-style1.style2 > a:hover {
  background-color: var(--sec-color);
  border-color: var(--sec-color);
}

/*------------------- 4.15. Process  -------------------*/
.process--layout1 {
  padding-top: 272px;
  margin-top: -160px;
  background-color: var(--smoke-color);
  background-attachment: fixed;
}
.process--layout2 {
  padding-bottom: 245px;
}
.process--layout2 .overlay {
  background: linear-gradient(90deg, #01133c 41.07%, rgba(1, 19, 60, 0) 100%);
  opacity: 0.9;
  z-index: -1;
}
.process-block--style1 {
  background-color: var(--smoke-color);
  position: relative;
  display: flex;
  gap: 30px;
  padding: 40px 40px 30px 40px;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .process-block--style1 {
    gap: 15px;
    padding: 40px 15px 30px 15px;
    flex-wrap: wrap;
  }
}
.process-block--style1 .process-block__number {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 50px;
  min-height: 50px;
  background-color: var(--theme-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  font-size: 24px;
  transition: all 0.3s ease-in-out;
}
.process-block--style1 .process-block__icon {
  min-width: 70px;
  max-height: 70px;
  background-color: var(--theme-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .process-block--style1 .process-block__icon {
    min-height: 70px;
  }
}
.process-block--style1 .process-block__title {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 15px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}
.process-block--style1 .process-block__text {
  margin-bottom: 0;
}
.process-block--style1:hover {
  background-color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}
.process-block--style1:hover .process-block__icon {
  background-color: var(--title-color);
  transition: all 0.3s ease-in-out;
}
.process-block--style1:hover .process-block__number {
  background-color: var(--white-color);
  color: var(--title-color);
}
.process-block--style1:hover .process-block__title,
.process-block--style1:hover .process-block__text {
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.process-block--style2 {
  margin-bottom: 30px;
  background-color: transparent;
}
.process-block--style2:nth-child(even) {
  margin-top: 60px;
}
@media (max-width: 991px) {
  .process-block--style2:nth-child(even) {
    margin-top: 0;
  }
}
.process-block--style2 .process-block__body {
  text-align: center;
  background: var(--white-color);
  padding: 30px 25px 25px;
}
.process-block--style2 .process-block__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  min-width: 100px;
  background-color: var(--theme-color);
  border-radius: 50%;
  margin-bottom: 15px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.process-block--style2 .process-block__icon > img {
  transform: rotateY(0deg);
  transition: all 0.3s ease-in-out;
}
.process-block--style2 .process-block__title {
  font-size: 24px;
  transition: all 0.3s ease-in-out;
  margin: -0.25em 0 10px 0;
}
.process-block--style2 .process-block__text {
  margin-bottom: 0;
}
.process-block--style2 .process-block__number {
  position: absolute;
  top: 0px;
  right: -15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--title-color);
  font-size: 15px;
  font-weight: 400;
  color: var(--white-color);
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  border: 2px solid var(--theme-color);
  transition: all 0.3s ease-in-out 0s;
}
.process-block--style2:hover .process-block__number {
  background-color: var(--theme-color);
}
.process-block--style2:hover .process-block__icon {
  background-color: var(--sec-color);
}
.process-block--style2:hover .process-block__icon > img {
  transform: rotateY(180deg);
  transition: all 0.3s ease-in-out;
}
.process-block--style2:hover .process-block__title {
  color: var(--theme-color);
}

/*------------------- 4.16. Accordion  -------------------*/
.accordion-style1 {
  margin: 0 0 30px 0;
}
.accordion-style1 .accordion-item {
  margin: 0 0 20px 0;
  padding: 0 30px 0 30px;
  background-color: var(--white-color);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 0px;
}
.accordion-style1 .accordion-item:last-child {
  margin-bottom: 0;
}
.accordion-style1 .accordion-button {
  background-color: transparent;
  border: none;
  padding: 25px 0;
  font-size: 24px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--theme-color);
  text-align: left;
  gap: 10px;
}
.accordion-style1 .accordion-button.collapsed {
  color: var(--title-color);
}
.accordion-style1 .accordion-button:after {
  content: "\f067";
  font-family: var(--icon-font);
  font-weight: 400;
  border: none;
  width: 50px;
  height: 50px;
  background-image: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--smoke-color);
  color: var(--title-color);
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.accordion-style1 .accordion-button:focus {
  box-shadow: none;
}
.accordion-style1 .accordion-button:not(.collapsed):after {
  transform: rotate(0);
  content: "\f068";
  background-color: var(--theme-color);
  color: var(--white-color);
}
.accordion-style1 .accordion-body {
  border-top: 1px solid var(--border-color);
  padding: 20px 60px 23px 0;
  font-size: 16px;
}
.accordion-style1 .accordion-body p:last-child {
  margin-bottom: 0;
}
.accordion-style1 .accordion-collapse {
  border: none;
}
.accordion-style1.layout2 .accordion-item {
  padding: 0;
  box-shadow: none;
}
.accordion-style1.layout2 .accordion-button {
  padding: 14.5px 25px;
  font-size: 16px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 0;
}
.accordion-style1.layout2 .accordion-button:after {
  color: var(--white-color);
}
.accordion-style1.layout2 .accordion-button.collapsed {
  background-color: var(--smoke-color);
  color: var(--title-color);
}
.accordion-style1.layout2 .accordion-button.collapsed:after {
  color: var(--theme-color);
}
.accordion-style1.layout2 .accordion-body {
  border: none;
  background-color: var(--smoke-color);
  padding: 25px 25px 23px 25px;
}

.accordion-style2 .accordion-item {
  margin: 0 0 20px 0;
  padding: 0 35px 6.5px 35px;
  background-color: var(--white-color);
  box-shadow: 7.5px 12.99px 30px 0px rgba(148, 171, 255, 0.05);
  border-radius: 30px;
}
.accordion-style2 .accordion-button {
  background-color: transparent;
  border: none;
  padding: 24.5px 0 18px 0;
  font-size: 17px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--title-color);
  text-align: left;
}
.accordion-style2 .accordion-button:after {
  content: "\f35a";
  font-family: var(--icon-font);
  font-weight: 400;
  color: var(--title-color);
  border: none;
  width: auto;
  height: auto;
  background-image: none;
  transform: rotate(0);
}
.accordion-style2 .accordion-button:focus {
  box-shadow: none;
}
.accordion-style2 .accordion-button:not(.collapsed):after {
  transform: rotate(90deg);
}
.accordion-style2 .accordion-button:hover {
  color: var(--theme-color);
}
.accordion-style2 .accordion-body {
  padding: 0 0 20px 0;
}
.accordion-style2 .accordion-body p {
  font-size: 14px;
}
.accordion-style2 .accordion-body p:last-child {
  margin-bottom: 0;
}
.accordion-style2 .accordion-collapse {
  border: none;
}

.faq-wrap1 {
  padding-top: 240px;
  padding-bottom: 90px;
  position: relative;
}

.faq-shape1 {
  position: absolute;
  right: 0;
  top: 120px;
  bottom: 0;
  width: 100%;
  max-width: 1120px;
  border-radius: 5px 0 0 5px;
  z-index: -1;
}

.faq-shape2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 945px;
  max-height: 570px;
  z-index: -2;
  border-radius: 0 5px 5px 0;
}

/* Hight Resoulation devices */
@media (min-width: 1921px) {
  .faq-shape1 {
    max-width: 60%;
  }
  .faq-shape2 {
    max-width: 49%;
  }
}
/* Extra large devices */
@media (max-width: 1500px) {
  .faq-wrap1 {
    padding-top: var(--section-space, 120px);
    padding-bottom: calc(var(--section-space, 120px) - 30px);
  }
  .faq-shape2 {
    max-height: initial;
    bottom: 0;
    display: none;
  }
  .faq-shape1 {
    max-width: 70%;
    top: 0;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .faq-wrap1 {
    padding-top: var(--section-space, 120px);
  }
  .faq-shape2 {
    max-height: initial;
    bottom: 0;
    display: none;
  }
  .faq-shape1 {
    max-width: 70%;
    top: 0;
  }
  .accordion-style1 .accordion-button {
    font-size: 20px;
  }
  .accordion-style1 .accordion-body {
    font-size: 14px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .faq-wrap1 {
    padding-top: var(--section-space-mobile, 80px);
    padding-bottom: calc(var(--section-space-mobile, 80px) - 30px);
  }
  .faq-shape1 {
    max-width: 100%;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .accordion-style1 .accordion-item {
    padding: 0 20px 0px 20px;
  }
  .accordion-style1.layout2 .accordion-button {
    padding: 14.5px 20px;
    font-size: 16px;
  }
  .accordion-style1.layout2 .accordion-body {
    padding: 25px 20px 23px 20px;
  }
  .accordion-style2 .accordion-item {
    padding: 0 30px 6.5px 30px;
  }
}
/*------------------- 4.17. Simple Sections  -------------------*/
.work--layout1 {
  background-size: contain;
  background-position: right;
  background-color: var(--theme-color);
}
@media (max-width: 1199px) {
  .work--layout1 {
    background-size: cover;
  }
}
.work-right {
  padding-right: 57px;
}
.work-list {
  position: relative;
  z-index: 1;
}
.work-list::before {
  position: absolute;
  content: "";
  height: 85%;
  width: 4px;
  background-color: var(--white-color);
  z-index: -1;
  left: 35px;
  top: 50%;
  transform: translateY(-50%);
}
.work-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-right: 80px;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .work-item {
    padding-right: 0;
  }
}
.work-item:last-child {
  margin-bottom: 0;
}
.work-item__icon {
  min-width: 70px;
  min-height: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--title-color);
  border-radius: 0%;
}
.work-item__title {
  color: var(--white-color);
  margin-bottom: 4px;
  font-size: 24px;
}
.work-item__text {
  color: var(--white-color);
  margin-bottom: 0;
}

/*------------------- 4.18. Testimonial  -------------------*/
.client--layout1 {
  background-color: var(--smoke-color);
}
.client-block--style1, .client-block--style2, .client-block--style3 {
  background-color: var(--white-color);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
}
.client-block--style1 .client-block__img, .client-block--style2 .client-block__img, .client-block--style3 .client-block__img {
  height: 100%;
}
.client-block--style1 .client-block__img > img, .client-block--style2 .client-block__img > img, .client-block--style3 .client-block__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.client-block--style1 .client-block__content, .client-block--style2 .client-block__content, .client-block--style3 .client-block__content {
  padding: 55px 52px;
}
@media (max-width: 1199px) {
  .client-block--style1 .client-block__content, .client-block--style2 .client-block__content, .client-block--style3 .client-block__content {
    padding: 40px 40px;
  }
}
.client-block--style1 .client-block__rating, .client-block--style2 .client-block__rating, .client-block--style3 .client-block__rating {
  display: flex;
  align-items: center;
  color: var(--sec-color);
  margin-bottom: 18px;
  gap: 5px;
}
.client-block--style1 .client-block__text, .client-block--style2 .client-block__text, .client-block--style3 .client-block__text {
  font-size: 22px;
  line-height: 36px;
  margin-bottom: 20px;
  color: var(--title-color);
}
@media (max-width: 1199px) {
  .client-block--style1 .client-block__text, .client-block--style2 .client-block__text, .client-block--style3 .client-block__text {
    font-size: 18px;
  }
}
.client-block--style1 .client-block__name, .client-block--style2 .client-block__name, .client-block--style3 .client-block__name {
  margin-bottom: 0px;
  color: var(--theme-color);
}
.client-block--style1 .client-block__desig, .client-block--style2 .client-block__desig, .client-block--style3 .client-block__desig {
  font-size: 15px;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  color: var(--title-color);
}
.client-block--style1 {
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .client-block--style1 .client-block__img {
    min-height: 391px;
  }
}
@media (max-width: 1199px) {
  .client-block--style1 .client-block__img > img {
    min-height: 391px;
  }
}
@media (max-width: 767px) {
  .client-block--style1 .client-block__content {
    padding: 20px 15px;
  }
}
.client-block--style2 {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  min-width: 283px;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.client-block--style2 .client-block__img {
  max-width: 70px;
  max-height: 70px;
}
.client-block--style2 .client-block__img > img {
  height: 100%;
}
.client-block--style2 .client-block__content {
  padding: 0;
}
.client-block--style2 .client-block__name {
  font-size: 20px;
  margin-bottom: 0;
  transition: all 0.3s ease-in-out;
}
.client-block--style3 {
  box-shadow: none;
}
.client-block--style3 .client-block__content {
  background-color: var(--smoke-color);
  position: relative;
  margin-left: 30px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .client-block--style3 .client-block__content {
    margin-left: 0;
    padding: 15px;
  }
}
.client-block--style3 .client-block__content::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 30px 30px 30px 0;
  border-color: transparent var(--smoke-color) transparent transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
}
.client-block--style3 .client-block__img {
  min-width: 387px;
  min-height: 387px;
  margin-bottom: 30px;
}
@media (max-width: 1199px) {
  .client-block--style3 .client-block__img {
    min-width: 100%;
  }
}
@media (max-width: 991px) {
  .client-block--style3 .client-block__img {
    min-width: 387px;
    max-width: 387px;
    max-height: 387px;
    margin-bottom: 40px;
    margin-left: 100px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .client-block--style3 .client-block__img {
    margin-left: 0px;
    min-width: 100%;
  }
}
.client-block--style3 .client-block__img > img {
  border-radius: 50%;
  min-height: 387px;
}
.client-block--style4 .client-block__img {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  padding: 5px;
  overflow: hidden;
  background-color: var(--border-color);
}
.client-block--style4 .client-block__img > img {
  border-radius: 50%;
}
.client-block--style5 {
  background-color: var(--white-color);
  padding: 0 20px 20px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
.client-block--style5 .client-block__img {
  margin-bottom: 25px;
}
.client-block--style5 .client-block__img > img {
  margin-top: -33px;
  border-radius: 50%;
  padding: 1px;
  border: 1px solid var(--theme-color);
  background-color: var(--theme-color);
  width: 104px;
  height: 104px;
}
.client-block--style5 .client-block__rating {
  margin-bottom: 13px;
}
.client-block--style5 .client-block__rating > i {
  color: var(--sec-color);
  font-size: 18px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}
.client-block--style5 .client-block__text {
  margin-bottom: 14px;
  transition: all 0.3s ease-in-out;
}
.client-block--style5 .client-block__name {
  margin-bottom: 6px;
  color: var(--theme-color);
  transition: all 0.3s ease-in-out;
  font-size: 20px;
}
.client-block--style5 .client-block__desig {
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}
.client-block--style5:hover {
  background-color: var(--theme-color);
  transition: all 0.3s ease-in-out;
  box-shadow: none;
}
.client-block--style5:hover .client-block__text,
.client-block--style5:hover .client-block__name,
.client-block--style5:hover .client-block__desig {
  color: var(--white-color);
}
.client-block--style5:hover .client-block__rating > i {
  color: var(--white-color);
}
.client--layout3 {
  background-color: var(--smoke-color);
}
.client--layout3 .vs-carousel .slick-list {
  padding-top: 33px;
}
.client--layout4 {
  margin-top: -155px;
  padding-top: 268px;
}

.client-active-small .slick-slide.slick-current.slick-active {
  cursor: pointer;
}
.client-active-small .slick-slide.slick-current.slick-active .client-block--style2 {
  background-color: var(--theme-color);
}
.client-active-small .slick-slide.slick-current.slick-active .client-block--style2 .client-block__name,
.client-active-small .slick-slide.slick-current.slick-active .client-block--style2 .client-block__desig {
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.client-active-small.style2 .slick-slide.slick-current.slick-active {
  cursor: pointer;
}
.client-active-small.style2 .slick-slide.slick-current.slick-active .client-block--style2 {
  background-color: var(--theme-color);
}

.testi-style1 {
  position: relative;
}
.testi-style1 .testi-avaters {
  min-width: 387px;
  min-height: 387px;
  position: absolute;
  z-index: 9;
}
@media (max-width: 991px) {
  .testi-style1 .testi-avaters {
    margin-left: 22%;
  }
}
@media (max-width: 767px) {
  .testi-style1 .testi-avaters {
    margin-left: 0px;
    min-width: 90%;
  }
}
.testi-style1 .testi-avaters .tab-btn {
  width: 75px;
  height: 75px;
  padding: 0;
  border: 2px solid var(--border-color);
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  transition: all 0.3s ease-in-out;
}
.testi-style1 .testi-avaters .tab-btn.active {
  border: 2px solid var(--theme-color);
  scale: 1;
}
.testi-style1 .testi-avaters .tab-btn > img {
  min-width: 75px;
  height: 75px;
  object-fit: cover;
  margin-left: -4px;
  margin-top: -4px;
}
.testi-style1 .testi-avaters .tab-btn:nth-child(1) {
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}
.testi-style1 .testi-avaters .tab-btn:nth-child(2) {
  top: 25px;
  left: 15%;
  transform: translateX(-50%);
}
.testi-style1 .testi-avaters .tab-btn:nth-child(3) {
  top: 163px;
  left: 0;
  transform: translateX(-50%);
}
.testi-style1 .testi-avaters .tab-btn:nth-child(4) {
  top: auto;
  bottom: 25px;
  left: 15%;
  transform: translateX(-50%);
}
.testi-style1 .testi-avaters .tab-btn:nth-child(5) {
  top: auto;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}

/*------------------- 4.19. Popup Side Menu  -------------------*/
.sidemenu-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.8s;
}
.sidemenu-wrapper .closeButton {
  display: inline-block;
  border: none;
  color: var(--white-color);
  background-color: var(--theme-color);
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50%;
  transform: rotate(0);
  transition: all ease 0.4s;
}
.sidemenu-wrapper .closeButton:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  transform: rotate(90deg);
}
.sidemenu-wrapper .sidemenu-content {
  background-color: var(--white-color);
  width: 450px;
  margin-left: auto;
  padding: 40px;
  height: 100%;
  overflow: scroll;
  position: relative;
  right: -500px;
  cursor: auto;
  transition-delay: 1s;
  transition: right ease 1s;
}
.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}
.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}
.sidemenu-wrapper .widget {
  padding: 0;
  border: none;
  margin: 0 0 40px 0;
  background-color: transparent;
}
.sidemenu-wrapper .widget_title {
  border-bottom: 1px solid var(--sec-color);
  padding: 0 0 20px 0;
  margin: 0 0 25px 0;
}
.sidemenu-wrapper.show {
  opacity: 1;
  visibility: visible;
  width: 100%;
  transition: all ease 0.8s;
}
.sidemenu-wrapper.show .sidemenu-content {
  right: 0;
  opacity: 1;
  visibility: visible;
}

.offcanvas-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.8s;
}
.offcanvas-wrapper .closeButton {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 20px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 20px;
  padding: 0;
  background-color: var(--theme-color);
  color: var(--white-color);
  border: none;
  border-radius: 50%;
  transform: rotate(0);
  transition: all ease 0.4s;
  z-index: 33;
}
.offcanvas-wrapper .closeButton i {
  line-height: inherit;
}
.offcanvas-wrapper .closeButton:hover {
  color: var(--white-color);
  border-color: transparent;
  transform: rotate(90deg);
  background-color: var(--sec-color);
}
.offcanvas-wrapper .sidemenu-content {
  background-color: var(--white-color);
  width: 450px;
  margin-left: auto;
  padding: 50px 30px;
  height: 100%;
  overflow: scroll;
  position: relative;
  right: -500px;
  cursor: auto;
  transition-delay: 1s;
  transition: right ease 1s;
}
.offcanvas-wrapper .sidemenu-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}
.offcanvas-wrapper .sidemenu-content::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}
.offcanvas-wrapper .widget {
  padding: 0;
  border: none;
  background-color: transparent;
  margin-bottom: 50px;
}
.offcanvas-wrapper .footer-text {
  max-width: 100%;
}
.offcanvas-wrapper.show {
  opacity: 1;
  visibility: visible;
  width: 100%;
  transition: all ease 0.8s;
}
.offcanvas-wrapper.show .sidemenu-content {
  right: 0;
  opacity: 1;
  visibility: visible;
}

/*------------------- 4.20. Price Plan  -------------------*/
.price-block--style1 {
  margin-bottom: 30px;
}
.price-block--style1 .price-block__header {
  background-color: var(--theme-color);
  text-align: center;
  position: relative;
  min-height: 227px;
  padding-top: 40px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__header .shape1,
.price-block--style1 .price-block__header .shape2 {
  position: absolute;
  top: 109px;
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__header .shape1 {
  left: 0;
}
.price-block--style1 .price-block__header .shape2 {
  right: 0;
}
.price-block--style1 .price-block__title {
  font-size: 30px;
  margin-bottom: 10px;
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__price {
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
  color: var(--white-color);
  font-family: var(--title-font);
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__price > sup {
  font-size: 20px;
  top: -22px;
  padding: 0 2.5px;
}
.price-block--style1 .price-block__body {
  background-color: var(--white-color);
  margin: 0 40px;
  padding: 40px 35px;
  margin-top: -52px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  z-index: 1;
  position: relative;
  text-align: center;
}
@media (max-width: 1199px) {
  .price-block--style1 .price-block__body {
    margin: 0 15px;
    padding: 15px;
  }
}
.price-block--style1 .price-block__time {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  border: 1px solid var(--border-color);
  padding: 0 20px;
  font-weight: 500;
  line-height: 26px;
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__time > i {
  color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  padding: 25px 0 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.price-block--style1 .price-block__list > li {
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  gap: 10px;
  text-align: left;
  font-weight: 500;
  color: var(--body-color);
}
.price-block--style1 .price-block__list > li > i {
  color: var(--body-color);
}
.price-block--style1 .price-block__list > li.active {
  color: var(--title-color);
}
.price-block--style1 .price-block__list > li.active > i {
  color: var(--theme-color);
}
.price-block--style1 .vs-btn {
  clip-path: none;
  background-color: var(--title-color);
  width: 100%;
  justify-content: center;
}
.price-block--style1 .vs-btn::before, .price-block--style1 .vs-btn::after {
  background-color: var(--theme-color);
}
.price-block--style1:hover .price-block__header {
  background-color: var(--sec-color);
  transition: all 0.3s ease-in-out;
}
.price-block--style1:hover .price-block__time {
  background-color: var(--sec-color);
  border-color: var(--sec-color);
  color: var(--white-color);
}
.price-block--style1:hover .price-block__time > i {
  color: var(--white-color);
}

/*------------------- 4.21. Forms Style  -------------------*/
.form-style1,
.form-style2 {
  padding: 60px 60px 40px 60px;
}
@media (max-width: 1199px) {
  .form-style1,
  .form-style2 {
    padding: 50px 30px 30px 30px;
  }
}
@media (max-width: 991px) {
  .form-style1,
  .form-style2 {
    padding: 0px 30px 30px 30px;
  }
}
@media (max-width: 767px) {
  .form-style1,
  .form-style2 {
    padding: 20px;
  }
}
.form-style1 .nice-select,
.form-style2 .nice-select {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
}
.form-style1 .nice-select::after,
.form-style2 .nice-select::after {
  border-bottom: none;
  border-right: none;
  height: auto;
  margin-top: 0;
  right: 20px;
  transform: rotate(0deg) translateY(-50%);
  width: auto;
  content: "\f0d7";
  font-family: var(--icon-font);
  font-weight: 700;
  color: var(--theme-color);
  font-size: 20px;
}
.form-style1 .nice-select .list,
.form-style2 .nice-select .list {
  z-index: 9;
  width: 100%;
}
.form-style1 .form-group > i,
.form-style2 .form-group > i {
  right: calc(var(--bs-gutter-x) / 2 + 20px);
  color: var(--theme-color);
}
.form-style1 .form-control,
.form-style2 .form-control {
  padding: 15px 20px;
}
.form-style1 .nice-select,
.form-style1 .form-control,
.form-style2 .nice-select,
.form-style2 .form-control {
  border: 1px solid var(--border-color);
  border-radius: 0;
  font-size: 14px;
  color: var(--body-color);
}

.form-style1 .sec-title {
  font-size: 30px;
}
@media (max-width: 991px) {
  .form-style1 .sec-title {
    color: var(--white-color);
  }
}

.form-control[type=date]::-webkit-inner-spin-button,
.form-control[type=date]::-webkit-calendar-picker-indicator,
.form-control[type=time]::-webkit-calendar-picker-indicator {
  opacity: 0;
  width: 100px;
  height: 50px;
  cursor: pointer;
  margin-right: -20px;
  position: relative;
  z-index: 9;
}

.form-style2 {
  background-color: var(--white-color);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.07);
  padding: 60px;
}
@media (max-width: 991px) {
  .form-style2 {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .form-style2 {
    padding: 20px;
  }
}
.form-style2 .form-group {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .form-style2 .form-group {
    margin-bottom: 20px;
  }
}
.form-style2 .form-group:last-child {
  margin-top: 10px;
  margin-bottom: 0;
}
.form-style2 .form-control {
  background-color: var(--smoke-color);
  border-color: var(--smoke-color);
}
.form-style2 .form-control.nice-select {
  display: flex;
  align-items: center;
}

.form-style3 {
  background-color: var(--smoke-color);
  padding: 50px 60px;
}
@media (max-width: 991px) {
  .form-style3 {
    padding: 20px;
  }
}
.form-style3 .sec-title {
  font-size: 40px;
  margin-bottom: 30px;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .form-style3 .sec-title {
    font-size: 30px;
  }
}
.form-style3 .form-group {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .form-style3 .form-group {
    margin-bottom: 20px;
  }
}
.form-style3 .form-group:last-child {
  margin-top: 10px;
  margin-bottom: 0;
}
.form-style3 .form-control {
  padding: 15px 20px;
  color: var(--body-color);
  border: 1px solid var(--white-color);
}
.form-style3 .form-control::placeholder {
  color: var(--body-color);
}
.form-style3 .vs-btn {
  padding: 17px 30px;
}

.form-style4 {
  position: relative;
  margin-bottom: 30px;
}
.form-style4 .form-control {
  height: 60px;
}
.form-style4 .vs-btn {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  padding: 17px 20px;
}

/*------------------- 4.22. Projects  -------------------*/
.project .tab-content {
  min-height: 636px;
}
.project__tab {
  margin-bottom: 60px;
  text-align: center;
}
.project__tab .nav-tabs,
.project__tab .filter-menu1 {
  border: 1px solid var(--white-color);
  display: inline-flex;
  padding: 15px;
  gap: 10px;
}
.project__tab .nav-tabs .nav-link,
.project__tab .nav-tabs button,
.project__tab .filter-menu1 .nav-link,
.project__tab .filter-menu1 button {
  border-radius: 0;
  padding: 0 30px;
  background-color: var(--smoke-color);
  color: var(--title-color);
  font-size: 16px;
  font-weight: 700;
  min-height: 50px;
  font-family: var(--title-font);
  border: 0;
}
.project__tab .nav-tabs .nav-link.active,
.project__tab .nav-tabs button.active,
.project__tab .filter-menu1 .nav-link.active,
.project__tab .filter-menu1 button.active {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--white-color);
}
.project__tab.style2 .nav-tabs {
  padding: 0;
  border: 0;
  gap: 30px;
}
.project__tab.style2 .nav-link {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  min-height: 80px;
  justify-content: center;
  font-size: 20px;
  border: 1px solid var(--theme-color);
  position: relative;
}
.project__tab.style2 .nav-link::before {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 20px 10px 0 10px;
  border-color: var(--theme-color) transparent transparent transparent;
  transform: rotate(0deg) translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  bottom: -19px;
  left: 50%;
  transition: all 0.3s ease-in-out;
}
.project__tab.style2 .nav-link > img {
  filter: none;
  transition: all 0.3s ease-in-out;
}
.project__tab.style2 .nav-link.active {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}
.project__tab.style2 .nav-link.active::before {
  opacity: 1;
  visibility: visible;
}
.project__tab.style2 .nav-link.active > img {
  filter: brightness(0) invert(1);
  transition: all 0.3s ease-in-out;
}
.project__tab.style3 .nav-tabs,
.project__tab.style3 .filter-menu1 {
  border-color: var(--theme-color);
  flex-wrap: wrap;
}
.project--layout2 {
  padding-bottom: 235px;
  background-attachment: fixed;
}
.project-block {
  margin-bottom: 30px;
  position: relative;
}
.project-block__img {
  position: relative;
}
.project-block__img::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0%;
  background: linear-gradient(180deg, rgba(1, 19, 60, 0) 0%, rgba(1, 19, 60, 0.71) 25%, #01133c 100%);
  bottom: 0;
  transition: all 0.3s ease-in-out;
}
.project-block__img::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 6px;
  background-color: var(--sec-color);
  bottom: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.project-block__content {
  position: absolute;
  bottom: 30px;
  left: 0;
  padding: 36px 30px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.project-block__title > a {
  color: var(--white-color);
  display: inline-block;
}
.project-block__title > a:hover {
  color: var(--sec-color);
}
.project-block__title {
  font-size: 30px;
}
.project-block:hover .project-block__content {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}
.project-block:hover .project-block__img::before {
  height: 100%;
}
.project-block:hover .project-block__img::after {
  opacity: 1;
  visibility: visible;
  width: 100%;
}

.tab-content.style2 {
  background-color: var(--white-color);
  padding: 50px 50px 20px;
}
@media (max-width: 767px) {
  .tab-content.style2 {
    padding: 20px;
  }
}

.twentytwenty-handle {
  height: 60px;
  width: 60px;
  margin-left: -32px;
  margin-top: -30px;
  border: 3px solid var(--theme-color);
  background: var(--theme-color);
  cursor: pointer;
}

.twentytwenty-horizontal .twentytwenty-handle:before,
.twentytwenty-horizontal .twentytwenty-handle:after,
.twentytwenty-vertical .twentytwenty-handle:before,
.twentytwenty-vertical .twentytwenty-handle:after {
  background: var(--theme-color);
  box-shadow: none;
}

.details-content {
  background-color: var(--smoke-color);
  padding: 50px 40px;
  margin-top: -84px;
  margin-bottom: 55px;
}
@media (max-width: 767px) {
  .details-content {
    padding: 20px 10px;
    margin-top: 0;
  }
}
.details-content p {
  color: var(--black-color);
}
.details-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  column-count: 3;
  column-gap: 20px;
  margin: -2.5px;
}
@media (max-width: 1199px) {
  .details-list {
    column-count: 2;
  }
}
@media (max-width: 767px) {
  .details-list {
    column-count: 1;
  }
}
.details-list > li {
  display: inline-flex;
  align-items: center;
  gap: 30px;
  padding: 2.5px;
}
.details-list > li .star-rating::before {
  color: var(--theme-color);
}
.details-list__name {
  font-size: 20px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--black-color);
  min-width: 85px;
}
.details-list__span {
  font-size: 16px;
  font-family: var(--body-font);
  color: var(--title-color);
  font-weight: 400;
}

.filter-active {
  padding: 0 5px;
}

.filter-item {
  width: 283px;
  height: 420px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .filter-item {
    width: 100%;
  }
}

.grid-item--width2 {
  width: 594px;
}
@media (max-width: 767px) {
  .grid-item--width2 {
    width: 100%;
  }
}

.grid-item--height2 {
  height: 420px;
}
@media (max-width: 767px) {
  .grid-item--height2 {
    height: 248px;
  }
}

/*------------------- 4.23. Contact  -------------------*/
.social-style1.style3 {
  display: flex;
  align-items: center;
  padding-top: 24px;
}
.social-style1.style3 .title {
  font-size: 30px;
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 700;
  margin-right: 20px;
}
@media (max-width: 991px) {
  .social-style1.style3 .title {
    font-size: 18px;
  }
}
.social-style1.style3 a {
  background-color: var(--smoke-color);
  color: var(--title-color);
  border-color: var(--smoke-color);
}
.social-style1.style3 a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

/*------------------- 4.24. Event  -------------------*/
/*------------------- 4.25. CTA  -------------------*/
/*------------------- 4.26. Newsletter  -------------------*/
.newsletter--layout1 {
  padding: 115px 0 85px;
}
@media (max-width: 767px) {
  .newsletter--layout1 {
    padding: 80px 0 55px;
  }
}
.newsletter--layout1 .overlay {
  background-color: var(--theme-color);
  opacity: 0.85;
  z-index: -1;
}

.subscribe-block--style1 {
  position: relative;
}
.subscribe-block--style1 .subscribe-block__field {
  height: 70px;
}
.subscribe-block--style1 .vs-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/*------------------- 4.26. Newsletter  -------------------*/
.vs-sort-bar {
  margin-bottom: 60px;
}
.vs-sort-bar select {
  height: 50px;
  border: 1px solid #eee;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: auto;
  padding: 0 45px 0 25px;
  font-size: 16px;
  margin: 0;
  background-position: right 22px center;
}
.vs-sort-bar label {
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 15px;
  font-weight: 500;
  line-height: 1;
}
.vs-sort-bar p.woocommerce-result-count {
  margin-bottom: 0;
}
.vs-sort-bar .nice-select {
  border: none;
  width: 100%;
  min-width: 250px;
  padding-right: 30px;
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  background-color: var(--smoke-color);
  font-size: 16px;
  font-family: var(--body-font);
  /* Small devices */
}
.vs-sort-bar .nice-select .list {
  width: 100%;
  border-radius: 0;
  margin-top: 0px;
  border: 1px solid var(--smoke-color);
  box-shadow: none;
}
.vs-sort-bar .nice-select::after {
  border-bottom: 2px solid var(--body-color);
  border-right: 2px solid var(--body-color);
  height: 10px;
  margin-top: -6px;
  right: 24px;
  width: 10px;
}
@media (max-width: 767px) {
  .vs-sort-bar .nice-select {
    margin-left: auto;
    margin-right: auto;
  }
}

/*------------------- 4.26. Product  -------------------*/
.woocommerce-message,
.woocommerce-info {
  position: relative;
  border: 1px solid var(--sec-color);
  padding: 11px 20px;
  background-color: var(--smoke-color);
  color: var(--title-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  border-radius: 10px;
  font-family: var(--title-font);
}
.woocommerce-message a,
.woocommerce-info a {
  color: inherit;
  text-decoration: underline;
}
.woocommerce-message a:hover,
.woocommerce-info a:hover {
  text-decoration: underline;
}
.woocommerce-message:before,
.woocommerce-info:before {
  content: "\f06a";
  font-family: var(--icon-font);
  font-weight: 900;
  margin-right: 10px;
}

.woocommerce-notices-wrapper .woocommerce-message {
  background-color: var(--smoke-color);
  color: var(--white-color);
  border-color: transparent;
  border-radius: 0;
  font-size: 16px;
  padding: 11px 20px;
  font-family: var(--title-font);
  margin-bottom: 40px;
}
.woocommerce-notices-wrapper .woocommerce-message:before {
  content: "\f14a";
  font-weight: 300;
  top: 1px;
  position: relative;
}

.woocommerce-Reviews .vs-comments-wrap {
  margin-top: 0;
}
.woocommerce-Reviews .star-rating:before {
  display: block;
}

.quantity {
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;
}
.quantity > label {
  font-size: 14px;
  color: var(--body-color);
  font-weight: 700;
  margin: 0 20px 0 0;
  font-family: var(--body-font);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.quantity input {
  padding: 0 10px 0 10px;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  --body-color: var(--title-color);
  border: 1px solid var(--smoke-color);
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.rating-select {
  margin-top: -0.4em;
}
.rating-select label {
  margin: 0 10px 0 0;
  display: inline-block;
}
.rating-select p.stars {
  margin-bottom: 0;
  line-height: 1;
  display: inline-block;
}
.rating-select p.stars a {
  position: relative;
  height: 14px;
  width: 18px;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none;
}
@media (max-width: 991px) {
  .rating-select p.stars a {
    width: 14px;
  }
}
.rating-select p.stars a::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 14px;
  line-height: 1;
  font-family: var(--icon-font);
  content: "\f005";
  font-weight: 400;
  text-indent: 0;
  color: var(--yellow-color);
}
.rating-select p.stars a:hover ~ a::before {
  content: "\f005";
  font-weight: 400;
}
.rating-select p.stars:hover a::before {
  content: "\f005";
  font-weight: 700;
}
.rating-select p.stars.selected a.active::before {
  content: "\f005";
  font-weight: 700;
}
.rating-select p.stars.selected a.active ~ a::before {
  content: "\f005";
  font-weight: 400;
}
.rating-select p.stars.selected a:not(.active)::before {
  content: "\f005";
  font-weight: 700;
}

.woocommerce-message,
.woocommerce-info {
  position: relative;
  border: 1px solid transparent;
  padding: 16px 20px;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  border-radius: 0;
}

.woocommerce-message a,
.woocommerce-info a {
  color: inherit;
  text-decoration: underline;
}

.woocommerce-message a:hover,
.woocommerce-info a:hover {
  text-decoration: underline;
}

.woocommerce-message:before,
.woocommerce-info:before {
  content: "\f06a";
  font-family: var(--icon-font);
  font-weight: 900;
  margin-right: 10px;
}

.woocommerce-notices-wrapper .woocommerce-message {
  background-color: var(--theme-color);
}

.woocommerce-notices-wrapper .woocommerce-message:before {
  content: "\f14a";
  font-weight: 300;
}

.woocommerce-form-login-toggle .woocommerce-info {
  background-color: var(--theme-color);
}

.woocommerce-form-coupon,
.woocommerce-form-login {
  padding: 40px;
  background-color: var(--smoke-color);
  margin-bottom: 40px;
  display: none;
}
.woocommerce-form-coupon .form-control,
.woocommerce-form-login .form-control {
  border: 1px solid var(--border-color);
  transition: all 0.3s ease-in-out;
}
.woocommerce-form-coupon .form-control::placeholder,
.woocommerce-form-login .form-control::placeholder {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
}
.woocommerce-form-coupon .form-control:focus,
.woocommerce-form-login .form-control:focus {
  border-color: var(--sec-color);
  transition: all 0.3s ease-in-out;
}
.woocommerce-form-coupon .form-control:focus::placeholder,
.woocommerce-form-login .form-control:focus::placeholder {
  opacity: 0;
  visibility: hidden;
}

.woocommerce-form-coupon .form-group,
.woocommerce-form-login .form-group {
  margin-bottom: 25px;
}

.woocommerce-form-coupon .form-group:last-child,
.woocommerce-form-login .form-group:last-child {
  margin-bottom: 0;
}

.woocommerce-error {
  background-color: var(--error-color);
  color: #fff;
  list-style: none;
  padding: 10px 26px;
  margin: 0 0 30px 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}

nav.woocommerce-MyAccount-navigation li {
  border: 1px solid #ddd;
  margin: 0;
  border-top: none;
}

nav.woocommerce-MyAccount-navigation li:first-child {
  border-top: 1px solid #ddd;
}

nav.woocommerce-MyAccount-navigation li a {
  color: var(--title-color);
  font-weight: 700;
  padding: 7px 17px;
  display: block;
}

nav.woocommerce-MyAccount-navigation li.is-active a,
nav.woocommerce-MyAccount-navigation li a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.woocommerce-MyAccount-content h3 {
  margin-top: -0.3em;
}

.woocommerce-MyAccount-content .btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 14px;
  padding: 10px 25px;
  font-weight: 700;
}

.woocommerce-MyAccount-content .btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

table.variations,
.woocommerce-grouped-product-list-item {
  border-collapse: separate;
  border-spacing: 0 15px;
  margin-bottom: 5px;
  align-items: center;
}

table.variations td,
.woocommerce-grouped-product-list-item td {
  border: none;
  vertical-align: middle;
  padding: 0 5px;
}

table.variations td:first-child,
.woocommerce-grouped-product-list-item td:first-child {
  padding: 0;
}

table.variations label,
.woocommerce-grouped-product-list-item label {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}

table.variations label a,
.woocommerce-grouped-product-list-item label a {
  color: var(--title-color);
}

table.variations label a:hover,
.woocommerce-grouped-product-list-item label a:hover {
  color: var(--theme-color);
}

.woocommerce-product-attributes th,
.woocommerce-product-attributes td {
  border: 1px solid var(--border-color);
}

.woocommerce-product-attributes th p:last-child,
.woocommerce-product-attributes td p:last-child {
  margin-bottom: 0;
}

.woocommerce-grouped-product-list.group_table {
  border-collapse: collapse;
  margin-bottom: 15px;
}

.woocommerce-grouped-product-list.group_table .woocommerce-Price-amount.amount {
  font-size: 16px;
  color: var(--title-color);
}

.woocommerce-grouped-product-list.group_table label {
  margin: 0 0 0 10px;
  margin: 0 0 0 10px;
  font-family: var(--title-font);
  font-size: 18px;
}

.woocommerce-grouped-product-list.group_table .qty-input {
  border-color: #e3e6e9;
}

.woocommerce-grouped-product-list.group_table tr {
  border-bottom: 1px solid #e3e6e9;
}

.woocommerce-grouped-product-list.group_table tr:last-child {
  border-bottom: none;
}

.woocommerce-grouped-product-list.group_table td {
  padding: 30px 5px;
}

table.variations {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
}

table.variations td {
  padding: 0;
}

table.variations td.label {
  padding-right: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

table.variations select {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  margin: 0;
  padding-right: 54px;
  padding-left: 20px;
  height: 50px;
}

table.variations .reset_variations {
  margin-left: 16px;
  display: inline-block;
  position: absolute;
  left: 100%;
  bottom: 25px;
}

.woocommerce-variation.single_variation {
  margin-bottom: 30px;
}

.woocommerce-variation.single_variation .price {
  color: var(--title-color);
  font-weight: 700;
}

/*------------------- 4.26. Newsletter  -------------------*/
.vs-product {
  background-color: var(--white-color);
  border: 2px solid var(--border-color);
  margin-bottom: 30px;
}
.vs-product .product-img {
  background-color: var(--border-color);
  max-height: 225px;
  position: relative;
}
.vs-product .product-img > a {
  display: inline-block;
  width: 100%;
}
.vs-product .product-img > a > img, .vs-product .product-img > img,
.vs-product .product-img img {
  max-height: 225px;
  width: 100%;
  object-fit: cover;
}
.vs-product .product-img .star-ratings {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  list-style: none;
  position: absolute;
  bottom: 10px;
  left: 10px;
  min-height: 30px;
  padding: 0 15px;
  background-color: var(--sec-color);
  color: var(--white-color);
  font-size: 14px;
  display: none;
}
.vs-product .product-img .star-ratings > li {
  display: inline-flex;
  align-items: center;
}
.vs-product .product-img .star-ratings > li > span {
  font-weight: 800;
  letter-spacing: 0.6px;
  margin-right: 6px;
  padding-top: 1px;
}
.vs-product .product-content {
  padding: 21px 27px 27px;
}
.vs-product .product-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 14px;
}
.vs-product .product-title > a {
  display: inline-block;
}
.vs-product .product-price {
  display: block;
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 24px;
  color: var(--theme-color);
}
.vs-product .product-price > del {
  font-size: 16px;
  color: var(--title-color);
}
.vs-product .actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.vs-product .icon-btn {
  width: var(--btn-size, 50px);
  height: var(--btn-size, 50px);
  line-height: var(--btn-size, 50px);
  font-size: var(--btn-font-size, 16px);
  background-color: var(--title-color);
  border-radius: 0%;
  color: var(--white-color);
  border-color: var(--title-color);
}
.vs-product .icon-btn::before {
  background-color: var(--theme-color);
}
.vs-product .icon-btn:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.vs-product .vs-btn {
  background-color: var(--title-color);
  flex: auto;
  justify-content: center;
}
.vs-product .vs-btn::before, .vs-product .vs-btn::after {
  background-color: var(--theme-color);
}

.product-about {
  padding-right: 3%;
}

.product_meta > span {
  display: flex;
  margin-bottom: 10px;
  color: var(--theme-color);
  font-weight: 700;
}
.product_meta > span > p {
  margin-bottom: 0;
  color: var(--title-color);
  font-size: 16px;
  font-weight: 700;
  min-width: 150px;
  font-family: var(--title-font);
}

.product-about,
.product-slide-row {
  margin-bottom: 30px;
}

.product-about .product-rating {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin: 0 0 10px 0;
}
@media (max-width: 767px) {
  .product-about .product-rating {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.product-about .product-rating__total {
  color: var(--body-color);
  font-size: 16px;
  font-weight: 400;
  font-family: var(--body-font);
}

.product-about .product-rating .star-rating {
  margin-right: 10px;
  font-size: 16px;
  width: 104px;
}

.product-about .product-title {
  font-size: 36px;
  margin-bottom: 13px;
  font-weight: 800;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .product-about .product-title {
    font-size: 28px;
    margin-bottom: 25px;
  }
}

.quantity {
  display: flex;
  align-items: center;
  gap: 21px;
  flex-wrap: wrap;
}
.quantity .screen-reader-text {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--title-font);
}
.quantity__field {
  display: flex;
  align-items: center;
  border: 0px solid var(--smoke-color);
  background-color: var(--smoke-color);
  min-width: 150px;
  padding-right: 0px;
  position: relative;
  min-height: 50px;
}
.quantity__buttons {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: row-reverse;
}

.product-about .qty-input {
  border: 0;
  padding: 0 50px;
  min-height: 50px;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--black-color);
  text-align: center;
  background-color: var(--smoke-color);
}
.product-about .product-instock {
  display: inline-flex;
  align-items: center;
  color: var(--title-color);
  font-size: 24px;
  font-family: var(--title-font);
  font-weight: 700;
  gap: 10px;
  margin-bottom: 30px;
}
.product-about .product-instock > p {
  margin-bottom: 0;
  color: var(--title-color);
  min-width: 150px;
}
.product-about .product-instock > span {
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  font-family: var(--body-font);
  color: var(--theme-color);
  gap: 5px;
  font-weight: 400;
}
.product-about .product-instock > span > i {
  color: var(--sec-color);
}
.product-about .star-rating:before,
.product-about .star-rating span:before {
  color: var(--sec-color);
  transform: translate(0%, 0%);
  font-weight: 700;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
}

.product-big-img {
  background-color: #f1f1f1;
  border: 1px solid rgba(203, 203, 203, 0.3);
  margin-bottom: 20px;
  text-align: center;
  border-radius: 0px;
}
.product-big-img img {
  width: 100%;
}

.product-thumb-slide {
  margin: 0 -10px;
}
@media (max-width: 767px) {
  .product-thumb-slide {
    max-width: 100%;
  }
}
.product-thumb-slide.row {
  --bs-gutter-x: 20px;
}
.product-thumb-slide .thumb {
  background-color: #f1f1f1;
  overflow: hidden;
  transition: all ease 0.4s;
  border: 1px solid rgba(203, 203, 203, 0.3);
  cursor: pointer;
  border-radius: 0px;
  margin: 0 10px;
}
@media (max-width: 767px) {
  .product-thumb-slide .thumb {
    margin-bottom: 0;
    margin: 0 7.5px;
  }
}
.product-thumb-slide .thumb img {
  transition: all ease 0.4s;
  width: 100%;
}
.product-thumb-slide .thumb:hover {
  border-color: var(--theme-color);
}
.product-thumb-slide .slick-current .thumb {
  border-color: var(--theme-color);
}

.qty-btn {
  background: var(--theme-color);
  border: 0;
  padding: 0;
  line-height: 1;
  display: inline-flex;
  margin: -4px 0;
  color: var(--white-color);
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.qty-btn:hover {
  background-color: var(--sec-color);
}

.product-about .product-price {
  font-size: 24px;
  font-weight: 700;
  color: var(--theme-color);
  line-height: 1;
  margin: 0 0 25px 0;
  font-family: var(--title-font);
}

.product-about .product-price del {
  font-size: 16px;
  font-weight: 700;
  color: var(--body-color);
}

.product-about .product-text {
  margin-bottom: 24px;
}

.product-about .actions {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
  align-items: center;
}

.product-about .actions .vs-btn {
  padding: 17px 55px;
  min-height: 50px;
  clip-path: none;
  flex: auto;
  background: var(--theme-color);
}

.product-about .actions .vs-btn::after,
.product-about .actions .vs-btn::before {
  background-color: var(--sec-color);
}

.product-about .actions .icon-btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  border: 1px solid var(--silverLight-color);
  font-size: 20px;
  width: var(--btn-size, 50px);
  height: var(--btn-size, 50px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}

.product-about .actions .icon-btn::before {
  background-color: var(--sec-color);
  --border-size: 0;
}

.product-about .actions .icon-btn:hover {
  border-color: var(--sec-color);
  color: var(--white-color);
  background-color: var(--sec-color);
}

.product-about .product-getway {
  padding-top: 30px;
}

.product-about .getway-title {
  font-size: 14px;
  color: var(--black-color);
  font-weight: 700;
  margin: -0.15em 0 15px 0;
  line-height: 1;
  font-family: var(--body-font);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  display: block;
}

.product_meta > span:last-child {
  margin-bottom: 0;
}

.product_meta > span a {
  color: inherit;
}

.product_meta > span a:hover {
  color: var(--theme-color);
}

.product_meta > span > a,
.product_meta > span > span {
  margin-bottom: 0;
  color: var(--theme-color);
  font-size: 16px;
  font-weight: 400;
  font-family: var(--body-font);
}

.product_meta > span > a {
  color: var(--theme-color);
}
.product_meta > span > a:hover {
  color: var(--sec-color);
}

.product_meta > span > a:first-child,
.product_meta > span > span:first-child {
  margin-left: 5px;
}

.product_meta > span > a:not(:last-child)::after,
.product_meta > span > span:not(:last-child)::after {
  content: ",";
  margin-right: 3px;
}

.product-description {
  background: var(--white-color);
  border: 1px solid var(--border-color);
  box-shadow: 0px 0px 48px rgba(0, 70, 226, 0.07);
  margin: var(--blog-space-y, 30px) 0 30px 0;
}
.product-description .description .comment-list {
  margin: 0 0 0 0;
}
.product-description .product_meta > span > p {
  font-size: 16px;
}
.product-description__tab .nav {
  border: none;
  background-color: var(--smoke-color);
  padding: 0px 25px 0 25px;
}
@media (max-width: 767px) {
  .product-description__tab .nav .nav-item {
    width: 100%;
  }
}
.product-description__tab .nav .nav-link {
  background: transparent;
  font-size: 18px;
  font-weight: 800;
  color: var(--black-color);
  font-family: var(--title-font);
  padding: 17px 39px 17px 39px;
  border: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
.product-description__tab .nav .nav-link .product-description__tab .nav .nav-link:hover {
  color: var(--theme-color);
}
@media (max-width: 991px) {
  .product-description__tab .nav .nav-link {
    padding: 17px 19px 17px 19px;
  }
}
@media (max-width: 767px) {
  .product-description__tab .nav .nav-link {
    width: 100%;
    padding: 17px 0px 17px 0;
    font-size: 16px;
  }
}
.product-description__tab .nav .nav-link::before, .product-description__tab .nav .nav-link::after {
  position: absolute;
  content: "";
  transition: all ease 0.4s;
}
.product-description__tab .nav .nav-link::before {
  width: 0%;
  min-height: 100%;
  background-color: var(--white-color);
  bottom: 0px;
  left: auto;
  right: 0;
  z-index: -1;
}
.product-description__tab .nav .nav-link::after {
  display: none;
}
.product-description__tab .nav .nav-link.active {
  color: var(--theme-color);
}
.product-description__tab .nav .nav-link.active::before {
  width: 100%;
  left: 0;
  right: 0;
}
.product-description__tab .nav .nav-item:last-child .nav-link::after {
  display: none;
}

.star-rating {
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 1.2em;
  line-height: 1.2em;
  display: block;
  font-family: var(--icon-font);
  font-weight: 700;
  font-size: 14px;
}

.star-rating:before {
  content: "\f005\f005\f005\f005\f005";
  color: var(--yellow-color);
  float: left;
  top: 50%;
  left: 50%;
  position: absolute;
  letter-spacing: 3px;
  font-weight: 700;
  transform: translate(-50%, -50%);
}

.star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}

.star-rating span:before {
  content: "\f005\f005\f005\f005\f005";
  position: absolute;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  color: var(--yellow-color);
  letter-spacing: 3px;
  font-weight: 700;
}

.list-style2 li {
  color: var(--charcoalGreyDeep-color);
  font-weight: 400;
}
.list-style2 li::before {
  margin-top: 7px;
  margin-right: 15px;
}

.product-information__item {
  background-color: #f5f5f5;
  min-height: 66px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--black-color);
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .product-information__item {
    font-size: 16px;
  }
}
.product-information__item:last-child {
  margin-bottom: 0;
}
.product-information__name {
  color: var(--theme-color);
  background-color: var(--white-color);
  min-height: 66px;
  border: 1px solid var(--theme-color);
  display: inline-flex;
  align-items: center;
  min-width: 310px;
  padding: 10px 47px;
  margin-right: 24%;
}
@media (max-width: 991px) {
  .product-information__name {
    min-width: 180px;
    padding: 10px 15px;
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .product-information__name {
    min-width: 140px;
  }
}

.woocommerce-reviews,
.description {
  padding: 40px 40px;
}

.description .product_meta {
  margin-bottom: 15px;
}
.description .text:last-child {
  margin-bottom: 0;
}

.woocommerce-reviews .blog-inner-title {
  margin-bottom: 14px;
}
.woocommerce-reviews .rating-select {
  margin-bottom: 35px;
}
.woocommerce-reviews .vs-comments-wrap {
  margin: 0;
  margin-bottom: 40px;
}
.woocommerce-reviews .vs-comments-wrap ul.comment-list {
  margin: 0 0 0 0;
}
.woocommerce-reviews .vs-comments-wrap .vs-comment-item {
  margin-bottom: 30px;
}
.woocommerce-reviews .vs-comments-wrap .vs-comment-item:last-child {
  margin-bottom: 0;
}
.woocommerce-reviews .comment-respond {
  margin-bottom: 0;
}
.woocommerce-reviews .comment-respond .vs-btn {
  width: 100%;
  background-color: var(--theme-color);
}
.woocommerce-reviews .comment-respond .vs-btn::before, .woocommerce-reviews .comment-respond .vs-btn::after {
  background-color: var(--sec-color);
}

/*------------------- 4.26. Newsletter  -------------------*/
.app-container {
  background-color: var(--smoke-color);
  position: relative;
  z-index: 3;
  margin-top: -155px;
}
.app-container.style0 {
  margin-top: 0;
  margin-bottom: 0;
}
.app-container.style2 {
  margin-top: 0;
  margin-bottom: -154px;
}
.app-container-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  width: 50%;
  z-index: -1;
}
@media (max-width: 991px) {
  .app-container-img {
    width: 100%;
    height: 100%;
  }
}
.app-container-img::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--title-color);
  opacity: 0.85;
  position: absolute;
  top: 0;
  left: 0;
}
.app-container-img.style2::before {
  background-color: var(--theme-color);
}
@media (max-width: 991px) {
  .app-container .vs-btn {
    background-color: var(--theme-color);
  }
}
.app-content {
  padding: 65px 125px 65px 65px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .app-content {
    padding: 40px 20px 0;
  }
}
.app-content .sec-title {
  font-size: 40px;
  margin-bottom: 35px;
}
@media (max-width: 1199px) {
  .app-content .sec-title {
    font-size: 30px;
  }
}
@media (max-width: 991px) {
  .app-content .sec-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .app-content .sec-title {
    font-size: 24px;
  }
}

.call-block {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: var(--white-color);
  font-weight: 600;
  font-family: var(--title-font);
  gap: 15px;
  font-size: 24px;
}
@media (max-width: 767px) {
  .call-block {
    font-size: 20px;
  }
}
.call-block > i {
  min-width: 60px;
  min-height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  border-radius: 0%;
  color: var(--theme-color);
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}
.call-block > span {
  display: inline-block;
  position: relative;
}
.call-block > span::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 1.5px;
  bottom: 0;
  background-color: var(--white-color);
  transition: all 0.3s ease-in-out;
  right: 0;
  transform: translateX(-15px);
}
.call-block:hover {
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.call-block:hover i {
  background-color: var(--theme-color);
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.call-block:hover span::before {
  width: 100%;
  left: 0;
  transform: translateX(0px);
  transition: all 0.3s ease-in-out;
}

/*------------------- 4.26. Newsletter  -------------------*/
.brand-block {
  border: 1px solid var(--theme-color);
  margin-bottom: 30px;
  display: inline-block;
}
.brand--layout2 {
  margin-top: -115px;
  position: relative;
  z-index: 1;
}

/*------------------- 4.26. Newsletter  -------------------*/
.faq--layout1 {
  background-color: var(--smoke-color);
}

/*------------------- 4.26. Newsletter  -------------------*/
.cart_table {
  border: 1px solid var(--smoke-color);
  margin-bottom: 45px;
}

.cart_table thead {
  background-color: var(--smoke-color);
}

.cart_table thead th {
  border: none !important;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 26.75px 15px;
}

.cart_table td:before,
.cart_table th {
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 600;
  border: none;
  padding: 27px 15px;
}

.cart_table td:before {
  content: attr(data-title);
  position: absolute;
  left: 15px;
  top: 50%;
  vertical-align: top;
  padding: 0;
  transform: translateY(-50%);
  display: none;
}

.cart_table td {
  border: none;
  border-bottom: 1px solid var(--smoke-color);
  color: var(--body-color);
  padding: 20px 10px;
  position: relative;
  vertical-align: middle;
  text-align: center;
}

.cart_table .product-quantity {
  color: var(--title-color);
}

.cart_table .product-quantity input {
  position: relative;
  top: -2px;
}

.cart_table .cart-productname {
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
}
.cart_table .cart-productname:hover {
  color: var(--sec-color);
}

.cart_table .cart-productimage {
  display: inline-block;
}
.cart_table .cart-productimage > img {
  width: 100px;
  height: 95px;
  object-fit: cover;
}

.cart_table .remove {
  color: var(--theme-color);
  font-size: 18px;
}
.cart_table .remove:hover {
  color: var(--sec-color);
}

.cart_table .quantity {
  display: inline-flex;
  align-items: center;
}

.quantity.style2 .quantity__field {
  background-color: transparent;
}
.quantity.style2 .qty-btn {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 1px solid var(--smoke-color);
  border-radius: 4px;
  color: var(--body-color);
}
.quantity.style2 .qty-btn:hover {
  background-color: var(--sec-color);
  color: var(--white-color);
  border-color: var(--white-color);
}

.cart_table .qut-btn {
  border: 2px solid var(--smoke-color);
  background-color: rgba(0, 0, 0, 0);
  color: #b8c6d0;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 25px;
  font-size: 16px;
  border-radius: 4px;
}

.cart_table .qut-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.cart_table .qty-input {
  vertical-align: middle;
  border: 1px solid var(--smoke-color);
  width: 70px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  color: var(--title-color);
  font-family: var(--body-font);
  font-weight: 400;
  margin: 0 10px;
  border-radius: 4px;
}

.cart_table .actions {
  text-align: right;
  vertical-align: middle;
  padding: 20px;
}

.cart_table .actions > .vs-btn {
  font-size: 16px;
  padding: 17px 28px;
  margin-right: 15px;
}

.cart_table .actions > .vs-btn:last-child {
  margin-right: 0;
}

.cart_table .vs-cart-coupon {
  float: left;
  margin: 0;
  width: 455px;
  max-width: 100%;
  display: flex;
}

.cart_table .vs-cart-coupon input {
  height: 50px;
  width: calc(100% - 200px);
  margin-right: 10px;
  border: 1px solid var(--smoke-color);
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
}
.cart_table .vs-cart-coupon input:focus {
  border-color: var(--sec-color);
  transition: all 0.3s ease-in-out;
}

.cart_table .vs-cart-coupon .vs-btn {
  font-size: 16px;
  padding: 17px 30px;
  width: -moz-max-content;
  width: max-content;
}

.cart_totals {
  border: 1px solid var(--border-color);
}

.cart_totals th,
.cart_totals td {
  vertical-align: top;
  padding: 20px 20px;
  border: none;
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
  color: var(--title-color);
  width: 55%;
}

.cart_totals th:first-child,
.cart_totals td:first-child {
  width: 45%;
  background-color: var(--smoke-color);
  font-weight: 700;
  font-size: 14px;
  color: var(--title-color);
}

.cart_totals .shipping-calculator-button {
  display: inline-block;
  border-bottom: 1px solid;
  color: var(--title-color);
  font-weight: 700;
}

.cart_totals .shipping-calculator-button:hover {
  color: var(--theme-color);
}

.cart_totals .woocommerce-shipping-destination {
  margin-bottom: 10px;
}

.cart_totals .woocommerce-shipping-methods {
  margin-bottom: 5px;
}
.cart_totals .woocommerce-shipping-methods > li {
  min-height: 26px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 7px;
}
.cart_totals .woocommerce-shipping-methods input[type=radio] ~ label {
  min-height: 26px;
  display: flex;
  align-items: center;
  font-family: var(--title-font);
  padding-left: 25px;
}

.cart_totals .woocommerce-shipping-methods input[type=radio] ~ label::before {
  padding-right: 1.2px;
  padding-top: 0.3px;
  font-size: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  line-height: 14px;
}

.cart_totals .shipping-calculator-form {
  display: none;
}

.cart_totals .shipping-calculator-form p:first-child {
  margin-top: 20px;
}

.cart_totals .shipping-calculator-form p:last-child {
  margin-bottom: 0;
}

.cart_totals .shipping-calculator-form .vs-btn {
  padding: 10px 30px;
  height: 50px;
  align-items: center;
}

.shipping-calculator-form .nice-select.form-select,
.shipping-calculator-form .form-control {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 50px;
  border: 1px solid var(--border-color);
  color: var(--body-color);
}
.shipping-calculator-form .nice-select.form-select::after,
.shipping-calculator-form .form-control::after {
  display: none;
}

.cart_totals .amount {
  font-weight: 700;
}

.cart_totals .order-total .amount {
  color: var(--theme-color);
}

@media (max-width: 991px) {
  .cart_table th {
    padding: 23px 8px;
    font-size: 14px;
  }
  .cart_table .cart-productname {
    font-size: 14px;
  }
  .cart_table .vs-cart-coupon {
    width: 100%;
    margin-bottom: 20px;
  }
  .cart_table .actions {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .cart_table {
    text-align: left;
    min-width: auto;
    border-collapse: separate;
    border-spacing: 0 20px;
    border: none;
  }
  .cart_table thead {
    display: none;
  }
  .cart_table td {
    padding: 15px;
    display: block;
    width: 100%;
    padding-left: 25%;
    text-align: right;
    border: 1px solid #f3f3f3;
    border-bottom: none;
  }
  .cart_table td::before {
    display: block;
  }
  .cart_table td:last-child {
    border-bottom: 1px solid #f3f3f3;
  }
  .cart_table td.actions {
    padding-left: 15px;
    text-align: center;
  }
  .cart_table td.actions > .vs-btn {
    margin-top: 10px;
    margin-right: 0;
    display: block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  .cart_table td.actions > .vs-btn:last-child {
    margin-right: auto;
  }
  .cart_table .vs-cart-coupon {
    width: 100%;
    text-align: center;
    float: none;
    justify-content: center;
    display: block;
    padding-bottom: 10px;
  }
  .cart_table .vs-cart-coupon input {
    width: 100%;
    margin-bottom: 10px;
  }
  .cart_totals th,
  .cart_totals td {
    padding: 15px 10px;
  }
  .cart_totals th:first-child,
  .cart_totals td:first-child {
    width: 17%;
    line-height: 1.4;
  }
}
.woocommerce-checkout .form-group {
  margin-bottom: 0;
}

.woocommerce-checkout .form-select,
.woocommerce-checkout .select2-container,
.woocommerce-checkout .form-control {
  margin-bottom: var(--bs-gutter-x);
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
}
.woocommerce-checkout .form-select::after,
.woocommerce-checkout .select2-container::after,
.woocommerce-checkout .form-control::after {
  display: none;
}
.woocommerce-checkout .form-select .list,
.woocommerce-checkout .select2-container .list,
.woocommerce-checkout .form-control .list {
  width: 100%;
}
.woocommerce-checkout .form-select::placeholder,
.woocommerce-checkout .select2-container::placeholder,
.woocommerce-checkout .form-control::placeholder {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
}
.woocommerce-checkout .form-select:focus,
.woocommerce-checkout .select2-container:focus,
.woocommerce-checkout .form-control:focus {
  border-color: var(--sec-color);
  transition: all 0.3s ease-in-out;
}
.woocommerce-checkout .form-select:focus::placeholder,
.woocommerce-checkout .select2-container:focus::placeholder,
.woocommerce-checkout .form-control:focus::placeholder {
  opacity: 0;
  visibility: hidden;
}

.woocommerce-checkout .select2-container--open .select2-dropdown--below {
  margin-top: 30px;
}

.woocommerce-checkout .select2-container--open .select2-dropdown--above {
  position: relative;
  bottom: -30px;
}

.woocommerce-checkout .select2-dropdown {
  border: 1px solid #e3e6e9;
  border-top: none;
}

.woocommerce-checkout .select2-container--default .select2-selection--single .select2-selection__rendered,
.woocommerce-checkout .form-control:focus {
  color: var(--body-color);
}

.select2-container--default .select2-selection--single {
  height: 60px;
  border: 1px solid #e3e6e9;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 60px;
  padding-left: 30px;
  padding-right: 25px;
}

.woocommerce-billing-fields .form-row {
  margin-bottom: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b:before {
  content: "ï„‡";
  font-family: var(--icon-font);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin: 0;
  border: none;
  top: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 60px;
  line-height: 60px;
  margin-right: 18px;
}

span.select2-selection.select2-selection--single:focus {
  outline: none;
}

.shipping-calculator-form .form-select,
.shipping-calculator-form .form-control {
  height: 50px;
  padding-left: 20px;
  font-size: 14px;
  border-radius: 0;
  background-position: right 13px center;
}

.shipping-calculator-form .vs-btn {
  font-size: 14px;
  padding: 0 20px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 40px;
}

.checkout-ordertable th,
.checkout-ordertable td {
  border: none;
  vertical-align: top;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 700;
  color: #2c3e50;
}

.checkout-ordertable ul {
  margin: 0;
  padding: 0;
}

.checkout-ordertable .order-total .amount {
  color: var(--theme-color);
}

.checkout-ordertable input[type=hidden] ~ label {
  color: var(--theme-color);
}

.woocommerce-checkout .form-group input:not(:last-child) {
  margin-bottom: var(--bs-gutter-x);
}

.checkout-ordertable th,
.checkout-ordertable td {
  border: 1px solid #ededed;
  text-align: right;
  padding: 5px 20px;
}

.checkout-ordertable th {
  text-align: left;
}

.woocommerce-checkout-payment {
  text-align: left;
}

.woocommerce-checkout-payment ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.woocommerce-checkout-payment ul li {
  padding-top: 17px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 0;
  font-size: 16px;
}

.woocommerce-checkout-payment ul input[type=radio] ~ label {
  margin-bottom: 19px;
  color: var(--title-color);
  font-weight: 700;
}

.woocommerce-checkout-payment ul input[type=radio] ~ label img {
  margin-bottom: -2px;
  margin-left: 10px;
}

.woocommerce-checkout-payment .place-order {
  padding-top: 30px;
}

.woocommerce-checkout-payment .payment_box {
  color: var(--body-color);
  background-color: var(--smoke-color);
  border: 1px solid var(--smoke-color);
  border-bottom: none;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 4px;
  display: none;
}

.woocommerce-checkout-payment .payment_box p {
  margin: 0;
}

.vs-checkout-wrapper form.woocommerce-form {
  margin-bottom: 25px;
}

.vs-cart-wrapper .vs-btn,
.vs-checkout-wrapper .vs-btn {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
}

@media (max-width: 767px) {
  tfoot.checkout-ordertable th {
    display: none;
  }
  .woocommerce-checkout-payment ul input[type=radio] ~ label img {
    max-width: 150px;
  }
  .woocommerce-form-coupon,
  .woocommerce-form-login {
    padding: 30px 20px;
    margin-bottom: 30px;
  }
}
.tinv-wishlist input[type=checkbox] {
  display: inline-block;
  opacity: 1;
  visibility: visible;
  width: auto;
  height: auto;
}

.tinv-wishlist .cart-empty {
  padding: 12px 25px;
  background-color: #eee;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}

.tinv-wishlist p.return-to-shop .button {
  display: inline-block;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 14px;
  padding: 10px 25px;
  border-radius: 4px;
  margin-top: 10px;
  font-weight: 700;
}

.tinv-wishlist p.return-to-shop .button:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.tinv-wishlist table.tinvwl-table-manage-list {
  font-size: 14px;
}

.tinv-wishlist table.tinvwl-table-manage-list button {
  border-radius: 5px;
}

.tinv-wishlist .product-stock i {
  margin-right: 5px;
}

.tinv-wishlist .tinv-modal .icon_big_times {
  margin-bottom: 5px;
  color: var(--theme-color);
}

.tinv-wishlist button.button {
  border: none;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 700;
  background-color: var(--theme-color);
  color: #fff;
  padding: 0;
}

.tinv-wishlist button.button i {
  font-size: 1rem !important;
  margin-right: 3px !important;
}

.tinv-wishlist th,
.tinv-wishlist td.product-name {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--title-font);
}

.tinv-wishlist td.product-name a {
  color: var(--title-color);
}

.tinv-wishlist td.product-name a:hover {
  color: var(--theme-color);
}

.tinv-wishlist td.product-price del {
  font-size: 0.9em;
}

.tinv-wishlist .social-buttons > span {
  font-weight: 700;
  margin-right: 10px;
  font-family: var(--title-font);
  color: var(--title-color);
}

.tinv-wishlist .social-buttons li a.social {
  background-color: var(--theme-color);
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
}

.tinv-wishlist .social-buttons li a.social i {
  line-height: inherit;
}

.tinv-wishlist .social-buttons li a.social:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

@media (max-width: 767px) {
  .tinv-wishlist table {
    table-layout: fixed;
  }
}
.cat-media {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  padding: 20px 0;
  position: relative;
  margin-bottom: 30px;
}

.cat-media:after,
.cat-media:before {
  content: "";
  position: absolute;
  width: 0%;
  height: 0%;
  transition: width ease 0.6s, height ease 0.6s, border-color ease 0s;
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 0;
}

.cat-media:before {
  left: 0;
  top: 0;
  border-left-width: 1px;
  border-top-width: 1px;
}

.cat-media:after {
  right: 0;
  bottom: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.cat-media_icon {
  padding: 3px 15px;
  border-right: 1px solid var(--border-color);
  position: relative;
  z-index: 3;
  width: 90px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cat-media_cat {
  margin-bottom: 0;
  font-size: 14px;
  text-transform: uppercase;
}

.cat-media .badge {
  position: absolute;
  right: 15px;
  top: auto;
  bottom: 0;
  transform: translateY(50%);
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
}

.cat-media .media-body {
  text-align: center;
  position: relative;
  z-index: 3;
}

.cat-media_slider {
  z-index: 1;
}

.cat-media_slider .slick-arrow {
  --icon-size: 20px;
  --pos-x: -5px;
  font-size: 8px;
  padding: 0;
  line-height: 14px;
  background-color: var(--title-color);
  color: var(--white-color);
  top: calc(50% - 15px);
  border: 3px solid var(--white-color);
}

.cat-media_slider .slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.cat-shape-1 {
  position: absolute;
  left: 2%;
  top: 1%;
}

.cat-media:hover .badge,
.slick-center .cat-media .badge {
  opacity: 1;
  visibility: visible;
}

.cat-media:hover::before,
.cat-media:hover::after,
.slick-center .cat-media::before,
.slick-center .cat-media::after {
  width: 100%;
  height: 100%;
  border-color: var(--theme-color);
}

.cat_chart {
  display: flex;
  background-color: var(--white-color);
  align-items: center;
  padding: 35px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.cat_chart_img {
  margin-right: 30px;
}

.cat_chart_title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 13px;
}

.cat_chart_list {
  margin: 0 0 -0.3em 0;
  padding: 0;
  list-style-type: none;
}

.cat_chart_list a {
  font-size: 14px;
  color: #71737a;
}

.cat_chart_list a:hover {
  color: var(--theme-color);
}

.cat_card {
  margin-bottom: 30px;
  position: relative;
}

.cat_card [class*=cat_card_name] a {
  color: inherit;
}

.cat_card_img {
  position: relative;
  overflow: hidden;
}

.cat_card_img img {
  width: 100%;
  transition: all ease 0.4s;
  transform: scale(1);
}

.cat_card_total {
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 1;
  background-color: var(--title-color);
  color: var(--white-color);
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1;
  padding: 8px 14px;
}

.cat_card_name {
  color: var(--white-color);
  background-color: var(--theme-color);
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 18px 15px;
  text-align: center;
}

.cat_card_name--style2 {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: var(--white-color);
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
  padding: 18px 31px;
  border-radius: 9999px;
}

.cat_card:hover .cat_card_img img {
  transform: scale(1.15);
}

.cat_rounded {
  text-align: center;
  margin-bottom: 26px;
}

.cat_rounded .cat-img {
  border: 2px solid var(--theme-color);
  border: 2px solid var(--border-color);
  width: var(--img-size, 160px);
  height: var(--img-size, 160px);
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 18px;
  transition: all ease 0.4s;
}

.cat_rounded .cat-img img {
  width: 100%;
  transition: all ease 0.4s;
}

.cat_rounded .cat-name {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.cat_rounded:hover .cat-img {
  border-color: var(--theme-color);
}

.cat_rounded:hover .cat-img img {
  transform: scale(1.1);
}

/*=================================
    05. Spacing
==================================*/
/*-- Padding Left And Right --*/
.px-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.px-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.px-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.px-30 {
  padding-right: 30px;
  padding-left: 30px;
}

/*-- Padding Top And Bottom --*/
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

/*-- margin Left And Right --*/
.mx-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.mx-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.mx-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.mx-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.mx-25 {
  margin-right: 25px;
  margin-left: 25px;
}

.mx-30 {
  margin-right: 30px;
  margin-left: 30px;
}

/*-- margin Top And Bottom --*/
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

/*-- margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

/*-- margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

/*-- margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

/*-- margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mt-n1 {
  margin-top: -0.25rem;
}

.mt-n2 {
  margin-top: -0.5rem;
}

.mt-n3 {
  margin-top: -1rem;
}

.mt-n4 {
  margin-top: -1.5rem;
}

.mt-n5 {
  margin-top: -3rem;
}

.mb-n1 {
  margin-bottom: -0.25rem;
}

.mb-n2 {
  margin-bottom: -0.5rem;
}

.mb-n3 {
  margin-bottom: -1rem;
}

.mb-n4 {
  margin-bottom: -1.5rem;
}

.mb-n5 {
  margin-bottom: -3rem;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.space,
.space-top {
  padding-top: var(--section-space);
}

.space,
.space-bottom {
  padding-bottom: var(--section-space);
}

.space-extra,
.space-extra-top {
  padding-top: calc(var(--section-space) - 30px);
}

.space-extra,
.space-extra-bottom {
  padding-bottom: calc(var(--section-space) - 30px);
}

/* Medium devices */
@media (max-width: 991px) {
  .space,
  .space-top {
    padding-top: var(--section-space-mobile);
  }
  .space,
  .space-bottom {
    padding-bottom: var(--section-space-mobile);
  }
  .space-extra,
  .space-extra-top {
    padding-top: calc(var(--section-space-mobile) - 30px);
  }
  .space-extra,
  .space-extra-bottom {
    padding-bottom: calc(var(--section-space-mobile) - 30px);
  }
  .space-top-md-none {
    padding-top: 0;
  }
}
/*=================================
    06. Color Plate
==================================*/
/* Color Plate */
.vs-color-plate {
  position: fixed;
  display: block;
  z-index: 91;
  padding: 20px;
  width: 240px;
  background: #fff;
  left: 0;
  text-align: left;
  top: 30%;
  transition: all 0.4s ease;
  box-shadow: -3px 0px 10px -2px rgba(0, 0, 0, 0.1);
  direction: ltr;
  transform: translateX(-100%);
}
.vs-color-plate.open {
  transform: translateX(0);
}
.vs-color-plate .color-plate-icon {
  position: absolute;
  right: -48px;
  width: 48px;
  height: 45px;
  line-height: 45px;
  font-size: 21px;
  border-radius: 0 5px 5px 0;
  text-align: center;
  color: var(--white-color);
  top: 0;
  cursor: pointer;
  border: none;
  padding: 0;
  box-shadow: -4px 0px 5px rgba(0, 0, 0, 0.2117647059);
  background-color: var(--theme-color);
}
.vs-color-plate h4 {
  display: block;
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
}
.vs-color-plate p {
  font-size: 12px;
  margin-bottom: 15px;
  line-height: 20px;
}
.vs-color-plate .reset-btn {
  border: none;
  padding: 0;
  height: 20px;
  font-size: 14px;
  text-transform: capitalize;
  color: var(--title-color);
  background-color: var(--smoke-color);
  width: 100%;
  height: 40px;
}
.vs-color-plate .reset-btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.vs-color-plate input[type=color] {
  border: none;
  padding: 0;
}

.default-color-btn {
  border: none;
  background-color: #000;
  color: var(--white-color);
  font-weight: 400;
  font-family: var(--body-font);
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 0 0 10px 0;
  padding: 5px 0;
}