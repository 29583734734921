.widget_nav_menu,
.widget_meta,
.widget_pages,
.widget_archive,
.widget_categories {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  > ul {
    margin: 0 0 -38px 0;
  }

  a {
    display: flex;
    margin: 0px 0px 20px;
    padding: 5px 20px 5px 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    align-items: center;
    justify-content: space-between;
    background: var(--white-color);
    color: var(--title-color);
    text-transform: capitalize;
    font-family: var(--body-font);
    min-height: 50px;
    position: relative;
  }

  li {
    display: block;
    position: relative;

    &:last-child {
      a {
        margin-bottom: 0;
      }
    }

    > span {
      text-align: center;
      position: absolute;
      right: 0;
      top: -4.5px;
      font-size: 16px;
      transition: all ease 0.4s;
    }

    &:hover {
      > span {
        color: $theme-color;
      }
    }
  }

  .children {
    margin-left: 10px;
  }
}
.widget_categories {
  a {
    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 4px;
      height: 100%;
      left: 0;
      display: block;
      @include smooth-transition;
    }
    &::before {
      top: 0;
      background-color: var(--theme-color);
    }
    &::after {
      top: auto;
      bottom: 0;
      background-color: var(--sec-color);
      height: 0;
    }
    &:hover {
      &::after {
        height: 100%;
      }
      &::before {
        height: 0;
      }
    }
  }
}

.widget_nav_menu,
.widget_meta,
.widget_pages {
  a {
    padding-right: 20px;
  }
}

.widget_nav_menu {
  .sub-menu {
    margin-left: 10px;
  }
}

.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;

  a:not(:hover) {
    color: inherit;
  }
}

.vs-blog ul.wp-block-archives li {
  margin: 5px 0;
}

@include sm {
  .widget_nav_menu,
  .widget_meta,
  .widget_pages,
  .widget_archive,
  .widget_categories {
    a {
      font-size: 14px;
    }
  }
}

.cat-item {
  &__number {
    transition: all 0.4s ease;
    color: var(--theme-color);
  }
  &:hover {
    .cat-item {
      &__number {
        color: var(--sec-color);
      }
    }
  }
}
