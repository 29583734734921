.social-style1 {
  &.style3 {
    display: flex;
    align-items: center;
    padding-top: 24px;
    .title {
      font-size: 30px;
      font-family: var(--title-font);
      color: var(--title-color);
      font-weight: 700;
      margin-right: 20px;
      @include respond-below(md) {
        font-size: 18px;
      }
    }
    a {
      background-color: var(--smoke-color);
      color: var(--title-color);
      border-color: var(--smoke-color);
      &:hover {
        background-color: var(--theme-color);
        color: var(--white-color);
      }
    }
  }
}
