.woocommerce-message,
.woocommerce-info {
  position: relative;
  border: 1px solid $border-color;
  padding: 11px 20px;
  background-color: $smoke-color;
  color: $title-color;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  border-radius: 10px;
  font-family: $title-font;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  &:before {
    content: "\f06a";
    font-family: $icon-font;
    font-weight: 900;
    margin-right: 10px;
  }
}

.woocommerce-notices-wrapper {
  .woocommerce-message {
    background-color: var(--smoke-color);
    color: var(--white-color);
    border-color: transparent;
    border-radius: 0;
    font-size: 16px;
    padding: 11px 20px;
    font-family: var(--title-font);
    margin-bottom: 40px;

    &:before {
      content: "\f14a";
      font-weight: 300;
      top: 1px;
      position: relative;
    }
  }
}

.woocommerce-Reviews {
  .vs-comments-wrap {
    margin-top: 0;
  }
  .star-rating {
    &:before {
      display: block;
    }
  }
}

.quantity {
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;

  > label {
    font-size: 14px;
    color: $body-color;
    font-weight: 700;
    margin: 0 20px 0 0;
    font-family: $body-font;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  input {
    padding: 0 10px 0 10px;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    --body-color: #{$title-color};
    border: 1px solid $smoke-color;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}

.rating-select {
  margin-top: -0.4em;

  label {
    margin: 0 10px 0 0;
    display: inline-block;
  }

  p.stars {
    margin-bottom: 0;
    line-height: 1;
    display: inline-block;

    a {
      position: relative;
      height: 14px;
      width: 18px;
      text-indent: -999em;
      display: inline-block;
      text-decoration: none;
      @include respond-below(md) {
        width: 14px;
      }
    }

    a::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 14px;
      line-height: 1;
      font-family: $icon-font;
      content: "\f005";
      font-weight: 400;
      text-indent: 0;
      color: $yellow-color;
    }

    a:hover ~ a::before {
      content: "\f005";
      font-weight: 400;
    }

    &:hover a::before {
      content: "\f005";
      font-weight: 700;
    }

    &.selected a.active::before {
      content: "\f005";
      font-weight: 700;
    }

    &.selected a.active ~ a::before {
      content: "\f005";
      font-weight: 400;
    }

    &.selected a:not(.active)::before {
      content: "\f005";
      font-weight: 700;
    }
  }
}

.woocommerce-message,
.woocommerce-info {
  position: relative;
  border: 1px solid transparent;
  padding: 16px 20px;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  border-radius: 0;
}

.woocommerce-message a,
.woocommerce-info a {
  color: inherit;
  text-decoration: underline;
}

.woocommerce-message a:hover,
.woocommerce-info a:hover {
  text-decoration: underline;
}

.woocommerce-message:before,
.woocommerce-info:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 900;
  margin-right: 10px;
}

.woocommerce-notices-wrapper .woocommerce-message {
  background-color: var(--theme-color);
}

.woocommerce-notices-wrapper .woocommerce-message:before {
  content: "";
  font-weight: 300;
}

.woocommerce-form-login-toggle .woocommerce-info {
  background-color: var(--theme-color);
}

.woocommerce-form-coupon,
.woocommerce-form-login {
  padding: 40px;
  background-color: var(--smoke-color);
  margin-bottom: 40px;
  display: none;
  .form-control {
    border: 1px solid var(--border-color);
    @include smooth-transition;
    &::placeholder {
      @include smooth-transition;
      opacity: 1;
      visibility: visible;
    }
    &:focus {
      border-color: var(--sec-color);
      @include smooth-transition;
      &::placeholder {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.woocommerce-form-coupon .form-group,
.woocommerce-form-login .form-group {
  margin-bottom: 25px;
}

.woocommerce-form-coupon .form-group:last-child,
.woocommerce-form-login .form-group:last-child {
  margin-bottom: 0;
}

.woocommerce-error {
  background-color: var(--error-color);
  color: #fff;
  list-style: none;
  padding: 10px 26px;
  margin: 0 0 30px 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}

nav.woocommerce-MyAccount-navigation li {
  border: 1px solid #ddd;
  margin: 0;
  border-top: none;
}

nav.woocommerce-MyAccount-navigation li:first-child {
  border-top: 1px solid #ddd;
}

nav.woocommerce-MyAccount-navigation li a {
  color: var(--title-color);
  font-weight: 700;
  padding: 7px 17px;
  display: block;
}

nav.woocommerce-MyAccount-navigation li.is-active a,
nav.woocommerce-MyAccount-navigation li a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.woocommerce-MyAccount-content h3 {
  margin-top: -0.3em;
}

.woocommerce-MyAccount-content .btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 14px;
  padding: 10px 25px;
  font-weight: 700;
}

.woocommerce-MyAccount-content .btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

table.variations,
.woocommerce-grouped-product-list-item {
  border-collapse: separate;
  border-spacing: 0 15px;
  margin-bottom: 5px;
  align-items: center;
}

table.variations td,
.woocommerce-grouped-product-list-item td {
  border: none;
  vertical-align: middle;
  padding: 0 5px;
}

table.variations td:first-child,
.woocommerce-grouped-product-list-item td:first-child {
  padding: 0;
}

table.variations label,
.woocommerce-grouped-product-list-item label {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}

table.variations label a,
.woocommerce-grouped-product-list-item label a {
  color: var(--title-color);
}

table.variations label a:hover,
.woocommerce-grouped-product-list-item label a:hover {
  color: var(--theme-color);
}

.woocommerce-product-attributes th,
.woocommerce-product-attributes td {
  border: 1px solid var(--border-color);
}

.woocommerce-product-attributes th p:last-child,
.woocommerce-product-attributes td p:last-child {
  margin-bottom: 0;
}

.woocommerce-grouped-product-list.group_table {
  border-collapse: collapse;
  margin-bottom: 15px;
}

.woocommerce-grouped-product-list.group_table .woocommerce-Price-amount.amount {
  font-size: 16px;
  color: var(--title-color);
}

.woocommerce-grouped-product-list.group_table label {
  margin: 0 0 0 10px;
  margin: 0 0 0 10px;
  font-family: var(--title-font);
  font-size: 18px;
}

.woocommerce-grouped-product-list.group_table .qty-input {
  border-color: #e3e6e9;
}

.woocommerce-grouped-product-list.group_table tr {
  border-bottom: 1px solid #e3e6e9;
}

.woocommerce-grouped-product-list.group_table tr:last-child {
  border-bottom: none;
}

.woocommerce-grouped-product-list.group_table td {
  padding: 30px 5px;
}

table.variations {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
}

table.variations td {
  padding: 0;
}

table.variations td.label {
  padding-right: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

table.variations select {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  margin: 0;
  padding-right: 54px;
  padding-left: 20px;
  height: 50px;
}

table.variations .reset_variations {
  margin-left: 16px;
  display: inline-block;
  position: absolute;
  left: 100%;
  bottom: 25px;
}

.woocommerce-variation.single_variation {
  margin-bottom: 30px;
}

.woocommerce-variation.single_variation .price {
  color: var(--title-color);
  font-weight: 700;
}
