.jump-reverse-img,
.jump-img,
.jump-reverse,
.jump {
  animation: jumpping var(--duration, 6s) infinite linear;
}

.jump-reverse-img,
.jump-img {
  --duration: 5s;
}

.jump-reverse-img,
.jump-reverse {
  --jump-y: -20px;
}

.rotate-reverse-img,
.rotate-img,
.rotate-reverse,
.rotate {
  animation: rotate var(--duration, 12s) infinite linear;
}

.rotate-reverse-img,
.rotate-img {
  --duration: 40s;
}

.rotate-reverse {
  --rotate-angle: -360deg;
}

.spin {
  animation: spin var(--duration, 40s) infinite linear;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.wow-animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadein {
  --animation-name: fadein-custom;
}

.slideinup {
  --animation-name: slideinup;
}

.slideindown {
  --animation-name: slideindown;
}

.slideinleft {
  --animation-name: slideinleft;
}

.slideinright {
  --animation-name: slideinright;
}

.animated {
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-name: var(--animation-name);
}

.ripple-animation {
  animation-duration: var(--ripple-ani-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ripple;
}

// .leftToRight {
//   animation-timing-function: ease-in-out;
//   animation-iteration-count: infinite;
//   --animation-name: leftToRight;
// }

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes slideinup {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideindown {
  0% {
    opacity: 0;
    transform: translateY(-70px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideinleft {
  0% {
    opacity: 0;
    transform: translateX(-70px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideinright {
  0% {
    opacity: 0;
    transform: translateX(70px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadein-custom {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes jumpping {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  40% {
    transform: translate3d(0, var(--jump-y, 20px), var(--jump-x, 0));
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(var(--rotate-angle, 360deg));
  }
}

@keyframes leftToRight {
  0%,
  100% {
    left: 0px;
  }
  50% {
    left: 56px;
  }
}
